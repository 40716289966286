import styled from "styled-components";
import OwnColors from "../../../constants/OwnColors";
import { Button, styled as s } from "@mui/material";

export const DragWrapper = styled.div`
  display: left;
`;
export const ListContainer = styled.div`
  margin: 0.2rem auto;
  max-width: auto;
  padding: .5rem 2rem 2rem;
  border-radius: 0.2rem;
  box-shadow: 0.1rem 0.1rem 0.4rem #aaaaaa;
`;
export const ListItem = styled.div`
  text-align:left
  color: #444444;
  padding: 0.8rem 0.3rem;
  border-bottom: 1px solid #dddddd;
   &:last-child {
    border-bottom: none;
  }
  span {
    display: inline-block;
    vertical-align: left;
  }
`;

const StyledButton = s(Button)(() => ({
  color: OwnColors.LECOCorporate,
  borderColor: OwnColors.LECOCorporate
}));

const StyledListHeader = s(ListItem)(() => ({
  fontWeight: 500,
  borderColor: OwnColors.LECOCorporate,
  display: "flex",
  backgroundColor: OwnColors.LECOlightRed,
}));

const StyledSpanField = s('span')(() => ({
  borderColor: OwnColors.LECOCorporate,
  flexBasis: "20%"
}));

const StyledSpanFieldLong = s('span')(() => ({
  borderColor: OwnColors.LECOCorporate,
  flexBasis: "40%"
}));

const StyledListBody = s('div')(() => ({
  color: '#555555',
  display: "flex"
}));

const StyledDivItem = s('div')(() => ({
  display: 'block',
  textAlign: 'center'
}));

export {
  StyledButton,
  StyledListHeader,
  StyledSpanField,
  StyledSpanFieldLong,
  StyledListBody,
  StyledDivItem
}


