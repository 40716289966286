import { FC, useEffect, useState } from "react";
import { observer } from "mobx-react";
import SortTable from "./components/SortTable";
import { StyledDivItem } from "./styles";
import { ContadorViewModel } from "../../../viewmodels/ContadorViewModel";
import { RutaViewModel } from "../../../viewmodels/RutaViewModel";
import { useNavigate, Link } from "react-router-dom";
import { Grid, Paper, Typography, Snackbar } from "@mui/material";
import Alert, { AlertColor } from "@mui/material/Alert";
import { ReportProblem } from "@mui/icons-material";
import { Contador, ContadorPaged } from "../../../client";
import { StyledBreadcrumbs, StyledListAltIcon, StyledSearchIcon, StyledTypography } from "../../../components/CustomBreadCrumbs";

const MetersRouteView: FC = () => {
  const navigate = useNavigate();

  const [routeMeters, setRouteMeters] = useState<Contador[]>([]);
  const [alertType, setAlertType] = useState<AlertColor>("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    (async () => {
      let routeId = RutaViewModel.getInstance().currentRuta?.idRuta;
      if (routeId) {
        ContadorViewModel.getInstance()
          .getContadoresByRoute(routeId)
          .then((value: Contador[]) => setRouteMeters([...value]))
          .catch((err) => err);
      } else navigate("/selectRutaToSort");
    })();
  }, []);

  const updateMeterOrder = async (meters: Contador[]) => {
    setRouteMeters([]);
    await ContadorViewModel.getInstance()
      .updateRouteMeters({
        routeId: RutaViewModel.getInstance().currentRuta?.idRuta ?? "",
        routeMeters: meters,
      })
      .then((value: ContadorPaged) => {
        setAlertMessage("Orden actualizado correctamente");
        setAlertType("success");
        setRouteMeters(value.content ?? []);
      })
      .catch(() => {
        setAlertMessage("Hubo un problema al actualizar el orden");
        setAlertType("error");
        setRouteMeters([...(ContadorViewModel.getInstance().contadores ?? [])]);
      });

    setAlertOpen(true);
  };

  return (
    <StyledDivItem>
      <StyledBreadcrumbs aria-label="breadcrumb">
        <Typography component={Link} to="/selectRutaToSort" color="inherit" display="flex" sx={{ textDecoration: "none" }}>
          <StyledSearchIcon />
          Selección de ruta
        </Typography>
        <StyledTypography color="textPrimary">
          <StyledListAltIcon />
          Ordenar ruta
        </StyledTypography>
      </StyledBreadcrumbs>
      {routeMeters?.filter((c: Contador) => c.needsSorting).length ? (
        <Grid
          container
          item
          xs={12}
          style={{ margin: 10 }}
          sx={{
            justifyContent: "center",
          }}
        >
          <Grid
            item
            xs={8}
            sx={{
              justifyContent: "center",
            }}
          >
            <Paper elevation={10} style={{ height: 50, padding: 20 }}>
              <ReportProblem fontSize="large" color="warning" />
              <Typography
                align="center"
                style={{ fontWeight: "bold", fontSize: 18 }}
              >
                Esta ruta contiene contadores que necesitan ser ordenados
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <SortTable contadores={routeMeters} updateFunction={updateMeterOrder} />
      <Snackbar
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
      >
        <div>
          <Alert severity={alertType}>{alertMessage}</Alert>
        </div>
      </Snackbar>
    </StyledDivItem>
  );
};

export default observer(MetersRouteView);
