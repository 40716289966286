import { makeAutoObservable } from "mobx";
import { Ruta } from "../client";
import RutaRepository from "../data/repositories/RutaRepository";

export class RutaViewModel {
    private static instance: RutaViewModel | null = null;


    static getInstance(): RutaViewModel {
        if (!RutaViewModel.instance) {
            RutaViewModel.instance = new RutaViewModel();
        }
        return RutaViewModel.instance;
    }

    currentRuta?: Ruta;
    rutas: Ruta[] | undefined = undefined;

    private constructor() {
        makeAutoObservable(this);
        RutaRepository.getRutas().then((res: Ruta[] | undefined) => {
            this.setRutas(res);
        });
        this.currentRuta = {
            idRuta: undefined,
            descripcion: undefined,
            estado: undefined
        }
    }

    async createRuta(ruta: Ruta) {
        return RutaRepository.createRuta(ruta)
    }

    async getRutas() {
        const updatedRoutes = await RutaRepository.getRutas();
        this.setRutas(updatedRoutes);
    }

    async getRutaById(id: string) {
        return RutaRepository.getRutaById(id);
    }

    async updateRutaById(id: string, ruta: Ruta) {
        return RutaRepository.updateRutaById(id, ruta);
    }

    async deleteRutaById(id: string) {
        await RutaRepository.deleteRutaById(id);
    }

    public setCurrentRuta(ruta?: Ruta) {
        this.currentRuta = ruta;
    }

    public setRutaValue(key: keyof Ruta, value: any) {
        this.currentRuta && (this.currentRuta[key] = value);
    }

    public setRutas(rutas?: Ruta[]) {
        this.rutas = rutas;
    }
}