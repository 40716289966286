import { observer } from "mobx-react";
import { UserViewModel } from "../../viewmodels/UserViewModel";
import logo from "../../assets/images/LogoHuesca.jpg";
import { HomeStyledImg, HomeStyledText, HomeStyledTypography } from "./styles";
import Grid from "@mui/material/Grid";

const HomeView = () => {
  const vmUser = UserViewModel.getInstance();
  const name = vmUser.loggedUser?.name;

  return (
    <Grid sx={{alignItems: "center", justifyContent: "center"}}>
      <HomeStyledText >AFORADORES DE HUESCA</HomeStyledText>
      <div style={{textAlign: "center"}}>
        <HomeStyledImg src={logo} alt="Logo"/>
      </div>
      <HomeStyledText>BIENVENIDO A LA APLICACIÓN</HomeStyledText>
      <HomeStyledTypography>{name}</HomeStyledTypography>
    </Grid>
  );
};

export default observer(HomeView);
