/* tslint:disable */
/* eslint-disable */
/**
 * Aforadores
 * Proxy Service Api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FindByFieldRequest
 */
export interface FindByFieldRequest {
    /**
     * 
     * @type {string}
     * @memberof FindByFieldRequest
     */
    field?: FindByFieldRequestFieldEnum;
    /**
     * 
     * @type {number}
     * @memberof FindByFieldRequest
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof FindByFieldRequest
     */
    pageSize?: number;
    /**
     * 
     * @type {string}
     * @memberof FindByFieldRequest
     */
    value?: string;
}


/**
 * @export
 */
export const FindByFieldRequestFieldEnum = {
    Reference: 'REFERENCE',
    MeterNumber: 'METER_NUMBER',
    Owner: 'OWNER',
    Address: 'ADDRESS'
} as const;
export type FindByFieldRequestFieldEnum = typeof FindByFieldRequestFieldEnum[keyof typeof FindByFieldRequestFieldEnum];


/**
 * Check if a given object implements the FindByFieldRequest interface.
 */
export function instanceOfFindByFieldRequest(value: object): value is FindByFieldRequest {
    return true;
}

export function FindByFieldRequestFromJSON(json: any): FindByFieldRequest {
    return FindByFieldRequestFromJSONTyped(json, false);
}

export function FindByFieldRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindByFieldRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'field': json['field'] == null ? undefined : json['field'],
        'pageNumber': json['pageNumber'] == null ? undefined : json['pageNumber'],
        'pageSize': json['pageSize'] == null ? undefined : json['pageSize'],
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function FindByFieldRequestToJSON(value?: FindByFieldRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'field': value['field'],
        'pageNumber': value['pageNumber'],
        'pageSize': value['pageSize'],
        'value': value['value'],
    };
}

