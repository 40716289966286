import { Contador, HistoricoLecturas, HistoricoLecturasTipoLecturaEnum } from "../client"

export const calculateTotalConsumption = (lastReading: number, currentReading: number) => currentReading - lastReading;

export const createNewReading = (currentHistory: HistoricoLecturas, currentMeter?: Contador, username?: string): HistoricoLecturas => {
  let newHistory: HistoricoLecturas = {};

  newHistory.idHistoricolecturas = 0
  newHistory.referencia = currentMeter
  newHistory.fechaLectura = currentHistory.fechaLectura
  newHistory.lecturaContador = currentHistory.lecturaContador?.toString()
  newHistory.fechaGrabacionBBDD = 0
  newHistory.tipoLectura = currentHistory.tipoLectura
  newHistory.procesado = true
  newHistory.codigoIncidencia = currentHistory.codigoIncidencia
  newHistory.user = username ?? ""
  newHistory.consumo = currentHistory.consumo
  newHistory.visible = "Y"

  if (currentHistory.tipoLectura === HistoricoLecturasTipoLecturaEnum.Facturada) {
    newHistory.fechaFacturacion = new Date(currentHistory.fechaLectura!).getTime();
  }

  return newHistory;
}

export const sortHistoryItemsByRecordDateDesc = (histories?: HistoricoLecturas[]) => (
  histories?.sort((a: HistoricoLecturas, b: HistoricoLecturas) => (b.fechaGrabacionBBDD! - a.fechaGrabacionBBDD!)) ?? []
);