import { makeAutoObservable } from "mobx";
import { ContadorViewModel } from "./ContadorViewModel";
import { Contador } from "../client";

const catastroDefaultUrl =
  "https://www1.sedecatastro.gob.es/Cartografia/mapa.aspx?buscar=S";

export interface IState {
  latitude: number;
  longitude: number;
  cadastralRef: string;
  address: string;
}

const DEFAULT_LATITUDE = 42.13615;
const DEFAULT_LONGITUDE = -0.4087;

export class LocalizationViewModel {
  latitude: number = 0;
  longitude: number = 0;
  catastroUrl: string = catastroDefaultUrl;
  loading: boolean = true;
  formData: IState = {
    latitude: 0,
    longitude: 0,
    cadastralRef: "",
    address: "",
  };
  currentContador?: Contador;

  constructor(referencia: string) {
    makeAutoObservable(this);
    this.handleMarkerDragEnd = this.handleMarkerDragEnd.bind(this);

    if (referencia) {
      const contadorViewModel = ContadorViewModel.getInstance();
      contadorViewModel.getContadorByReference(referencia).then(() => {
        const fetchedContador = contadorViewModel.currentContador;

        if (fetchedContador) {
          this.setCurrentContador(fetchedContador);
          this.setFormData({
            cadastralRef: fetchedContador.cadastralReference ?? "",
            address: fetchedContador.direccion ?? "",
            latitude: fetchedContador.latitude ?? DEFAULT_LATITUDE,
            longitude: fetchedContador.longitude ?? DEFAULT_LONGITUDE,
          });
          this.setLatitude(fetchedContador.latitude ?? DEFAULT_LATITUDE);
          this.setLongitude(fetchedContador.longitude ?? DEFAULT_LONGITUDE);
        }

        this.setLoading(false);
      });
    }
  }

  async handleSave(referencia?: string) {
    if (referencia && this.currentContador && this.formData) {
      await ContadorViewModel.getInstance().updateContadorByReference(
        referencia,
        {
          ...this.currentContador,
          cadastralReference: this.formData.cadastralRef,
          direccion: this.formData.address,
          latitude: this.formData.latitude,
          longitude: this.formData.longitude,
        }
      );
    }
  }

  get catastroResultUrl(): string {
    return `https://www1.sedecatastro.gob.es/CYCBienInmueble/OVCListaBienes.aspx?from=OVCBusqueda&pest=coordenadas&latitud=${this.latitude}&longitud=${this.longitude}&huso=0&tipoCoordenadas=2&TipUR=Coor`;
  }

  get getContador(): Contador | undefined {
    return this.currentContador;
  }

  get getLoading(): boolean {
    return this.loading;
  }

  get getLatitude(): number {
    return this.latitude;
  }

  get getLongitude(): number {
    return this.longitude;
  }

  get getFormData(): IState {
    return this.formData;
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.formData = {
      ...this.formData,
      [name]: value,
    };
  };

  updateCoordinates() {
    if (this.formData.latitude !== 0 && this.formData.longitude !== 0) {
      this.latitude = this.formData.latitude;
      this.longitude = this.formData.longitude;
    }
  }

  handleMarkerDragEnd(newLatitude: number, newLongitude: number) {
    if (this.formData) {
      this.formData = {
        ...this.formData,
        latitude: newLatitude,
        longitude: newLongitude,
      };
    }
  }

  setFormData(formData: IState) {
    this.formData = formData;
  }

  setCurrentContador(currentContador?: Contador) {
    this.currentContador = currentContador;
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  setLatitude(latitude: number) {
    this.latitude = latitude;
  }

  setLongitude(longitude: number) {
    this.longitude = longitude;
  }
}
