import { UserViewModel } from '../../../viewmodels/UserViewModel';
import { User } from '../../../client/models/User';
import SessionStorage from '../../../data/sessionStorage/SessionStorage';
import Toolbar from '../../toolbar/Toolbar';
import AppSkeletonStyled from './AppSkeletonStyles';

export interface IAppSkeletonProps {
    component?: JSX.Element
}

export const AppSkeleton = ({ component }: IAppSkeletonProps) => {
    if (UserViewModel.getInstance().loggedUser === undefined) {
        const [userId, userToken] = SessionStorage.getLocalStorageUser();
        if (userId && userToken) {
            UserViewModel.getInstance().getUserById(Number.parseInt(userId)).then((user: User | undefined) => {
                if (user) {
                    UserViewModel.getInstance().setLoggedUser(user);
                }
            })
        }
    }

    return <AppSkeletonStyled>
        <div style={{ display: "block" }}>
            <Toolbar />
            {component}
        </div>
    </AppSkeletonStyled>
}
