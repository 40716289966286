/* tslint:disable */
/* eslint-disable */
/**
 * Aforadores
 * Proxy Service Api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Contador } from './Contador';
import {
    ContadorFromJSON,
    ContadorFromJSONTyped,
    ContadorToJSON,
} from './Contador';

/**
 * 
 * @export
 * @interface UpdateRouteMetersRequest
 */
export interface UpdateRouteMetersRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateRouteMetersRequest
     */
    routeId?: string;
    /**
     * 
     * @type {Array<Contador>}
     * @memberof UpdateRouteMetersRequest
     */
    routeMeters?: Array<Contador>;
}

/**
 * Check if a given object implements the UpdateRouteMetersRequest interface.
 */
export function instanceOfUpdateRouteMetersRequest(value: object): value is UpdateRouteMetersRequest {
    return true;
}

export function UpdateRouteMetersRequestFromJSON(json: any): UpdateRouteMetersRequest {
    return UpdateRouteMetersRequestFromJSONTyped(json, false);
}

export function UpdateRouteMetersRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateRouteMetersRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'routeId': json['routeId'] == null ? undefined : json['routeId'],
        'routeMeters': json['routeMeters'] == null ? undefined : ((json['routeMeters'] as Array<any>).map(ContadorFromJSON)),
    };
}

export function UpdateRouteMetersRequestToJSON(value?: UpdateRouteMetersRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'routeId': value['routeId'],
        'routeMeters': value['routeMeters'] == null ? undefined : ((value['routeMeters'] as Array<any>).map(ContadorToJSON)),
    };
}

