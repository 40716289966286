import { styled, Typography } from "@mui/material";

const HomeStyledText = styled("p")(() => ({
  "@media (max-height: 700px)": {
    padding: "1rem 0 1rem 0",
  },
  padding: "4rem 0 2rem 0",
  fontWeight: "bold",
  textAlign: "center",
}));

const HomeStyledTypography = styled(Typography)(() => ({
  fontWeight: "bold",
  textAlign: "center",
}));

const HomeStyledImg = styled("img")(() => ({
  width: "100%",          
  height: "auto",          
  maxWidth: "600px", 
  "@media (max-height: 700px)": {
    maxWidth: "350px",     
  }
}));

export { HomeStyledImg, HomeStyledText, HomeStyledTypography };