import {
  styled,
  FormControl,
  Typography,
  Dialog,
  Button,
  TextField,
  Grid,
} from "@mui/material";
import OwnColors from "../../../constants/OwnColors";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(3, 0, 3, 0),
  minWidth: 220,

  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: OwnColors.LECOCorporate,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: OwnColors.LECOCorporate,
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: OwnColors.LECOCorporate,
  },
  "& .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal.MuiFormControl-fullWidth":
    {
      margin: theme.spacing(0, 0, 0, 0),
    },
}));

const StyledGridAddressSelectorMainContainer = styled(Grid)(() => ({
  padding: 10,
}));

const StyledTypographyAddressSelectorTitles = styled(Typography)(
  () => ({
    fontWeight: "bold",
    textAlign: "left"
  })
);

const StyledGridAddressSelectorTableHeaders = styled(Grid)(() => ({
  marginBottom: 10,
}));

const StyledDialogComment = styled(Dialog)(() => ({
  "&:hover .MuiInputLabel-root": {
    color: OwnColors.LECOCorporate,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: OwnColors.LECOCorporate,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: OwnColors.LECOCorporate,
  },
  "& .MuiInputLabel-root": {
    color: OwnColors.LECOMediumGrey,
  },
}));

const StyledButton2 = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0),
  alignItems: "center",
  color: OwnColors.LECOCorporate,
  borderColor: OwnColors.LECOCorporate,
}));

const StyledDivItem = styled('div')(() => ({
  display: "block",
  textAlign: "center",
}));

const StyledTextFieldDisableRoot = styled(TextField)(({ theme }) => ({
  "&:hover .MuiInputLabel-root": {
    color: OwnColors.LECOLightGrey,
  },
  ".MuiAutocomplete-inputRoot": {
    color: OwnColors.LECOCorporate,
  },
  margin: theme.spacing(0, 0, 1, 0),
}));

const StyledTextFieldInputRoot = styled(TextField)(({ theme }) => ({
  "&:hover .MuiInputLabel-root": {
    color: OwnColors.LECOCorporate,
  },
  margin: theme.spacing(0, 0, 1, 0),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3),
  color: OwnColors.LECOCorporate,
  borderColor: OwnColors.LECOCorporate,
}));

export {
  StyledGridAddressSelectorMainContainer,
  StyledFormControl,
  StyledGridAddressSelectorTableHeaders,
  StyledTypographyAddressSelectorTitles,
  StyledButton2,
  StyledDialogComment,
  StyledDivItem,
  StyledTextFieldDisableRoot,
  StyledTextFieldInputRoot,
  StyledButton
};
