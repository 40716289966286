import {
    CounterHistoryFilter,
    DeleteResponse,
    GetPendingCountersSortEnum,
    HistoricoLecturas,
    HistoricoLecturasPaged
} from "../../client";
import { GetAllCounterReadingHistoryByFilterSortEnum, HistoricoLecturasApi } from "../../client/apis/HistoricoLecturasApi";
import { Api, ApiClient } from "./ApiClient";



export default class HistoricoLecturasRepository{

    private static getHistoricoLecturasApiClient() {
        return ApiClient.getClient(Api.HistoricoLecturasApi) as HistoricoLecturasApi;
    }

    static async getAllHistoricoLecturas(): Promise<HistoricoLecturasPaged>{
        const historicoLecturasApi=this.getHistoricoLecturasApiClient();
        const allHistoricoLecturas: HistoricoLecturasPaged=await historicoLecturasApi.getAllHistoricoLecturas(0,20);

        return allHistoricoLecturas;
    }

    static async getHistoricoLecturasById(id: number): Promise<HistoricoLecturas>{
        const historicoLecturasApi=this.getHistoricoLecturasApiClient();
        const historicoLecturas: HistoricoLecturas= await historicoLecturasApi.getHistoricoLecturasById(id);

        return historicoLecturas;
    }

    static async getHistoricoLecturasByReference(referencia: string): Promise<HistoricoLecturas[]>{
        const historicoLecturasApi=this.getHistoricoLecturasApiClient();
        const historicoLecturas: HistoricoLecturas[]= await historicoLecturasApi.getHistoricoLecturasByReference(referencia);

        return historicoLecturas;
    }

    static async createHistoricoLecturas(newHistoricoLecturas: HistoricoLecturas): Promise<HistoricoLecturas>{
        const historicoLecturasApi=this.getHistoricoLecturasApiClient();
        const historicoLecturas: HistoricoLecturas= await historicoLecturasApi.createHistoricoLecturas(newHistoricoLecturas);

        return historicoLecturas;
    }

    static async updateHistoricoLecturasById (id:number,updateHistoricoLecturas: HistoricoLecturas): Promise<HistoricoLecturas>{
        const historicoLecturasApi=this.getHistoricoLecturasApiClient();
        const historicoLecturas: HistoricoLecturas= await historicoLecturasApi.updateHistoricoLecturasById(id,updateHistoricoLecturas);

        return historicoLecturas;
    }

    static async deleteHistoricolecturasById (id:number): Promise<DeleteResponse>{
        const historicoLecturasApi=this.getHistoricoLecturasApiClient();
        let response: DeleteResponse= await historicoLecturasApi.deleteHistoricoLecturasById(id);
        return response;
    }

    static async getAllCounterReadingHistoryByFilter (
        counterHistoryFilter: CounterHistoryFilter,
        field?: string,
        sort?: string,
        offset: number = 0,
        limit: number = 50
    ): Promise<HistoricoLecturasPaged> {
        const historicoLecturasApi = this.getHistoricoLecturasApiClient();
        const response: HistoricoLecturasPaged = await historicoLecturasApi.getAllCounterReadingHistoryByFilter(
                offset,
                limit,
                field,
                sort && sort.length > 0 && GetAllCounterReadingHistoryByFilterSortEnum[sort],
                counterHistoryFilter
        );

        return response;
    }

    static async getAllPendingCounters (
        counterHistoryFilter: CounterHistoryFilter,
        field?: string,
        sort?: string,
        offset :number = 0,
        limit: number = 50
    ): Promise<HistoricoLecturasPaged> {
        const historicoLecturasApi = this.getHistoricoLecturasApiClient();
        const response: HistoricoLecturasPaged = await historicoLecturasApi.getPendingCounters(
                offset,
                limit,
                field,
                sort && sort.length > 0 && GetPendingCountersSortEnum[sort],
                counterHistoryFilter
        );

        return response;
    }
}