import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { UserViewModel } from '../../viewmodels/UserViewModel';
import { ChangeEvent, FC, KeyboardEvent, useState } from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { StyledLoginInputRoot, StyledLoginPaper } from "./styles";
import OwnColors from "../../constants/OwnColors";

export interface State {
    password: string;
    email: string;
    showPassword: boolean;
}

const LoginView: FC = () => {

    const [values, setValues] = useState<State>({
        password: "",
        email: "",
        showPassword: false,
    });
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    const handleSubmit = () => {
        setErrorMessage("");
        UserViewModel.getInstance().login({ email: values.email, password: values.password })
            .then((res: boolean) => {
                if (res) {
                    window.scrollTo(0, 0);
                    navigate("/home");
                } else {
                    setErrorMessage("Credenciales inválidas");
                }
            })
    }

    const handleEnterSubmit = (event: KeyboardEvent) => {
        if (event.key === 'Enter') handleSubmit();
    };

    const handleChange = (prop: keyof State) => (event: ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    return (
        <Container
            component="main"
            maxWidth="sm"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '80vh',
            }}
        >
            <StyledLoginPaper
                sx={{
                    padding: 8,
                    width: '100%',
                    borderRadius: 2
                }}
            >
                {errorMessage && (
                    <Typography variant="body2" color="error" align="center" gutterBottom sx={{
                        marginBottom: 5
                    }}>
                        {errorMessage}
                    </Typography>
                )}
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <StyledLoginInputRoot
                            color="error"
                            sx={{ color: OwnColors.LECOCorporate }}
                            required
                            fullWidth
                            value={values.email}
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={handleChange("email")}
                            onKeyUp={handleEnterSubmit}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <StyledLoginInputRoot
                            color="error"
                            sx={{ color: OwnColors.LECOCorporate }}
                            variant="outlined"
                            required
                            fullWidth
                            name="password"
                            value={values.password}
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={handleChange("password")}
                            onKeyUp={handleEnterSubmit}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 5 }}>
                        <Box width='100%' sx={{ '& > *': { my: 1 } }} >
                            <Button
                                fullWidth
                                color='error'
                                sx={{
                                    backgroundColor: OwnColors.LECOCorporate,
                                    color: OwnColors.white,
                                    fontSize: '0.65rem',
                                }}
                                variant="contained"
                                size="large"
                                onClick={() => handleSubmit()}
                            >
                                Continuar
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </StyledLoginPaper>
        </Container>
    );
}

export default observer(LoginView)