import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import SelectorToSortForm from "./components/SelectorToSortForm";
import { RutaViewModel } from "../../../viewmodels/RutaViewModel";
import Snackbar from "@mui/material/Snackbar";
import { StyledButton, StyledDiv } from "./styles";
import { CustomAlert } from "../../../components/CustomAlert";
import { StyledBreadcrumbs, StyledSearchIcon, StyledTypography } from "../../../components/CustomBreadCrumbs";
import { FC, SyntheticEvent, useEffect, useState } from "react";

const RouteSelectorToSortView: FC = () => {
  const navigate = useNavigate();
  const [openError, setOpenError] = useState(false);

  useEffect(() => {
    RutaViewModel.getInstance().getRutas();
    RutaViewModel.getInstance().setCurrentRuta();
  }, []);

  const handleSubmit = () => {
    if (RutaViewModel.getInstance().currentRuta !== null) {
      window.scrollTo(0, 0);
      navigate("/sortRuta");
    } else {
      setOpenError(true);
    }
  };

  const handleCloseError = (
    _event?: SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") return;

    setOpenError(false);
  };

  return (
    <StyledDiv>
      <StyledBreadcrumbs aria-label="breadcrumb">
        <StyledTypography color="textPrimary">
          <StyledSearchIcon />
          Selección de ruta
        </StyledTypography>
      </StyledBreadcrumbs>
      <SelectorToSortForm rutas={RutaViewModel.getInstance().rutas ?? []} />
      <StyledButton
        size="medium"
        variant="outlined"
        color="error"
        onClick={() => handleSubmit()}
      >
        Ordenar ruta
      </StyledButton>
      <Snackbar
        open={openError}
        autoHideDuration={3000}
        onClose={handleCloseError}
      >
        <div>
          <CustomAlert onClose={handleCloseError} severity="error">
            Introduce una ruta
          </CustomAlert>
        </div>
      </Snackbar>
    </StyledDiv>
  );
};

export default observer(RouteSelectorToSortView);
