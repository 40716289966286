import {
  MutableRefObject,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  DataGrid,
  GridColDef,
  GridSortModel,
  GridRenderCellParams,
  GridCallbackDetails,
} from "@mui/x-data-grid";
import {
  Contador,
  CounterHistoryFilter,
  HistoricoLecturas,
  Ruta,
} from "../../../client";
import { StyledGridRowItem } from "../styles";
import {
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  Button,
  DialogActions,
  Snackbar,
  AutocompleteRenderInputParams,
  Autocomplete,
  Alert,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ExportCsv from "../../../components/csvExport/ExportCsv";
import { Link } from "react-router-dom";
import { ReadingDialog } from "./reading-dialog";
import { ContadorViewModel } from "../../../viewmodels/ContadorViewModel";
import { HistoricoLecturasViewModel } from "../../../viewmodels/HistoricoLecturasViewModel";
import { UserViewModel } from "../../../viewmodels/UserViewModel";
import { RutaViewModel } from "../../../viewmodels/RutaViewModel";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import {
  DEFAULT_GRID_INITIAL_STATE,
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
} from "../../../constants/TableConstants";
import {
  calculateTotalConsumption,
  createNewReading,
} from "../../../utils/reading-helper";
import { getDateString } from "../../../utils/DateUtils";
import { getDefaultSlotProps } from "../../../components/datepicker/datePickerUtils";

export interface IHistoricTable {
  historico: HistoricoLecturas[];
  activePage: number;
  totalItems: number;
  filter: CounterHistoryFilter;
  isLoading: boolean;
  sortingField?: string;
  sort?: string;
  handlePageChange: (value: number) => void;
  setFilter: (value: CounterHistoryFilter) => void;
  setSortingField: (value: string) => void;
  setSort: (value: string) => void;
  pageSize: number;
  setPageSize: (size: number) => void;
}

const HistoricTable = ({
  historico,
  totalItems,
  filter,
  isLoading,
  sortingField,
  sort,
  setFilter = (_value: CounterHistoryFilter) => {},
  setSortingField = (_value: string) => {},
  setSort = (_value: string) => {},
  pageSize,
  setPageSize,
  activePage,
  handlePageChange,
}: IHistoricTable) => {
  const [selectedDateInit, setSelectedDateInit] = useState<Date | null>(null);
  const [openDatePicker, setOpenDatePicker] = useState([false, false]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMeter, setSelectedMeter] = useState<Contador | undefined>({});
  const [history, setHistory] = useState<HistoricoLecturas | undefined>({});
  const [routeMeters, setRouteMeters] = useState<Contador[]>([]);
  const [selectedIncidentCode, setSelectedIncidentCode] = useState("");
  const [warningIncidentCode, setWarningIncidentCode] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [newReading, setNewReading] = useState<HistoricoLecturas>({});
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"error" | "success">(
    "success"
  );
  const [routes, setRoutes] = useState<Ruta[]>([]);

  const pickerRef = useRef() as MutableRefObject<HTMLInputElement>;

  const username = UserViewModel.getInstance().loggedUser;

  useEffect(() => {
    (async () => {
      const vmInstance = RutaViewModel.getInstance();
      let vmRoutes = vmInstance.rutas
        ? vmInstance.rutas
        : await vmInstance.getRutas().then(() => vmInstance.rutas ?? []);
      setRoutes([...vmRoutes]);
    })();
  }, []);

  const handleCheckIncidentCode = (
    lastReading: HistoricoLecturas,
    newReading: HistoricoLecturas
  ) => {
    let consumo = 0;
    let incidentCode = newReading.codigoIncidencia ?? "";
    const reading = createNewReading(newReading, selectedMeter, username?.name);

    if (incidentCode !== "Aviso lectura") {
      setNewReading(reading);
      setSelectedIncidentCode(incidentCode);

      if (lastReading) {
        consumo = calculateTotalConsumption(
          Number(lastReading.lecturaContador ?? 0),
          Number(newReading.lecturaContador ?? 0)
        );
      } else consumo = Number(newReading.lecturaContador ?? 0);

      if (consumo === 0) {
        if (incidentCode === "Sin consumo") {
          saveReading(reading);
        } else openWarningDialog("Sin consumo");
      } else if (consumo <= 99 && consumo > 0) {
        saveReading(reading);
      } else if (consumo > 99) {
        if (incidentCode === "Alto consumo") {
          saveReading(reading);
        } else openWarningDialog("Alto consumo");
      } else if (incidentCode === "Lectura última mal") {
        saveReading(reading);
      } else openWarningDialog("Lectura última mal");
    } else saveReading(newReading);
  };

  const saveReading = async (reading: HistoricoLecturas) => {
    await HistoricoLecturasViewModel.getInstance()
      .createHistoricoLecturas(reading)
      .then(() => {
        setSnackbarMessage("Lectura guardada con éxito");
        setSnackbarSeverity("success");
        setOpenDialog(false);
        setFilter({ ...filter });
      })
      .catch(() => {
        setSnackbarMessage("Ocurrió un error");
        setSnackbarSeverity("error");
      });

    setSnackbarOpen(true);
  };

  const openWarningDialog = (message: string) => {
    setWarningMessage(message);
    setWarningIncidentCode(true);
  };

  const handleReferenceClick = async (reference: string) => {
    const meter = routeMeters.find((c: Contador) => c.referencia === reference);
    const history =
      await HistoricoLecturasViewModel.getInstance().getHistoricoLecturasByReference(
        reference
      );
    const lasthistory = history
      .sort((a: HistoricoLecturas, b: HistoricoLecturas) =>
        a.fechaLectura! > b.fechaLectura! ? -1 : 1
      )
      .filter((h: HistoricoLecturas) => {
        if (!["Facturada", "Eliminada"].includes(h.tipoLectura ?? "")) {
          if (h.tipoLectura === "Calculada") {
            return !["Condicionada", "Estimada"].includes(
              h.codigoIncidencia ?? ""
            );
          }
          return true;
        }
        return false;
      })[0];

    setSelectedMeter(meter);
    setHistory(lasthistory);
    setOpenDialog(true);
  };

  const handleCloseSnackbar = () => setSnackbarOpen(false);

  const columns: GridColDef[] = [
    {
      field: "referencia",
      headerClassName: "theme--header",
      headerName: "Referencia",
      type: "string",
      minWidth: 150,
      flex: 1.2,
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => params.value?.referencia?.referencia,
      renderCell: (params: GridRenderCellParams) => (
        <Link
          to="#"
          onClick={() =>
            handleReferenceClick(params.row.referencia?.referencia)
          }
        >
          {params.row.referencia?.referencia}
        </Link>
      ),
    },
    {
      field: "ordenRuta",
      headerClassName: "theme--header",
      headerName: "Orden",
      type: "string",
      minWidth: 110,
      flex: 1,
      headerAlign: "center",
      valueGetter: (params) => params.row.referencia?.ordenRuta,
      align: "center",
    },
    {
      field: "direccion",
      headerClassName: "theme--header",
      headerName: "Direccion",
      headerAlign: "center",
      type: "string",
      flex: 1.2,
      minWidth: 300,
      valueGetter: (params) => params.row.referencia.direccion,
    },
    {
      field: "lecturaContador",
      headerClassName: "theme--header",
      headerName: "Lectura contador",
      type: "string",
      minWidth: 180,
      flex: 1.2,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "fechaLectura",
      headerClassName: "theme--header",
      headerName: "Fecha lectura",
      type: "string",
      minWidth: 150,
      flex: 1.2,
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) =>
        getDateString(params.value ? new Date(params.value) : undefined),
    },
    {
      field: "tipoLectura",
      headerClassName: "theme--header",
      headerName: "Tipo lectura",
      type: "string",
      minWidth: 180,
      flex: 1.2,
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) =>
        params.value?.lecturaContador !== ""
          ? params.value?.tipoLectura
          : "Sin lectura",
    },
  ];

  const handleDatePickerClose = (
    _event?: SyntheticEvent | Event,
    _reason?: string
  ) => setOpenDatePicker([false, false]);

  const handleDateChangeInit = (date) => {
    if (date === null) {
      setSelectedDateInit(date);
      setFilter({ ...filter, firstReadingDate: undefined });
    } else {
      const finalDate = new Date(date);
      if (!isNaN(finalDate.getTime())) {
        setSelectedDateInit(finalDate);
        setFilter({ ...filter, firstReadingDate: finalDate });
        setOpenDatePicker([false, false]);
      }
    }
  };

  const handleRouteFilter = async (value: string) => {
    if (value !== "" && value !== undefined) {
      await ContadorViewModel.getInstance().getContadoresByRoute(value);
      setRouteMeters(ContadorViewModel.getInstance().contadores ?? []);
    } else setRouteMeters([]);
    setFilter({ ...filter, routeId: value });
  };

  const handleSortChange = (
    model: GridSortModel,
    _details: GridCallbackDetails
  ) => {
    if (model.length !== 0) {
      setSortingField(model[0].field.toLowerCase() ?? "");
      setSort(model[0].sort?.toUpperCase() ?? "");
    } else {
      setSortingField("");
      setSort("");
    }
  };

  return (
    <Grid margin={"0px 80px"}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-around"
        rowGap={2}
      >
        <StyledGridRowItem xs={12} md={2} item style={{ textAlign: "center" }}>
          <Autocomplete
            renderInput={(params: AutocompleteRenderInputParams): ReactNode => (
              <TextField label="Id ruta" {...params} variant="standard" />
            )}
            options={routes}
            getOptionLabel={(option: Ruta) => option.idRuta ?? ""}
            style={{ maxWidth: 200, margin: "auto", paddingTop: 10 }}
            onChange={(_evt: SyntheticEvent, value: Ruta | null) =>
              handleRouteFilter(value?.idRuta ?? "")
            }
          />
        </StyledGridRowItem>
        <StyledGridRowItem item style={{ textAlign: "center" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
            <DatePicker
              format="dd/MM/yyyy"
              label="Fecha de lectura inicial"
              value={
                filter.firstReadingDate
                  ? new Date(filter.firstReadingDate)
                  : selectedDateInit
              }
              onChange={handleDateChangeInit}
              open={openDatePicker[0]}
              onClose={handleDatePickerClose}
              ref={pickerRef}
              slotProps={getDefaultSlotProps({
                value: selectedDateInit,
                pickerRef: pickerRef,
                onClickCalendar: () => {
                  openDatePicker[0] = true;
                  setOpenDatePicker([...openDatePicker]);
                },
                onClickClear: () => handleDateChangeInit(null),
              })}
            />
          </LocalizationProvider>
        </StyledGridRowItem>
      </Grid>
      <Grid m={"8px 0"}>
        <ExportCsv
          pending={true}
          filter={filter}
          field={sortingField}
          sort={sort}
        />
      </Grid>
      <DataGrid
        getRowId={(row) => row.idHistoricolecturas}
        rows={historico}
        columns={columns}
        loading={isLoading}
        rowCount={totalItems}
        initialState={DEFAULT_GRID_INITIAL_STATE}
        onPageSizeChange={(size: number) => setPageSize(size)}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        paginationMode="server"
        sortingMode="server"
        autoHeight
        page={activePage}
        // components={{ Toolbar: CustomToolbar, }}
        rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
        onSortModelChange={handleSortChange}
        disableSelectionOnClick
        disableColumnMenu
        localeText={{
          footerTotalVisibleRows: (visibleCount, totalCount) =>
            `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,
          noRowsLabel: "No hay resultados",
        }}
      />
      <ReadingDialog
        props={{
          open: openDialog,
          setOpen: setOpenDialog,
          meter: selectedMeter,
          lastHistory: history,
          handleSaveReading: handleCheckIncidentCode,
        }}
      />
      <Dialog open={warningIncidentCode}>
        <DialogTitle>
          El código de incidencia calculado es:&nbsp;
          <strong>{warningMessage}</strong>. Usted ha seleccionado:&nbsp;
          <strong>{selectedIncidentCode}</strong>
        </DialogTitle>
        <DialogTitle>¿Desea continuar de todas formas?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setWarningIncidentCode(false)} color="error">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              saveReading(newReading);
              setWarningIncidentCode(false);
              setSnackbarOpen(true);
            }}
            color="primary"
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackbarOpen} autoHideDuration={3000}>
        <div>
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </div>
      </Snackbar>
    </Grid>
  );
};

export default HistoricTable;
