import SessionStorage from "../../data/sessionStorage/SessionStorage";


export const checkRoles = (allowedRoles: string[]) => {
    const userRoles = SessionStorage.getRoles()
    for (const sessionRole of userRoles) {
        if (allowedRoles.includes(sessionRole.role)) {
            return true;
        }
    }
    return false
}