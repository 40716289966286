/* tslint:disable */
/* eslint-disable */
/**
 * Aforadores
 * Proxy Service Api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InfoContAdicional
 */
export interface InfoContAdicional {
    /**
     * 
     * @type {string}
     * @memberof InfoContAdicional
     */
    calibre?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoContAdicional
     */
    comentario?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoContAdicional
     */
    fechaInstalacion?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoContAdicional
     */
    foto?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoContAdicional
     */
    gps?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoContAdicional
     */
    marca?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoContAdicional
     */
    modelo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InfoContAdicional
     */
    precintado?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InfoContAdicional
     */
    procesado?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InfoContAdicional
     */
    referencia?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoContAdicional
     */
    tipoLectura?: InfoContAdicionalTipoLecturaEnum;
    /**
     * 
     * @type {string}
     * @memberof InfoContAdicional
     */
    ubicacion?: InfoContAdicionalUbicacionEnum;
}


/**
 * @export
 */
export const InfoContAdicionalTipoLecturaEnum = {
    Manual: 'MANUAL',
    Auto: 'AUTO'
} as const;
export type InfoContAdicionalTipoLecturaEnum = typeof InfoContAdicionalTipoLecturaEnum[keyof typeof InfoContAdicionalTipoLecturaEnum];

/**
 * @export
 */
export const InfoContAdicionalUbicacionEnum = {
    Interior: 'Interior',
    Exterior: 'Exterior',
    Bateria: 'Bateria',
    SoloBasura: 'SoloBasura'
} as const;
export type InfoContAdicionalUbicacionEnum = typeof InfoContAdicionalUbicacionEnum[keyof typeof InfoContAdicionalUbicacionEnum];


/**
 * Check if a given object implements the InfoContAdicional interface.
 */
export function instanceOfInfoContAdicional(value: object): value is InfoContAdicional {
    return true;
}

export function InfoContAdicionalFromJSON(json: any): InfoContAdicional {
    return InfoContAdicionalFromJSONTyped(json, false);
}

export function InfoContAdicionalFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfoContAdicional {
    if (json == null) {
        return json;
    }
    return {
        
        'calibre': json['calibre'] == null ? undefined : json['calibre'],
        'comentario': json['comentario'] == null ? undefined : json['comentario'],
        'fechaInstalacion': json['fechaInstalacion'] == null ? undefined : json['fechaInstalacion'],
        'foto': json['foto'] == null ? undefined : json['foto'],
        'gps': json['gps'] == null ? undefined : json['gps'],
        'marca': json['marca'] == null ? undefined : json['marca'],
        'modelo': json['modelo'] == null ? undefined : json['modelo'],
        'precintado': json['precintado'] == null ? undefined : json['precintado'],
        'procesado': json['procesado'] == null ? undefined : json['procesado'],
        'referencia': json['referencia'] == null ? undefined : json['referencia'],
        'tipoLectura': json['tipoLectura'] == null ? undefined : json['tipoLectura'],
        'ubicacion': json['ubicacion'] == null ? undefined : json['ubicacion'],
    };
}

export function InfoContAdicionalToJSON(value?: InfoContAdicional | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'calibre': value['calibre'],
        'comentario': value['comentario'],
        'fechaInstalacion': value['fechaInstalacion'],
        'foto': value['foto'],
        'gps': value['gps'],
        'marca': value['marca'],
        'modelo': value['modelo'],
        'precintado': value['precintado'],
        'procesado': value['procesado'],
        'referencia': value['referencia'],
        'tipoLectura': value['tipoLectura'],
        'ubicacion': value['ubicacion'],
    };
}

