import { ListContainer, ListItem, StyledButton, StyledSpanField, StyledSpanFieldLong, DragWrapper, StyledListHeader, StyledListBody } from "../styles";
import { Grid } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Contador } from '../../../../client/models/Contador';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import OwnColors from "../../../../constants/OwnColors";
import { CustomAlert } from "../../../../components/CustomAlert";
import { SyntheticEvent, useState } from "react";


export interface ISelectors {
  contadores: Contador[];
  updateFunction: (value: Contador[]) => void
}

const SortTable = ({ contadores, updateFunction }: ISelectors) => {
  const [openAlert, setOpenAlert] = useState(false);

  const handleSubmit = () => {
    setOpenAlert(false);
    updateFunction(contadores)
  }

  const handleCloseError = (_event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;

    setOpenAlert(false);
  };

  return (
    (<div className="App">
      <Grid
        item
        container
        direction="column"
        style={{ padding: 10 }}
        sx={{
          justifyContent: "center",
          alignItems: "flex-end"
        }}>
        <StyledButton
          size="medium"
          variant="outlined"
          color="error"
          onClick={() => handleSubmit()}
        >
          GUARDAR
        </StyledButton>
      </Grid>
      <DragDropContext
        onDragEnd={(param) => {
          const srcI = param.source.index;
          const desI = param.destination?.index;
          if (desI !== null) {
            contadores.splice(desI, 0, contadores.splice(srcI, 1)[0]);
            contadores.forEach((item, index) => {
              item.ordenRuta = index + 1;
            });
            setOpenAlert(true);
          }
        }}
      >
        <ListContainer>
          <StyledListHeader>
            <StyledSpanField> Orden </StyledSpanField>
            <StyledSpanField>Contador </StyledSpanField>
            <StyledSpanField>Ruta </StyledSpanField>
            <StyledSpanFieldLong>Dirección  </StyledSpanFieldLong>
          </StyledListHeader>
          {contadores.length === 0 && <ListItem><CircularProgress /></ListItem>}
          <Droppable droppableId="droppable-1">
            {(provided, _) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {contadores.map((item, i) => (
                  <Draggable
                    key={item.ordenRuta}
                    draggableId={"draggable-" + item.ordenRuta}
                    index={i}
                  >
                    {(provided, snapshot) => (
                      <ListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={{
                          background: item.needsSorting ? OwnColors.LECOWarning : 'white',
                          ...provided.draggableProps.style,
                          boxShadow: snapshot.isDragging
                            ? "0 0 .4rem #666"
                            : "none",
                        }}
                      >
                        <DragWrapper {...provided.dragHandleProps}>
                          <StyledListBody style={{ background: item.needsSorting ? OwnColors.LECOWarning : 'white' }}>
                            <StyledSpanField> {item.ordenRuta}</StyledSpanField>
                            <StyledSpanField>{item.referencia}</StyledSpanField>
                            <StyledSpanField>{item.idRuta?.idRuta} </StyledSpanField>
                            <StyledSpanFieldLong>{item.direccion} </StyledSpanFieldLong>
                          </StyledListBody>
                        </DragWrapper>
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>

          <Snackbar open={openAlert} onClose={handleCloseError}>
            <div>
              <CustomAlert severity="warning">
                Cambios sin guardar
              </CustomAlert>
            </div>
          </Snackbar>
        </ListContainer>
      </DragDropContext>
    </div>)
  );
};

export default SortTable;