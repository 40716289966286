import { observer } from 'mobx-react';
import AppBar from '@mui/material/AppBar';
import { RightSection } from './components/rightSide/RightSection';
import { StyledDivGrow, StyledToolbar, StyledTypography } from './ToolbarStyles';
import { UserViewModel } from '../../viewmodels/UserViewModel';
import CustomDrawer from './components/leftSide/CustomDrawer';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';

const CustomToolbar = () => {
    let loggedUser = UserViewModel.getInstance().loggedUser !== undefined;
    const navigate = useNavigate();

    const logout = () => {
        UserViewModel.getInstance().logout();
        navigate("/login");
    };

    return (
        <StyledDivGrow>
            <AppBar position="static" elevation={0}>
                <StyledToolbar>
                    <CustomDrawer />
                    <StyledTypography variant="h6">
                        <Box
                            sx={{
                                fontWeight: "fontWeightBold",
                                m: 1
                            }}>LECO</Box>
                    </StyledTypography>

                    <RightSection loggedUser={loggedUser} logout={logout} />
                </StyledToolbar>
            </AppBar>
        </StyledDivGrow>
    );
};

export default observer(CustomToolbar);
