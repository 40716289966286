/* tslint:disable */
/* eslint-disable */
/**
 * Proxy Service API
 * Proxy Service API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DeleteResponse,
    DeleteResponseFromJSON,
    DeleteResponseToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Ruta,
    RutaFromJSON,
    RutaToJSON,
} from '../models';

export interface CreateRutaRequest {
    ruta?: Ruta;
}

export interface DeleteRutaByIdRequest {
    idRuta: string;
}

export interface GetRutaByIdRequest {
    idRuta: string;
}

export interface UpdateRutaByIdRequest {
    idRuta: string;
    ruta?: Ruta;
}

/**
 * no description
 */
export class RutaApi extends runtime.BaseAPI {

    /**
     * Endpoint for createRuta
     * createRuta
     */
    async createRutaRaw(requestParameters: CreateRutaRequest): Promise<runtime.ApiResponse<Ruta>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/rutas`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RutaToJSON(requestParameters.ruta),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RutaFromJSON(jsonValue));
    }

    /**
     * Endpoint for createRuta
     * createRuta
     */
    async createRuta(ruta?: Ruta): Promise<Ruta> {
        const response = await this.createRutaRaw({ ruta: ruta });
        return await response.value();
    }

    /**
     * deleteRutaById
     * deleteRutaById
     */
    async deleteRutaByIdRaw(requestParameters: DeleteRutaByIdRequest): Promise<runtime.ApiResponse<DeleteResponse>> {
        if (requestParameters.idRuta === null || requestParameters.idRuta === undefined) {
            throw new runtime.RequiredError('idRuta','Required parameter requestParameters.idRuta was null or undefined when calling deleteRutaById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/rutas/{idRuta}`.replace(`{${"idRuta"}}`, encodeURIComponent(String(requestParameters.idRuta))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteResponseFromJSON(jsonValue));
    }

    /**
     * deleteRutaById
     * deleteRutaById
     */
    async deleteRutaById(idRuta: string): Promise<DeleteResponse> {
        const response = await this.deleteRutaByIdRaw({ idRuta: idRuta });
        return await response.value();
    }

    /**
     * Endpoint for list all rutas
     * getAllRutas
     */
    async getAllRutasRaw(): Promise<runtime.ApiResponse<Array<Ruta>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/rutas`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RutaFromJSON));
    }

    /**
     * Endpoint for list all rutas
     * getAllRutas
     */
    async getAllRutas(): Promise<Array<Ruta>> {
        const response = await this.getAllRutasRaw();
        return await response.value();
    }

    /**
     * getRutaById
     * getRutaById
     */
    async getRutaByIdRaw(requestParameters: GetRutaByIdRequest): Promise<runtime.ApiResponse<Ruta>> {
        if (requestParameters.idRuta === null || requestParameters.idRuta === undefined) {
            throw new runtime.RequiredError('idRuta','Required parameter requestParameters.idRuta was null or undefined when calling getRutaById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/rutas/{idRuta}`.replace(`{${"idRuta"}}`, encodeURIComponent(String(requestParameters.idRuta))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RutaFromJSON(jsonValue));
    }

    /**
     * getRutaById
     * getRutaById
     */
    async getRutaById(idRuta: string): Promise<Ruta> {
        const response = await this.getRutaByIdRaw({ idRuta: idRuta });
        return await response.value();
    }

    /**
     * updateRutaById
     * updateRutaById
     */
    async updateRutaByIdRaw(requestParameters: UpdateRutaByIdRequest): Promise<runtime.ApiResponse<Ruta>> {
        if (requestParameters.idRuta === null || requestParameters.idRuta === undefined) {
            throw new runtime.RequiredError('idRuta','Required parameter requestParameters.idRuta was null or undefined when calling updateRutaById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/rutas/{idRuta}`.replace(`{${"idRuta"}}`, encodeURIComponent(String(requestParameters.idRuta))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RutaToJSON(requestParameters.ruta),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RutaFromJSON(jsonValue));
    }

    /**
     * updateRutaById
     * updateRutaById
     */
    async updateRutaById(idRuta: string, ruta?: Ruta): Promise<Ruta> {
        const response = await this.updateRutaByIdRaw({ idRuta: idRuta, ruta: ruta });
        return await response.value();
    }

}
