/* tslint:disable */
/* eslint-disable */
/**
 * Aforadores
 * Proxy Service Api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Ruta
 */
export interface Ruta {
    /**
     * 
     * @type {string}
     * @memberof Ruta
     */
    descripcion?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Ruta
     */
    estado?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Ruta
     */
    idRuta?: string;
}

/**
 * Check if a given object implements the Ruta interface.
 */
export function instanceOfRuta(value: object): value is Ruta {
    return true;
}

export function RutaFromJSON(json: any): Ruta {
    return RutaFromJSONTyped(json, false);
}

export function RutaFromJSONTyped(json: any, ignoreDiscriminator: boolean): Ruta {
    if (json == null) {
        return json;
    }
    return {
        
        'descripcion': json['descripcion'] == null ? undefined : json['descripcion'],
        'estado': json['estado'] == null ? undefined : json['estado'],
        'idRuta': json['idRuta'] == null ? undefined : json['idRuta'],
    };
}

export function RutaToJSON(value?: Ruta | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'descripcion': value['descripcion'],
        'estado': value['estado'],
        'idRuta': value['idRuta'],
    };
}

