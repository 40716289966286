import { Accordion, AccordionSummary, Grid, Typography, AccordionDetails } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Contador } from "../../../../client";
import { StyledGridAddressSelectorMainContainer, StyledGridAddressSelectorTableHeaders, StyledTypographyAddressSelectorTitles } from "../styles";
import { FC } from "react";

type TAddressSelector = {
  countersByField: Contador[];
  handleSearchReference: (c: Contador) => void;
  address?: string;
}

export const AddressSelector: FC<{ props: TAddressSelector }> = (({ props }) => {
  const buildEachRow = (c: Contador) => (
    <Grid container style={{ padding: 5 }} key={c.referencia}>
      <Grid item xs={2}><Typography align='left'><Link to="#" onClick={() => props.handleSearchReference(c)}>{c.referencia}</Link></Typography></Grid>
      <Grid item xs={2}><Typography align='left'>{c.numeroCont}</Typography></Grid>
      <Grid item xs={8}><Typography align='left'>{c.direccion}</Typography></Grid>
    </Grid>
  )

  return (
    (<StyledGridAddressSelectorMainContainer container justifyContent='center'>
      <Grid item xs={8}>
        <Accordion elevation={5}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <StyledTypographyAddressSelectorTitles>{`Coincidencias para los datos consultados: ${props.address}`}</StyledTypographyAddressSelectorTitles>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container item xs={12}>
              <StyledGridAddressSelectorTableHeaders container item xs={12} justifyContent="center" >
                <Grid item xs={2}><StyledTypographyAddressSelectorTitles>Referencia</StyledTypographyAddressSelectorTitles></Grid>
                <Grid item xs={2}><StyledTypographyAddressSelectorTitles>Num. Contador</StyledTypographyAddressSelectorTitles></Grid>
                <Grid item xs={8}><StyledTypographyAddressSelectorTitles>Dirección</StyledTypographyAddressSelectorTitles></Grid>
              </StyledGridAddressSelectorTableHeaders>
              <Grid container item xs={12} sx={{ justifyContent: "center" }}>
                {props.countersByField.map((c: Contador) => buildEachRow(c))}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </StyledGridAddressSelectorMainContainer>)
  );
});