import { User, Credentials, DeleteResponse, LoginResponse } from "../../client";
import { LoginApi } from "../../client/apis/LoginApi";
import { UserApi } from "../../client/apis/UserApi";
import { ApiClient, Api } from "./ApiClient";


export default class UserRepository {

    private static getUserApiClient() {
        return ApiClient.getClient(Api.UserApi) as UserApi;
    }

    private static getLoginApiClient() {
        return ApiClient.getClient(Api.LoginApi) as LoginApi;
    }

    static async getUsers(): Promise<User[]> {
        const userApi = this.getUserApiClient();
        const users: User[] = await userApi.getAllAppUsers();

        return users;
    }

    static async getUser(id: number): Promise<User> {
        const userApi = this.getUserApiClient();
        const user: User = await userApi.getAppUserById(id);

        return user;
    }

    static async createUser(newUser: User): Promise<User> {
        const userApi = this.getUserApiClient();
        const user: User = await userApi.createAppUser(newUser);

        return user;
    }

    static async updateUserById(id: number, updateUser: User): Promise<User> {
        const userApi = this.getUserApiClient();
        const user: User = await userApi.updateAppUserById(id, updateUser)

        return user;
    }

    static async deleteUserById(id: number): Promise<DeleteResponse> {
        const userApi = this.getUserApiClient();
        let response: DeleteResponse = await userApi.deleteAppUserById(id);
        return response;
    }

    /* static doLogin(email:string, password:string){
        return new Promise<LoginResponse>((resolve,reject)=>{
            let credentials: Credentials = {email: email, password: password};
            this.getLoginApiClient().login(credentials).then((res: LoginResponse)=>{
                resolve(res);
            })
            .catch(err=>reject(err))
        })
    } */

    static async login(credentials: Credentials): Promise<LoginResponse | undefined> {
        const api = this.getLoginApiClient();
        let result;
        try {
            result = await api.login(credentials);
        } catch (error) {
            // TODO: handle error
        }

        return result;
    }
}