import { styled, Button } from "@mui/material";
import OwnColors from "../../constants/OwnColors";
import { DataGrid } from "@mui/x-data-grid";

const StyledDeleteButton = styled(Button)(() => ({
    color: 'white',
    backgroundColor: 'tomato',
    '&:hover': {
        backgroundColor: 'darkred',
    },
}));

const StyledEditButton = styled(Button)(() => ({
    color: 'white',
    backgroundColor:'CornflowerBlue',
    '&:hover': {
        backgroundColor: 'darkblue',
    },
}));

const StyledGeolocationButton = styled(Button)(() => ({
    color: 'white',
    backgroundColor:'Green',
    '&:hover': {
        backgroundColor: 'darkgreen',
    },
}));

const StyledCreateButton = styled(Button)(({ theme }) => ({
    fontSize:"14px",
    margin: theme.spacing(0.5,1,0.9,0),
    float: "right",
    color: 'white',
    backgroundColor:'seagreen',
    '&:hover': {
        backgroundColor: 'darkgreen',
    },
}));

const StyledDiv = styled('div')(({ theme }) => ({
    width: "auto",
    margin: theme.spacing(0, 10, 0, 10),
    '& .theme--header': {
        backgroundColor: OwnColors.LECOlightRed,
        color: "black",
        justify: "center"
    },
}));

const StyledDataGrid = styled(DataGrid)(() => ({
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
        outline: 'none',
    },
}));

export { StyledCreateButton, StyledDeleteButton, StyledDiv, StyledEditButton, StyledDataGrid, StyledGeolocationButton };
