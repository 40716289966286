/* tslint:disable */
/* eslint-disable */
/**
 * Proxy Service API
 * Proxy Service API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DeleteResponse,
    DeleteResponseFromJSON,
    DeleteResponseToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    InfoContAdicional,
    InfoContAdicionalFromJSON,
    InfoContAdicionalToJSON,
} from '../models';

export interface CreateInfoContAdicionalRequest {
    infoContAdicional?: InfoContAdicional;
}

export interface DeleteInfoContAdicionalByReferenceRequest {
    referencia: string;
}

export interface GetInfoContAdicionalByReferenceRequest {
    referencia: string;
}

export interface UpdateInfoContAdicionalByReferenceRequest {
    referencia: string;
    infoContAdicional?: InfoContAdicional;
}

/**
 * no description
 */
export class InfoContAdicionalApi extends runtime.BaseAPI {

    /**
     * Endpoint for createInfoContAdicional
     * createInfoContAdicional
     */
    async createInfoContAdicionalRaw(requestParameters: CreateInfoContAdicionalRequest): Promise<runtime.ApiResponse<InfoContAdicional>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/infocontadicional`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InfoContAdicionalToJSON(requestParameters.infoContAdicional),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InfoContAdicionalFromJSON(jsonValue));
    }

    /**
     * Endpoint for createInfoContAdicional
     * createInfoContAdicional
     */
    async createInfoContAdicional(infoContAdicional?: InfoContAdicional): Promise<InfoContAdicional> {
        const response = await this.createInfoContAdicionalRaw({ infoContAdicional: infoContAdicional });
        return await response.value();
    }

    /**
     * deleteInfoContAdicionalByReference
     * deleteInfoContAdicionalByReference
     */
    async deleteInfoContAdicionalByReferenceRaw(requestParameters: DeleteInfoContAdicionalByReferenceRequest): Promise<runtime.ApiResponse<DeleteResponse>> {
        if (requestParameters.referencia === null || requestParameters.referencia === undefined) {
            throw new runtime.RequiredError('referencia','Required parameter requestParameters.referencia was null or undefined when calling deleteInfoContAdicionalByReference.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/infocontadicional/{referencia}`.replace(`{${"referencia"}}`, encodeURIComponent(String(requestParameters.referencia))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteResponseFromJSON(jsonValue));
    }

    /**
     * deleteInfoContAdicionalByReference
     * deleteInfoContAdicionalByReference
     */
    async deleteInfoContAdicionalByReference(referencia: string): Promise<DeleteResponse> {
        const response = await this.deleteInfoContAdicionalByReferenceRaw({ referencia: referencia });
        return await response.value();
    }

    /**
     * Endpoint for list all infoContAdicional
     * getAllInfoContAdicional
     */
    async getAllInfoContAdicionalRaw(): Promise<runtime.ApiResponse<Array<InfoContAdicional>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/infocontadicional`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InfoContAdicionalFromJSON));
    }

    /**
     * Endpoint for list all infoContAdicional
     * getAllInfoContAdicional
     */
    async getAllInfoContAdicional(): Promise<Array<InfoContAdicional>> {
        const response = await this.getAllInfoContAdicionalRaw();
        return await response.value();
    }

    /**
     * getInfoContAdicionalByReference
     * getInfoContAdicionalByReference
     */
    async getInfoContAdicionalByReferenceRaw(requestParameters: GetInfoContAdicionalByReferenceRequest): Promise<runtime.ApiResponse<InfoContAdicional>> {
        if (requestParameters.referencia === null || requestParameters.referencia === undefined) {
            throw new runtime.RequiredError('referencia','Required parameter requestParameters.referencia was null or undefined when calling getInfoContAdicionalByReference.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/infocontadicional/{referencia}`.replace(`{${"referencia"}}`, encodeURIComponent(String(requestParameters.referencia))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InfoContAdicionalFromJSON(jsonValue));
    }

    /**
     * getInfoContAdicionalByReference
     * getInfoContAdicionalByReference
     */
    async getInfoContAdicionalByReference(referencia: string): Promise<InfoContAdicional> {
        const response = await this.getInfoContAdicionalByReferenceRaw({ referencia: referencia });
        return await response.value();
    }

    /**
     * updateInfoContAdicionalByReference
     * updateInfoContAdicionalByReference
     */
    async updateInfoContAdicionalByReferenceRaw(requestParameters: UpdateInfoContAdicionalByReferenceRequest): Promise<runtime.ApiResponse<InfoContAdicional>> {
        if (requestParameters.referencia === null || requestParameters.referencia === undefined) {
            throw new runtime.RequiredError('referencia','Required parameter requestParameters.referencia was null or undefined when calling updateInfoContAdicionalByReference.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/infocontadicional/{referencia}`.replace(`{${"referencia"}}`, encodeURIComponent(String(requestParameters.referencia))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InfoContAdicionalToJSON(requestParameters.infoContAdicional),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InfoContAdicionalFromJSON(jsonValue));
    }

    /**
     * updateInfoContAdicionalByReference
     * updateInfoContAdicionalByReference
     */
    async updateInfoContAdicionalByReference(referencia: string, infoContAdicional?: InfoContAdicional): Promise<InfoContAdicional> {
        const response = await this.updateInfoContAdicionalByReferenceRaw({ referencia: referencia, infoContAdicional: infoContAdicional });
        return await response.value();
    }

}
