import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import SessionStorage from "../../../../data/sessionStorage/SessionStorage";
import { StyledIconButton } from "../../ToolbarStyles";
import { MenuContent } from "./MenuContent";
import { useState } from "react";

const CustomDrawer = () => {
  const [open, setOpen] = useState(false);
  const isLoggedIn = SessionStorage.isLoggedIn();

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  return (
    <>
      {isLoggedIn ?
        <StyledIconButton edge="start" color="inherit" aria-label="open drawer" onClick={handleOpen}>
          <MenuIcon />
        </StyledIconButton>
        :
        null
      }
      <Drawer open={open} onClose={handleClose} onClick={handleClose}>
        <MenuContent />
      </Drawer>
    </>
  );
};

export default CustomDrawer;
