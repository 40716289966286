import { styled, TextField } from "@mui/material";
import OwnColors from "../../constants/OwnColors";

const StyledLoginPaper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const StyledLoginInputRoot = styled(TextField)(() => ({
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: OwnColors.LECOlightRed,
  },
  "&:hover .MuiInputLabel-root": {
    color: OwnColors.LECOCorporate,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: OwnColors.LECOCorporate,
  },
}));

export { StyledLoginInputRoot, StyledLoginPaper }
