import { IconButton, styled, Toolbar, Typography } from "@mui/material";
import OwnColors from "../../constants/OwnColors";

const StyledDivGrow = styled('div')(() => ({
  width: "100%",
  margin: 0,
  padding: 0,
  flexGrow: 1,
  display: "flex",
}));

const StyledToolbar = styled(Toolbar)(() => ({
  height: "4.7rem",
  justifyContent: "space-between",
  background: OwnColors.LECOCorporate,
  color: "#EEEEEE",
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: "block",
  textAlign: "center",
  [theme.breakpoints.down("xs")]: {
    maxWidth: "40%",
  },
}));

const StyledRightSection = styled("div")(() => ({
  display: "flex",
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

export { StyledDivGrow, StyledRightSection, StyledTypography, StyledToolbar, StyledIconButton };
