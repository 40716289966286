import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  Button,
  Snackbar,
  Autocomplete,
} from "@mui/material";
import SpeedIcon from "@mui/icons-material/Speed";
import PersonIcon from "@mui/icons-material/Person";
import RoomIcon from "@mui/icons-material/Room";
import InfoIcon from "@mui/icons-material/Info";
import { MutableRefObject, useRef, useState } from "react";
import { ContadorViewModel } from "../../../viewmodels/ContadorViewModel";
import { InfoContAdicionalViewModel } from "../../../viewmodels/InfoContAdicionalViewModel";
import {
  Contador,
  ContadorEstadoEnum,
  ContadorNocturnoEnum,
  InfoContAdicional,
  InfoContAdicionalTipoLecturaEnum,
  InfoContAdicionalUbicacionEnum,
  Ruta,
} from "../../../client";
import { styled } from "@mui/material/styles";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import { CustomAlert } from "../../../components/CustomAlert";
import { getDefaultSlotProps } from "../../../components/datepicker/datePickerUtils";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { datePickerLabelStyle } from "../../../components/datepicker/datePickerStyle";

const StyledCard = styled(Card)(() => ({
  minWidth: 275,
}));

export interface ICounterForm {
  onClose: Function;
  editCounter?: Contador;
  editInfoContAdicional?: InfoContAdicional;
  rutas: Ruta[];
}

const CounterForm = (params: ICounterForm) => {
  const [editCounter] = useState(params.editCounter);
  const [editInfoContAdicional] = useState(params.editInfoContAdicional);
  const [referencia, setReferencia] = useState(
    editCounter ? editCounter.referencia! : ""
  );
  const [numContador, setNumContador] = useState(
    editCounter ? editCounter.numeroCont! : ""
  );
  const [estado, setEstado] = useState(
    editCounter ? editCounter.estado : "CREADO"
  );
  const [comentario, setComentario] = useState(
    editInfoContAdicional ? editInfoContAdicional.comentario! : ""
  );
  const [titular, setTitular] = useState(
    editCounter ? editCounter.titular! : ""
  );
  const [direccionTitular, setDireccionTitular] = useState(
    editCounter ? editCounter.direccion! : ""
  );
  const [idRuta, setIdRuta] = useState(editCounter?.idRuta);
  const [calibre, setCalibre] = useState(
    editInfoContAdicional ? editInfoContAdicional.calibre! : ""
  );
  const [marca, setMarca] = useState(
    editInfoContAdicional ? editInfoContAdicional.marca! : ""
  );
  const [modelo, setModelo] = useState(
    editInfoContAdicional ? editInfoContAdicional.modelo! : ""
  );
  const [numBasuras, setNumBasuras] = useState(editCounter?.numBasuras);
  const [fechaAlta, setFechaAlta] = useState<Date | null>(
    editCounter ? new Date(editCounter.fechaAlta!) : null
  );
  const [fechaBaja, setFechaBaja] = useState<Date | null>(
    editCounter ? new Date(editCounter.fechaBaja!) : null
  );
  const [fechaInstalacion, setFechaInstalacion] = useState<Date | null>(
    editInfoContAdicional ? new Date(editInfoContAdicional.fechaInstalacion!) : null
  );
  const [ubicacion, setUbicacion] = useState<InfoContAdicionalUbicacionEnum>(
    editInfoContAdicional
      ? editInfoContAdicional.ubicacion!
      : InfoContAdicionalUbicacionEnum.Interior
  );
  const [precintado, setPrecintado] = useState(
    editInfoContAdicional !== undefined
  );
  const [mensual, setMensual] = useState(editCounter !== undefined);
  const [nocturno, setNocturno] = useState<ContadorNocturnoEnum>(
    editCounter?.nocturno ?? ContadorNocturnoEnum.Normal
  );
  const [tipoContador, setTipoContador] = useState<string>(
    editInfoContAdicional
      ? editInfoContAdicional.tipoLectura!
      : InfoContAdicionalTipoLecturaEnum.Manual
  );
  const [openDatePicker, setOpenDatePicker] = useState([false, false, false]);

  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [errorSnackbarEdit, setErrorSnackbarEdit] = useState(false);

  const pickerRefs = [
    useRef() as MutableRefObject<HTMLInputElement>,
    useRef() as MutableRefObject<HTMLInputElement>,
    useRef() as MutableRefObject<HTMLInputElement>,
  ];

  const getSealedValue = () => {
    if (precintado === undefined) return undefined;
    if (precintado) return "true";
    else return "false";
  };
  const getMonthlyValue = () => {
    if (mensual === undefined) return undefined;
    if (mensual) return "true";
    else return "false";
  };

  const fixReferencia = (referencia: string) => referencia.split(" ").join("_");

  const submitEdit = async () => {
    try {
      const oldRoute = editCounter?.idRuta?.idRuta;
      //await ContadorViewModel.getInstance().getContadores()
      const allContadores = ContadorViewModel.getInstance().contadores;

      let ordenRuta: number =
        oldRoute === idRuta?.idRuta!
          ? editCounter?.ordenRuta!
          : fixRoutePosition(allContadores ?? []);
      const contador = ContadorViewModel.getInstance().currentContador;
      const infoContAdicional =
        InfoContAdicionalViewModel.getInstance().currentInfoContAdicional;

      //Rellenar objetos con los parametros de los formularios
      //InfoContAdicional
      //infoContAdicional!!.referencia = referencia
      infoContAdicional!.calibre = calibre;
      infoContAdicional!.comentario = comentario;
      infoContAdicional!.fechaInstalacion = fechaInstalacion?.toLocaleDateString();
      infoContAdicional!.foto = "";
      infoContAdicional!.gps = "";
      infoContAdicional!.marca = marca;
      infoContAdicional!.modelo = modelo;
      infoContAdicional!.precintado = precintado;
      infoContAdicional!.procesado = true;
      infoContAdicional!.tipoLectura =
        tipoContador as InfoContAdicionalTipoLecturaEnum;
      infoContAdicional!.ubicacion = ubicacion;
      //Contador
      //contador!!.referencia = referencia
      contador!.numeroCont = numContador;
      contador!.estado = estado as ContadorEstadoEnum;
      contador!.titular = titular;
      contador!.direccion = direccionTitular;
      contador!.idRuta = idRuta;
      contador!.ordenRuta = ordenRuta;
      contador!.numBasuras = numBasuras;
      contador!.fechaAlta = fechaAlta?.toLocaleDateString();
      contador!.fechaBaja = fechaBaja?.toLocaleDateString();
      contador!.mensual = mensual;
      contador!.nocturno = nocturno;
      //contador!!.infoContAdicional = infoContAdicional

      await InfoContAdicionalViewModel.getInstance().updateInfoContAdicionalByReference(
        referencia,
        infoContAdicional!
      );
      await ContadorViewModel.getInstance().updateContadorByReference(
        referencia,
        contador!
      );

      params.onClose(contador);
    } catch (error) {
      setErrorSnackbar(true);
    }
  };

  const fixRoutePosition = (meters: Contador[]): number => {
    const newIndex = meters
      .filter((m: Contador) => m.ordenRuta! < 0)
      .sort(
        (a: Contador, b: Contador) => a.ordenRuta! - b.ordenRuta!
      )[0]?.ordenRuta;
    return newIndex ? newIndex - 1 : 0;
  };

  const submitCreate = async () => {
    try {
      const allContadores = ContadorViewModel.getInstance().contadores;

      let ordenRuta = fixRoutePosition(allContadores ?? []);

      const contador: Contador = {};
      const infoContAdicional: InfoContAdicional = {};

      //Rellenar objetos con los parametros de los formularios
      //InfoContAdicional
      infoContAdicional.referencia = fixReferencia(referencia);
      infoContAdicional.calibre = calibre;
      infoContAdicional.comentario = comentario;
      infoContAdicional.fechaInstalacion = fechaInstalacion?.toLocaleDateString();
      infoContAdicional.foto = "";
      infoContAdicional.gps = "";
      infoContAdicional.marca = marca;
      infoContAdicional.modelo = modelo;
      infoContAdicional.precintado = precintado;
      infoContAdicional.procesado = true;
      infoContAdicional.tipoLectura =
        tipoContador as InfoContAdicionalTipoLecturaEnum;
      infoContAdicional.ubicacion = ubicacion;

      //Contador
      contador.referencia = fixReferencia(referencia);
      contador.numeroCont = numContador;
      contador.estado = estado as ContadorEstadoEnum;
      contador.titular = titular;
      contador.direccion = direccionTitular;
      contador.idRuta = idRuta;
      contador.ordenRuta = ordenRuta;
      contador.numBasuras = numBasuras;
      contador.fechaAlta = fechaAlta?.toLocaleDateString();
      contador.fechaBaja = fechaBaja?.toLocaleDateString();
      contador.mensual = mensual;
      contador.nocturno = nocturno;
      contador.infoContAdicional = infoContAdicional;
      contador.user = "";

      await InfoContAdicionalViewModel.getInstance().createInfoContAdicional(
        infoContAdicional
      );
      await ContadorViewModel.getInstance().createContador(contador);
      params.onClose(contador);
    } catch (error) {
      setErrorSnackbar(true);
    }
  };

  const handleDatePickerOpen = (index: number) => {
    const activeDatePickers = [...openDatePicker];
    activeDatePickers[index] = true;
    setOpenDatePicker(activeDatePickers);
  };

  const handleDatePickerClose = (index: number) => {
    const activeDatePickers = [...openDatePicker];
    activeDatePickers[index] = false;
    setOpenDatePicker(activeDatePickers);
  };

  const handleRutaSelect = (_event, value) => {
    if (value !== null) setIdRuta(value);
    else setIdRuta(undefined);
  };

  return (
    <Grid item xs={12}>
      <Grid
        item
        container
        spacing={6}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          container
          xs={12}
          spacing={3}
          style={{ marginBottom: "2vw", marginTop: "2vw" }}
          justifyContent="center"
        >
          <Grid item xs={10}>
            <StyledCard>
              <CardHeader
                title={
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    style={{ marginBottom: "-3vw", marginTop: "-1vw" }}
                  >
                    <SpeedIcon />
                    &nbsp;
                    <p>Información del contador</p>
                  </Grid>
                }
              ></CardHeader>
              <CardContent>
                <TextField
                  color="error"
                  autoFocus
                  variant="outlined"
                  margin="dense"
                  value={referencia}
                  onChange={(event) => setReferencia(event.target.value)}
                  label="Referencia"
                  fullWidth
                  required
                  disabled={params.editCounter !== undefined}
                />
                <TextField
                  color="error"
                  variant="outlined"
                  margin="dense"
                  value={numContador}
                  onChange={(event) => setNumContador(event.target.value)}
                  label="Número de contador"
                  type="text"
                  fullWidth
                  required
                />
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  <InputLabel color="error">Estado * </InputLabel>
                  <Select
                    defaultValue={""}
                    color="error"
                    variant="outlined"
                    value={estado ?? " "}
                    onChange={(event) => setEstado(event.target.value as ContadorEstadoEnum)}
                    label="Estado  ."
                    fullWidth
                    required
                    disabled={!editCounter}
                  >
                    <MenuItem value="CREADO">Creado</MenuItem>
                    <MenuItem value="ALTA">Alta</MenuItem>
                    <MenuItem value="BAJA">Baja</MenuItem>
                    <MenuItem value="BAJACONFIRMADA">Baja confirmada</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  color="error"
                  variant="outlined"
                  multiline
                  rows={4}
                  label="Comentario"
                  value={comentario}
                  onChange={(event) => setComentario(event.target.value)}
                  fullWidth
                />
              </CardContent>
            </StyledCard>
          </Grid>
          <Grid item xs={10}>
            <StyledCard>
              <CardHeader
                title={
                  <Grid
                    item
                    container
                    direction="row"
                    style={{ marginBottom: "-3vw", marginTop: "-1vw" }}
                    sx={{
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <PersonIcon />
                    &nbsp;
                    <p>Información del titular</p>
                  </Grid>
                }
              ></CardHeader>
              <CardContent>
                <TextField
                  color="error"
                  variant="outlined"
                  margin="dense"
                  value={titular}
                  onChange={(event) => setTitular(event.target.value)}
                  label="Titular"
                  fullWidth
                  required
                />
                <TextField
                  color="error"
                  variant="outlined"
                  margin="dense"
                  value={direccionTitular}
                  onChange={(event) => setDireccionTitular(event.target.value)}
                  label="Dirección del titular"
                  type="text"
                  fullWidth
                  required
                />
              </CardContent>
            </StyledCard>
          </Grid>
          <Grid item xs={10}>
            <StyledCard>
              <CardHeader
                title={
                  <Grid
                    item
                    container
                    direction="row"
                    style={{ marginBottom: "-3vw", marginTop: "-1vw" }}
                    sx={{
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <RoomIcon />
                    &nbsp;
                    <p>Información de la ruta</p>
                  </Grid>
                }
              ></CardHeader>
              <CardContent>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  <Autocomplete
                    options={params.rutas}
                    getOptionLabel={(option) => option.idRuta?.toString()!}
                    value={idRuta}
                    onChange={handleRutaSelect}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        color="error"
                        required
                        {...params}
                        label="Id ruta"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </CardContent>
            </StyledCard>
          </Grid>
          <Grid item xs={10}>
            <StyledCard>
              <CardHeader
                title={
                  <Grid
                    item
                    container
                    direction="row"
                    style={{ marginBottom: "-3vw", marginTop: "-1vw" }}
                    sx={{
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <InfoIcon />
                    &nbsp;
                    <p>Información adicional del contador</p>
                  </Grid>
                }
              ></CardHeader>
              <CardContent>
                <TextField
                  color="error"
                  variant="outlined"
                  margin="dense"
                  value={calibre}
                  onChange={(event) => setCalibre(event.target.value)}
                  label="Calibre"
                  type="text"
                  fullWidth
                  required
                />
                <TextField
                  color="error"
                  variant="outlined"
                  margin="dense"
                  value={marca}
                  onChange={(event) => setMarca(event.target.value)}
                  label="Marca"
                  type="text"
                  fullWidth
                />
                <TextField
                  color="error"
                  variant="outlined"
                  margin="dense"
                  value={modelo}
                  onChange={(event) => setModelo(event.target.value)}
                  label="Modelo"
                  type="text"
                  fullWidth
                />
                <TextField
                  color="error"
                  variant="outlined"
                  margin="dense"
                  value={numBasuras}
                  onChange={(event) => {
                    if (!Number.isNaN(Number(event.target.value))) {
                      setNumBasuras(Number(event.target.value));
                    } else {
                      setNumBasuras(0);
                    }
                  }}
                  label="Nº basuras"
                  type="text"
                  fullWidth
                  required
                />
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                  <DatePicker
                    format="dd/MM/yyyy"
                    label="Fecha alta"
                    open={openDatePicker[0]}
                    onClose={() => handleDatePickerClose(0)}
                    value={fechaAlta}
                    ref={pickerRefs[0]}
                    sx={datePickerLabelStyle}
                    onChange={(date) => {
                      setFechaAlta(date as Date);
                      handleDatePickerClose(0);
                    }}
                    slotProps={getDefaultSlotProps({
                      value: fechaAlta,
                      pickerRef: pickerRefs[0],
                      onClickCalendar: () => {
                        openDatePicker[0] = true;
                        handleDatePickerOpen(0);
                      },
                      onClickClear: () => setFechaAlta(null),
                    })}
                  />
                  <DatePicker
                    format="dd/MM/yyyy"
                    label="Fecha baja"
                    maxDate={new Date(4000, 11, 31)}
                    open={openDatePicker[1]}
                    onClose={() => handleDatePickerClose(1)}
                    value={fechaBaja}
                    ref={pickerRefs[1]}
                    sx={datePickerLabelStyle}
                    onChange={(date) => {
                      setFechaBaja(date as Date);
                      handleDatePickerClose(1);
                    }}
                    slotProps={getDefaultSlotProps({
                      value: fechaBaja,
                      pickerRef: pickerRefs[1],
                      onClickCalendar: () => {
                        openDatePicker[1] = true;
                        handleDatePickerOpen(1);
                      },
                      onClickClear: () => setFechaBaja(null),
                    })}
                  />
                  <DatePicker
                    format="dd/MM/yyyy"
                    label="Fecha instalación"
                    open={openDatePicker[2]}
                    onClose={() => handleDatePickerClose(2)}
                    value={fechaInstalacion}
                    ref={pickerRefs[2]}
                    sx={datePickerLabelStyle}
                    onChange={(date) => {
                      setFechaInstalacion(date as Date);
                      handleDatePickerClose(2);
                    }}
                    slotProps={getDefaultSlotProps({
                      value: fechaInstalacion,
                      pickerRef: pickerRefs[2],
                      onClickCalendar: () => {
                        openDatePicker[2] = true;
                        handleDatePickerOpen(2);
                      },
                      onClickClear: () => setFechaInstalacion(null),
                    })}
                  />
                </LocalizationProvider>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  <InputLabel color="error">Ubicación *</InputLabel>
                  <Select
                    defaultValue={""}
                    color="error"
                    variant="outlined"
                    value={ubicacion ?? " "}
                    onChange={(event) =>
                      setUbicacion(
                        InfoContAdicionalUbicacionEnum[event.target.value]
                      )
                    }
                    label="Ubicación   ."
                    fullWidth
                    required
                  >
                    <MenuItem value="Interior">Interior</MenuItem>
                    <MenuItem value="Exterior">Exterior</MenuItem>
                    <MenuItem value="Bateria">Bateria</MenuItem>
                    <MenuItem value="SoloBasura">Solo Basura</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  <InputLabel color="error">Precintado *</InputLabel>
                  <Select
                    defaultValue={""}
                    color="error"
                    variant="outlined"
                    value={getSealedValue()}
                    onChange={(event) =>
                      setPrecintado(event.target.value === "true")
                    }
                    label="Precintado   ."
                    fullWidth
                    required
                  >
                    <MenuItem value="true">Sí</MenuItem>
                    <MenuItem value="false">No</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  <InputLabel color="error">Mensual</InputLabel>
                  <Select
                    defaultValue={""}
                    color="error"
                    variant="outlined"
                    value={getMonthlyValue()}
                    onChange={(event) =>
                      setMensual(event.target.value === "true")
                    }
                    label="Mensual"
                    required
                    fullWidth
                  >
                    <MenuItem value="true">Sí</MenuItem>
                    <MenuItem value="false">No</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  <InputLabel color="error">Nocturno *</InputLabel>
                  <Select
                    defaultValue={""}
                    color="error"
                    variant="outlined"
                    value={nocturno ?? " "}
                    onChange={(event) =>
                      setNocturno(ContadorNocturnoEnum[event.target.value])
                    }
                    label="Nocturno"
                    required
                    fullWidth
                  >
                    {Object.keys(ContadorNocturnoEnum).map(
                      (value: string, index: number) => {
                        return (
                          <MenuItem key={index} value={value}>
                            {value}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  <InputLabel color="error">Tipo contador *</InputLabel>
                  <Select
                    defaultValue={""}
                    color="error"
                    variant="outlined"
                    value={tipoContador ?? " "}
                    onChange={(event) => setTipoContador(event.target.value)}
                    label="Tipo contador   ."
                    fullWidth
                    required
                  >
                    <MenuItem value="MANUAL">Manual</MenuItem>
                    <MenuItem value="AUTO">Auto</MenuItem>
                  </Select>
                </FormControl>
              </CardContent>
            </StyledCard>
          </Grid>
          <Grid
            item
            container
            direction="row"
            xs={10}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              container
              xs={6}
              sx={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={() => params.onClose()}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid
              item
              container
              xs={6}
              sx={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => {
                  if (params.editCounter) submitEdit();
                  else submitCreate();
                }}
                variant="contained"
                color="primary"
                type="submit"
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={errorSnackbar}
        autoHideDuration={6000}
        onClose={() => setErrorSnackbar(false)}
      >
        <div>
          <CustomAlert onClose={() => setErrorSnackbar(false)} severity="error">
            Algo ha ido mal. El contador no ha sido creado
          </CustomAlert>
        </div>
      </Snackbar>
      <Snackbar
        open={errorSnackbarEdit}
        autoHideDuration={6000}
        onClose={() => setErrorSnackbar(false)}
      >
        <div>
          <CustomAlert
            onClose={() => setErrorSnackbarEdit(false)}
            severity="error"
          >
            Algo ha ido mal. El contador no se ha actualizado
          </CustomAlert>
        </div>
      </Snackbar>
    </Grid>
  );
};

export default CounterForm;
