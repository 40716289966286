import { Box, Breadcrumbs, styled, Typography } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import ListAltIcon from "@mui/icons-material/ListAlt";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import PeopleIcon from "@mui/icons-material/People";
import SpeedIcon from "@mui/icons-material/Speed";
import TimelineIcon from "@mui/icons-material/Timeline";
import HistoryIcon from "@mui/icons-material/History";
import LocationOnIcon from '@mui/icons-material/LocationOn';

export const iconStyle = ({ theme }) => ({
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
});

export const StyledBox = styled(Box)(() => ({
  display: "block",
  textAlign: "center",
  margin: "16px 0 16px 16px",
}));

export const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  display: "block",
  textAlign: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

export const StyledTypography = styled(Typography)(() => ({
  display: "flex",
  textDecoration: "none",
}));

//----------------------ICONS----------------------//

export const StyledSearchIcon = styled(SearchIcon)(iconStyle);

export const StyledListAltIcon = styled(ListAltIcon)(iconStyle);

export const StyledAccessAlarmIcon = styled(AccessAlarmIcon)(iconStyle);

export const StyledEqualizerIcon = styled(EqualizerIcon)(iconStyle);

export const StyledAnnouncementIcon = styled(AnnouncementIcon)(iconStyle);

export const StyledPeopleIcon = styled(PeopleIcon)(iconStyle);

export const StyledSpeedIcon = styled(SpeedIcon)(iconStyle);

export const StyledTimelineIcon = styled(TimelineIcon)(iconStyle);

export const StyledHistoryIcon = styled(HistoryIcon)(iconStyle);

export const StyledLocationIcon = styled(LocationOnIcon)(iconStyle);
