import { makeAutoObservable } from "mobx";
import { Contador, ContadorPaged, CounterFilter, FindByFieldRequest } from "../client";
import ContadorRepository from "../data/repositories/ContadorRepository";
import { UpdateRouteMetersReq } from "../client/models/UpdateRouteMetersReq";


export class ContadorViewModel {
    private static instance: ContadorViewModel | null = null;


    static getInstance(): ContadorViewModel {
        if (!ContadorViewModel.instance) {
            ContadorViewModel.instance = new ContadorViewModel();
        }
        return ContadorViewModel.instance;
    }

    currentContador?: Contador = undefined;
    contadores: Contador[] | undefined = undefined;
    countersByField: Contador[] = [];
    latestSearchResults: Contador[] = [];
    latestSearchText: string = "";

    private constructor() {
        makeAutoObservable(this);
        ContadorRepository.getContadores().then((res: ContadorPaged | undefined) => {
            this.setContadores(res?.content ?? []);
        });
        this.currentContador = {
            referencia: undefined,
            numeroCont: undefined,
            idRuta: undefined,
            ordenRuta: undefined,
            titular: undefined,
            direccion: undefined,
            estado: undefined,
            numBasuras: undefined,
            infoContAdicional: undefined
        }
    }

    async createContador(contador: Contador) {
        return ContadorRepository.createContador(contador)
    }

    async getContadores() {
        const pagedCounters = await ContadorRepository.getContadores();
        this.setContadores(pagedCounters.content ?? []);
    }

    async getAllContadoresByFilter(offset: number, limit: number, filter: CounterFilter, sortingField?: string, sortingType?: string) {
        return ContadorRepository.getAllContadoresByFilter(offset, limit, filter, sortingField, sortingType)
    }

    async getContadoresByRoute(route: string) {
        const updatedCounters = await ContadorRepository.getContadoresByRoute(route);
        this.setContadores(updatedCounters);
        return updatedCounters;
    }

    async getContadorByReference(referencia: string) {
        const newContador = await ContadorRepository.getContadorByReference(referencia);
        this.setCurrentContador(newContador);
    }

    async getContadorByNumeroCont(numeroCont: string) {
        const newContador = await ContadorRepository.getContadorByNumerocont(numeroCont);
        this.setCurrentContador(newContador);
    }

    async updateContadorByReference(referencia: string, contador: Contador) {
        return ContadorRepository.updateContadorByReference(referencia, contador);
    }

    async deleteContadorByReference(referencia: string) {
        await ContadorRepository.deleteContadorByReference(referencia);
    }

    async findContadoresByField(findByFieldRequest: FindByFieldRequest) {
        return ContadorRepository.findContadoresByField(findByFieldRequest);
    }

    async updateRouteMeters(updateRouteMetersReq: UpdateRouteMetersReq) {
        return ContadorRepository.updateRouteMeters(updateRouteMetersReq);
    }

    public setCurrentContador(contador?: Contador) {
        this.currentContador = contador;
    }

    public setContadorValue(key: keyof Contador, value: any) {
        this.currentContador && (this.currentContador[key] = value);
    }

    public setLastSearch(text: string, result: Contador[]) {
        this.latestSearchResults = result;
        this.latestSearchText = text;
    }

    public setContadores(contadores?: Contador[]) {
        this.contadores = contadores;
    }
}