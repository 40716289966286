/* tslint:disable */
/* eslint-disable */
/**
 * Aforadores
 * Proxy Service Api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CounterFilter
 */
export interface CounterFilter {
    /**
     * 
     * @type {string}
     * @memberof CounterFilter
     */
    direccion?: string;
    /**
     * 
     * @type {string}
     * @memberof CounterFilter
     */
    numeroCont?: string;
    /**
     * 
     * @type {string}
     * @memberof CounterFilter
     */
    referencia?: string;
}

/**
 * Check if a given object implements the CounterFilter interface.
 */
export function instanceOfCounterFilter(value: object): value is CounterFilter {
    return true;
}

export function CounterFilterFromJSON(json: any): CounterFilter {
    return CounterFilterFromJSONTyped(json, false);
}

export function CounterFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): CounterFilter {
    if (json == null) {
        return json;
    }
    return {
        
        'direccion': json['direccion'] == null ? undefined : json['direccion'],
        'numeroCont': json['numeroCont'] == null ? undefined : json['numeroCont'],
        'referencia': json['referencia'] == null ? undefined : json['referencia'],
    };
}

export function CounterFilterToJSON(value?: CounterFilter | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'direccion': value['direccion'],
        'numeroCont': value['numeroCont'],
        'referencia': value['referencia'],
    };
}

