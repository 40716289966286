import { KeyboardEvent, SyntheticEvent, useEffect, useState } from 'react';
import { StyledButton, StyledDivItem, StyledFormControl, StyledTextFieldInputRoot } from '../styles';
import { Contador } from '../../../../client/models/Contador';
import { ContadorViewModel } from '../../../../viewmodels/ContadorViewModel';
import { InfoContAdicionalViewModel } from '../../../../viewmodels/InfoContAdicionalViewModel';
import { ContadorPaged, FindByFieldRequest, FindByFieldRequestFieldEnum } from '../../../../client';
import { Grid, Snackbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { HistoricoLecturasViewModel } from '../../../../viewmodels/HistoricoLecturasViewModel';
import { AddressSelector } from './address-selector';
import { CustomAlert } from '../../../../components/CustomAlert';

export interface ISelectors {
	contadores: Contador[];
}

const Selectors = ({ contadores }: ISelectors) => {
	const navigate = useNavigate();
	const [openError, setOpenError] = useState(false);
	const [referencia, setReferencia] = useState("")
	const [numeroCont, setNumeroCont] = useState("")
	const [address, setAddress] = useState("");
	const [countersByField, setCountersByField] = useState<Contador[]>([]);
	const [refError, setRefError] = useState(false);
	const [meterNumberError, setMeterNumberError] = useState(false);
	const [resultsErrorMsg, setResultsErrorMsg] = useState("");
	const [query, setQuery] = useState("");

	useEffect(() => {
		let latest = ContadorViewModel.getInstance().latestSearchResults;
		let text = ContadorViewModel.getInstance().latestSearchText;
		if (latest.length > 0) {
			setCountersByField(latest);
			setQuery(text);
		}
	}, []);

	const handleSubmit = async () => {
		if (referencia !== "" || numeroCont !== "" || address !== "") {
			let countersByField: ContadorPaged = {};
			let filter: FindByFieldRequest = { pageNumber: 0, pageSize: 151, field: FindByFieldRequestFieldEnum.Reference, value: "" };

			if (referencia !== "" && numeroCont === "" && address === "") {
				if (referencia.length >= 4) {
					setRefError(false);
					filter.field = FindByFieldRequestFieldEnum.Reference;
					filter.value = referencia;
				} else { setRefError(true); return; }

			} else if (referencia === "" && numeroCont !== "" && address === "") {
				if (numeroCont.length >= 4) {
					setMeterNumberError(false);
					filter.field = FindByFieldRequestFieldEnum.MeterNumber;
					filter.value = numeroCont;
				} else { setMeterNumberError(true); return; }

			} else if (referencia === "" && numeroCont === "" && address !== "") {
				filter.field = FindByFieldRequestFieldEnum.Address;
				filter.value = address;
			}

			setQuery(filter.value ?? "");
			countersByField = await ContadorViewModel.getInstance().findContadoresByField(filter);

			if (countersByField.content && countersByField.content?.length > 1 && countersByField.content?.length <= 150) {
				ContadorViewModel.getInstance().countersByField = countersByField.content;
				setCountersByField(countersByField.content);
				ContadorViewModel.getInstance().setLastSearch(filter.value ?? "", countersByField.content);

			} else if (countersByField.content?.length === 1) {
				handleSearchReference(countersByField.content[0]);

			} else if (countersByField.content && countersByField.content?.length > 150) {
				setCountersByField([]);
				ContadorViewModel.getInstance().setLastSearch("", []);
				setOpenError(true);
				setResultsErrorMsg("Demasiados resultados para el criterio de búsqueda");

			} else {
				setCountersByField([]);
				ContadorViewModel.getInstance().setLastSearch("", []);
				setResultsErrorMsg("Introduce una referencia, número de contador o dirección válidos");
				setOpenError(true);
			}

		} else {
			setCountersByField([]);
			ContadorViewModel.getInstance().setLastSearch("", []);
			setResultsErrorMsg("Introduce una referencia, número de contador o dirección válidos");
			setOpenError(true);
		}
	}

	const getInfoByCounterReference = async () => {
		try {
			await InfoContAdicionalViewModel.getInstance().getInfoContAdicionalByReference(ContadorViewModel.getInstance().currentContador!.referencia!)
			await HistoricoLecturasViewModel.getInstance().getHistoricoLecturasByReference(ContadorViewModel.getInstance().currentContador!.referencia!)
		}
		catch { setOpenError(true); setResultsErrorMsg("Introduce una referencia, número de contador o dirección válidos"); }
	}

	const handleCloseError = (_event?: SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') return;

		setOpenError(false);
		setResultsErrorMsg("");
	};

	const handleCounterSearch = async () => {
		ContadorViewModel.getInstance().currentContador = undefined;
		await handleSubmit();
		if (ContadorViewModel.getInstance().currentContador) {
			ContadorViewModel.getInstance().countersByField = [];
			navigate("/lecturaContador");
		} else if (countersByField.length === 0 && ContadorViewModel.getInstance().countersByField.length === 0) setOpenError(true);
	}

	const handleSearchReference = async (counter: Contador) => {
		ContadorViewModel.getInstance().currentContador = counter;
		await getInfoByCounterReference();
		window.scrollTo(0, 0);
		navigate("/lecturaContador");
	}

	return (
		<StyledDivItem>
			<StyledFormControl>
				<StyledTextFieldInputRoot
					error={refError}
					disabled={numeroCont !== "" || address !== ""}
					label={refError ? "La referencia tiene que tener al menos 4 dígitos" : "Referencia de abonado"}
					variant="outlined"
					margin="normal"
					onChange={(event) => setReferencia(event.target.value)}
					style={{ width: 300 }}
					onKeyDown={(event: KeyboardEvent) => { if (event.key === 'Enter') handleCounterSearch(); }}
				/>
				<StyledTextFieldInputRoot
					error={meterNumberError}
					disabled={referencia !== "" || address !== ""}
					label={meterNumberError ? "El número tiene que tener al menos 4 caracteres" : "Número de contador"}
					variant="outlined"
					margin="normal"
					onChange={(event) => setNumeroCont(event.target.value)}
					style={{ width: 300 }}
					onKeyDown={(event: KeyboardEvent) => { if (event.key === 'Enter') handleCounterSearch(); }}
				/>
				<StyledTextFieldInputRoot
					disabled={referencia !== "" || numeroCont !== ""}
					label="Dirección"
					variant="outlined"
					margin="normal"
					onChange={(evt) => setAddress(evt.target.value)}
					style={{ width: 300 }}
					onKeyDown={(event: KeyboardEvent) => { if (event.key === 'Enter') handleCounterSearch(); }}
				/>
			</StyledFormControl>

			{countersByField.length > 0 &&
				<AddressSelector props={{ countersByField: countersByField, handleSearchReference: handleSearchReference, address: query }} />
			}

			<Grid item xs={12}>
				<StyledButton size="medium" variant="outlined" color="error" onClick={handleCounterSearch}>
					Buscar contador
				</StyledButton>
			</Grid>

			<Snackbar open={openError} autoHideDuration={3000} onClose={handleCloseError}>
				<div>
					<CustomAlert onClose={handleCloseError} severity="error">{resultsErrorMsg}</CustomAlert>
				</div>
			</Snackbar>
		</StyledDivItem>
	);
}

export default Selectors;