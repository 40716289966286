/* tslint:disable */
/* eslint-disable */
/**
 * Proxy Service API
 * Proxy Service API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import * as models from '../models';

export interface CreateContadorRequest {
    contador?: models.Contador;
}

export interface DeleteContadorByReferenceRequest {
    referencia: string;
}

export interface FindAllContadoresByFieldRequest {
    findByFieldRequest?: models.FindByFieldRequest;
}

export interface FindAllContadoresByReferenciaRequest {
    offset: number;
    limit: number;
    referenciaValue: string;
}

export interface GetAllContadoresRequest {
    offset: number;
    limit: number;
    field?: string;
    sort?: GetAllContadoresSortEnum;
}

export interface GetAllContadoresByRouteRequest {
    route: string;
}

export interface GetAllCounterByFilterRequest {
    offset: number;
    limit: number;
    field?: string;
    sort?: GetAllCounterByFilterSortEnum;
    counterFilter?: models.CounterFilter;
}

export interface GetContadorByNumerocontRequest {
    numerocont: string;
}

export interface GetContadorByReferenceRequest {
    referencia: string;
}

export interface UpdateContadorByReferenceRequest {
    referencia: string;
    contador?: models.Contador;
}

export interface UpdateRouteMetersRequestApi {
    updateRouteMetersReq?: models.UpdateRouteMetersRequest;
}

/**
 * no description
 */
export class ContadorApi extends runtime.BaseAPI {

    /**
     * Endpoint for createContador
     * createContador
     */
    async createContadorRaw(requestParameters: CreateContadorRequest): Promise<runtime.ApiResponse<models.Contador>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/contadores`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models.ContadorToJSON(requestParameters.contador),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => models.ContadorFromJSON(jsonValue));
    }

    /**
     * Endpoint for createContador
     * createContador
     */
    async createContador(contador?: models.Contador): Promise<models.Contador> {
        const response = await this.createContadorRaw({ contador: contador });
        return await response.value();
    }

    /**
     * deleteContadorByReference
     * deleteContadorByReference
     */
    async deleteContadorByReferenceRaw(requestParameters: DeleteContadorByReferenceRequest): Promise<runtime.ApiResponse<models.DeleteResponse>> {
        if (requestParameters.referencia === null || requestParameters.referencia === undefined) {
            throw new runtime.RequiredError('referencia','Required parameter requestParameters.referencia was null or undefined when calling deleteContadorByReference.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/contadores/{referencia}`.replace(`{${"referencia"}}`, encodeURIComponent(String(requestParameters.referencia))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => models.DeleteResponseFromJSON(jsonValue));
    }

    /**
     * deleteContadorByReference
     * deleteContadorByReference
     */
    async deleteContadorByReference(referencia: string): Promise<models.DeleteResponse> {
        const response = await this.deleteContadorByReferenceRaw({ referencia: referencia });
        return await response.value();
    }

    /**
     * Endpoint for find all contadores by Field
     * findAllContadoresByField
     */
    async findAllContadoresByFieldRaw(requestParameters: FindAllContadoresByFieldRequest): Promise<runtime.ApiResponse<models.ContadorPaged>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/find/contadores`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models.FindByFieldRequestToJSON(requestParameters.findByFieldRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => models.ContadorPagedFromJSON(jsonValue));
    }

    /**
     * Endpoint for find all contadores by Field
     * findAllContadoresByField
     */
    async findAllContadoresByField(findByFieldRequest?: models.FindByFieldRequest): Promise<models.ContadorPaged> {
        const response = await this.findAllContadoresByFieldRaw({ findByFieldRequest: findByFieldRequest });
        return await response.value();
    }

    /**
     * Endpoint for find all contadores by Referencia
     * findAllContadoresByReferencia
     */
    async findAllContadoresByReferenciaRaw(requestParameters: FindAllContadoresByReferenciaRequest): Promise<runtime.ApiResponse<models.ContadorPaged>> {
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling findAllContadoresByReferencia.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling findAllContadoresByReferencia.');
        }

        if (requestParameters.referenciaValue === null || requestParameters.referenciaValue === undefined) {
            throw new runtime.RequiredError('referenciaValue','Required parameter requestParameters.referenciaValue was null or undefined when calling findAllContadoresByReferencia.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.referenciaValue !== undefined) {
            queryParameters['referenciaValue'] = requestParameters.referenciaValue;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/contadores/referencia`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => models.ContadorPagedFromJSON(jsonValue));
    }

    /**
     * Endpoint for find all contadores by Referencia
     * findAllContadoresByReferencia
     */
    async findAllContadoresByReferencia(offset: number, limit: number, referenciaValue: string): Promise<models.ContadorPaged> {
        const response = await this.findAllContadoresByReferenciaRaw({ offset: offset, limit: limit, referenciaValue: referenciaValue });
        return await response.value();
    }

    /**
     * Endpoint for list all contadores
     * getAllContadores
     */
    async getAllContadoresRaw(requestParameters: GetAllContadoresRequest): Promise<runtime.ApiResponse<models.ContadorPaged>> {
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getAllContadores.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getAllContadores.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.field !== undefined) {
            queryParameters['field'] = requestParameters.field;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/contadores`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => models.ContadorPagedFromJSON(jsonValue));
    }

    /**
     * Endpoint for list all contadores
     * getAllContadores
     */
    async getAllContadores(offset: number, limit: number, field?: string, sort?: GetAllContadoresSortEnum): Promise<models.ContadorPaged> {
        const response = await this.getAllContadoresRaw({ offset: offset, limit: limit, field: field, sort: sort });
        return await response.value();
    }

    /**
     * getAllContadoresByRoute
     * getAllContadoresByRoute
     */
    async getAllContadoresByRouteRaw(requestParameters: GetAllContadoresByRouteRequest): Promise<runtime.ApiResponse<Array<models.Contador>>> {
        if (requestParameters.route === null || requestParameters.route === undefined) {
            throw new runtime.RequiredError('route','Required parameter requestParameters.route was null or undefined when calling getAllContadoresByRoute.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/contadores/route/{route}`.replace(`{${"route"}}`, encodeURIComponent(String(requestParameters.route))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models.ContadorFromJSON));
    }

    /**
     * getAllContadoresByRoute
     * getAllContadoresByRoute
     */
    async getAllContadoresByRoute(route: string): Promise<Array<models.Contador>> {
        const response = await this.getAllContadoresByRouteRaw({ route: route });
        return await response.value();
    }

    /**
     * getAllCounterByFilter
     * getAllCounterByFilter
     */
    async getAllCounterByFilterRaw(requestParameters: GetAllCounterByFilterRequest): Promise<runtime.ApiResponse<models.ContadorPaged>> {
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getAllCounterByFilter.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getAllCounterByFilter.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.field !== undefined) {
            queryParameters['field'] = requestParameters.field;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/contadores-filter`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models.CounterFilterToJSON(requestParameters.counterFilter),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => models.ContadorPagedFromJSON(jsonValue));
    }

    /**
     * getAllCounterByFilter
     * getAllCounterByFilter
     */
    async getAllCounterByFilter(offset: number, limit: number, field?: string, sort?: GetAllCounterByFilterSortEnum, counterFilter?: models.CounterFilter): Promise<models.ContadorPaged> {
        const response = await this.getAllCounterByFilterRaw({ offset: offset, limit: limit, field: field, sort: sort, counterFilter: counterFilter });
        return await response.value();
    }

    /**
     * getContadorByNumerocont
     * getContadorByNumerocont
     */
    async getContadorByNumerocontRaw(requestParameters: GetContadorByNumerocontRequest): Promise<runtime.ApiResponse<models.Contador>> {
        if (requestParameters.numerocont === null || requestParameters.numerocont === undefined) {
            throw new runtime.RequiredError('numerocont','Required parameter requestParameters.numerocont was null or undefined when calling getContadorByNumerocont.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/contadores/numerocont/{numerocont}`.replace(`{${"numerocont"}}`, encodeURIComponent(String(requestParameters.numerocont))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => models.ContadorFromJSON(jsonValue));
    }

    /**
     * getContadorByNumerocont
     * getContadorByNumerocont
     */
    async getContadorByNumerocont(numerocont: string): Promise<models.Contador> {
        const response = await this.getContadorByNumerocontRaw({ numerocont: numerocont });
        return await response.value();
    }

    /**
     * getContadorByReference
     * getContadorByReference
     */
    async getContadorByReferenceRaw(requestParameters: GetContadorByReferenceRequest): Promise<runtime.ApiResponse<models.Contador>> {
        if (requestParameters.referencia === null || requestParameters.referencia === undefined) {
            throw new runtime.RequiredError('referencia','Required parameter requestParameters.referencia was null or undefined when calling getContadorByReference.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/contadores/{referencia}`.replace(`{${"referencia"}}`, encodeURIComponent(String(requestParameters.referencia))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => models.ContadorFromJSON(jsonValue));
    }

    /**
     * getContadorByReference
     * getContadorByReference
     */
    async getContadorByReference(referencia: string): Promise<models.Contador> {
        const response = await this.getContadorByReferenceRaw({ referencia: referencia });
        return await response.value();
    }

    /**
     * updateContadorByReference
     * updateContadorByReference
     */
    async updateContadorByReferenceRaw(requestParameters: UpdateContadorByReferenceRequest): Promise<runtime.ApiResponse<models.Contador>> {
        if (requestParameters.referencia === null || requestParameters.referencia === undefined) {
            throw new runtime.RequiredError('referencia','Required parameter requestParameters.referencia was null or undefined when calling updateContadorByReference.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/contadores/{referencia}`.replace(`{${"referencia"}}`, encodeURIComponent(String(requestParameters.referencia))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models.ContadorToJSON(requestParameters.contador),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => models.ContadorFromJSON(jsonValue));
    }

    /**
     * updateContadorByReference
     * updateContadorByReference
     */
    async updateContadorByReference(referencia: string, contador?: models.Contador): Promise<models.Contador> {
        const response = await this.updateContadorByReferenceRaw({ referencia: referencia, contador: contador });
        return await response.value();
    }

    /**
     * updateRouteMeters
     * updateRouteMeters
     */
    async updateRouteMetersRaw(requestParameters: models.UpdateRouteMetersRequest): Promise<runtime.ApiResponse<models.ContadorPaged>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/route-meters`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models.UpdateRouteMetersRequestToJSON(requestParameters),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => models.ContadorPagedFromJSON(jsonValue));
    }

    /**
     * updateRouteMeters
     * updateRouteMeters
     */
    async updateRouteMeters(updateRouteMetersReq?: models.UpdateRouteMetersRequest): Promise<models.ContadorPaged> {
        const response = await this.updateRouteMetersRaw(updateRouteMetersReq ?? {});
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetAllContadoresSortEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCounterByFilterSortEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}
