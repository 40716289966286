import { IconButton } from "@mui/material";
import { DatePickerSlotsComponentsProps } from "@mui/x-date-pickers";
import { MutableRefObject } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import EventIcon from "@mui/icons-material/Event";
import OwnColors from "../../constants/OwnColors";

interface IGetDefaultSlotProps {
  pickerRef: MutableRefObject<HTMLInputElement>;
  onClickCalendar: () => void;
  onClickClear: () => void;
  value: Date | null
}

export const getDefaultSlotProps = ({
  onClickCalendar,
  onClickClear,
  pickerRef,
  value
}: IGetDefaultSlotProps): DatePickerSlotsComponentsProps<Date> => {
  return {
    day: {sx: {"&.Mui-selected": {
                    backgroundColor: OwnColors.LECOCorporate + " !important",
                  }}},
    popper: { anchorEl: pickerRef.current },
    textField: {
      variant: "standard",
      InputProps: {
        color: "error",
        startAdornment: (
          <IconButton onClick={onClickCalendar}>
            <EventIcon />
          </IconButton>
        ),
        endAdornment:
        value != null ? (
            <IconButton onClick={onClickClear}>
              <ClearIcon />
            </IconButton>
          ) : null,
      },
    },
  };
};
