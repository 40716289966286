import { Navigate } from "react-router-dom";
import SessionStorage from "../../data/sessionStorage/SessionStorage";
import { checkRoles } from "../../infrastructure/utils/CheckRoles";
import { FC, ReactNode } from "react";

export interface ProtectedRouteProps {
  allowedRoles: string[] | undefined;
  children: ReactNode;
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({
  allowedRoles = [],
  children,
}) => {
  if (SessionStorage.isLoggedIn() && checkRoles(allowedRoles) && SessionStorage.isTokenValid())
    return <>{children}</>;
  else return <Navigate to="/login" />;
};

export default ProtectedRoute;
