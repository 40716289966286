export const incidentCodes = [
    "Alto consumo",
    "Aviso lectura",
    "Cerrado",
    "Condicionada",
    "Contador averiado",
    "Estimada",
    "Inicio",
    "Lectura normal",
    "Lectura ultima mal",
    "Mensual",
    "Recuperando cifra",
    "Sin consumo",
    "Solo basura",
    "Vuelta de numeros"
];
