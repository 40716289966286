import { SyntheticEvent, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { StyledButtonCreate, StyledButtonDelete, StyledButtonEdit, StyledDiv } from "../styles";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { RutaViewModel } from "../../../viewmodels/RutaViewModel";
import Snackbar from "@mui/material/Snackbar";
import RouteForm from "./RouteForm";
import { checkRoles } from "../../../infrastructure/utils/CheckRoles";
import { Contador, Ruta } from "../../../client";
import { CustomAlert } from "../../../components/CustomAlert";
import { DEFAULT_GRID_INITIAL_STATE, DEFAULT_ROWS_PER_PAGE_OPTIONS } from "../../../constants/TableConstants";

export interface IRouteTable {
  rutas: Ruta[];
  contadores: Contador[];
}

const RouteTable = ({ rutas, contadores }: IRouteTable) => {
  const renderEditButton = (params) => {
    return (
      <strong>
        <StyledButtonEdit
          variant="contained"
          onClick={() => handleClickOpenEdit(params.row.idRuta)}
        >
          <EditIcon />
        </StyledButtonEdit>
      </strong>
    );
  };

  const renderDeleteButton = (params) => {
    return (
      <strong>
        <StyledButtonDelete
          variant="contained"
          onClick={() => handleClickOpenDelete(params.row.idRuta)}
        >
          <DeleteIcon />
        </StyledButtonDelete>
      </strong>
    );
  };

  const CustomToolbar = (row) => (
    <GridToolbarContainer sx={{justifyContent: "flex-end"}} >
      <Grid>
        <GridToolbarFilterButton />
      </Grid>
      <Grid>
        {checkRoles(["ADMIN", "OFICINA"]) && (
          <StyledButtonCreate
            variant="contained"
            onClick={handleClickOpenCreate}
          >
            Nueva Ruta
          </StyledButtonCreate>
        )}
      </Grid>
    </GridToolbarContainer>
  );

  const hideColumn = () => !checkRoles(["ADMIN", "OFICINA"]);

  const columns: GridColDef[] = [
    {
      field: "idRuta",
      headerClassName: "theme--header",
      headerName: "Id",
      type: "number",
      flex: 0.3,
    },
    {
      field: "descripcion",
      headerClassName: "theme--header",
      headerName: "Descripción",
      type: "string",
      flex: 1,
    },
    {
      field: "estado",
      headerClassName: "theme--header",
      headerName: "Estado",
      type: "string",
      flex: 0.7,
      valueGetter: (params) => params.row.estado ? "ALTA" : "BAJA",
    },
    {
      field: "editar",
      headerClassName: "theme--header",
      headerName: "Editar",
      type: "string",
      flex: 0.15,
      renderCell: renderEditButton,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "eliminar",
      headerClassName: "theme--header",
      headerName: "Eliminar",
      type: "string",
      flex: 0.15,
      renderCell: renderDeleteButton,
      headerAlign: "center",
      align: "center",
      hideable: hideColumn(),
    },
  ];

  const [editRuta, setEditRuta] = useState<Ruta>();
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openContadorExist, setOpenContadorExist] = useState(false);
  const [openDeleteConfirmSuccess, setOpenDeleteConfirmSuccess] = useState(false);
  const [successSnackbarCreate, setSuccessSnackbarCreate] = useState(false);
  const [successSnackbarEdit, setSuccessSnackbarEdit] = useState(false);

  let currentRuta = RutaViewModel.getInstance().currentRuta;

  let items = rutas.slice();
  const cont = contadores.slice();

  const handleClickOpenCreate = () => setOpenCreate(true);

  const handleClickCloseCreate = (rutaCreada?: Ruta) => {
    if (rutaCreada !== undefined && rutas.indexOf(rutaCreada) === -1) {
      const rutaToPush: Ruta = {
        idRuta: rutaCreada.idRuta,
        descripcion: rutaCreada.descripcion,
        estado: rutaCreada.estado,
      };
      setSuccessSnackbarCreate(true);
      rutas.push(rutaToPush);
    }
    setOpenCreate(false);
  };

  const handleClickOpenEdit = async (id: string) => {
    setEditRuta(await RutaViewModel.getInstance().getRutaById(id));
    setOpenEdit(true);
  };

  const handleClickCloseEdit = (rutaEditada?: Ruta) => {
    if (rutaEditada !== undefined) {
      const rutaToPush: Ruta = {
        idRuta: rutaEditada.idRuta,
        descripcion: rutaEditada.descripcion,
        estado: rutaEditada.estado,
      };
      rutas.forEach((i, index) => {
        if (i.idRuta === rutaToPush.idRuta) {
          rutas.splice(index, 1);
          rutas.push(rutaToPush);
        }
      });
      setSuccessSnackbarEdit(true);
      setOpenEdit(false);
    }
    setOpenEdit(false);
  };

  const handleClickOpenDelete = (id: string) => {
    currentRuta = items.find((item) => item.idRuta === id);
    if (
      cont.find(
        (item) =>
          item.idRuta?.idRuta === currentRuta?.idRuta &&
          item.estado !== "ELIMINADO"
      )
    ) {
      setOpenContadorExist(true);
    } else {
      RutaViewModel.getInstance().setCurrentRuta(currentRuta!);
      setOpenConfirmDelete(true);
    }
  };

  const handleCloseConfirmDelete = () => setOpenConfirmDelete(false);

  const handleCloseContadorExist = (
    _event?: SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") return;

    setOpenContadorExist(false);
  };
  const handleCloseDeleteConfirmSuccess = (
    _event?: SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") return;

    setOpenDeleteConfirmSuccess(false);
  };

  const deleteRuta = (ruta: Ruta) => {
    RutaViewModel.getInstance().deleteRutaById(ruta.idRuta!);
    const index = items.indexOf(ruta);
    if (index > -1) {
      rutas.splice(index, 1);
    }
    setOpenConfirmDelete(false);
    setOpenDeleteConfirmSuccess(true);
  };

  return (
    <>
      <Dialog open={openCreate} onClose={() => setOpenCreate(false)}>
        <DialogContent>
          <RouteForm onClose={handleClickCloseCreate} />
        </DialogContent>
      </Dialog>
      <Dialog open={openEdit} onClose={() => setOpenEdit(false)}>
        <DialogContent>
          <RouteForm onClose={handleClickCloseEdit} editRuta={editRuta} />
        </DialogContent>
      </Dialog>
      <StyledDiv>
        <DataGrid
          getRowId={(row) => row.idRuta}
          rows={items}
          columns={columns}
          rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
          initialState={DEFAULT_GRID_INITIAL_STATE}
          autoHeight
          components={{
            Toolbar: CustomToolbar
          }}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
        />
      </StyledDiv>
      <Dialog
        open={openConfirmDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          ¿Desea eliminar la siguiente ruta?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            align="center"
            color="inherit"
          >
            Ruta: <b>{currentRuta?.idRuta}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDelete} color="error">
            Cancelar
          </Button>
          <Button
            onClick={() => deleteRuta(currentRuta!)}
            color="primary"
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openContadorExist}
        autoHideDuration={3000}
        onClose={handleCloseContadorExist}
      >
        <div>
          <CustomAlert onClose={handleCloseContadorExist} severity="warning">
            La ruta tiene contadores, no se puede eliminar.
          </CustomAlert>
        </div>
      </Snackbar>
      <Snackbar
        open={openDeleteConfirmSuccess}
        autoHideDuration={3000}
        onClose={handleCloseDeleteConfirmSuccess}
      >
        <div>
          <CustomAlert onClose={handleCloseDeleteConfirmSuccess} severity="success">
            Ruta eliminada con éxito
          </CustomAlert>
        </div>
      </Snackbar >
      <Snackbar
        open={successSnackbarCreate}
        autoHideDuration={6000}
        onClose={() => setSuccessSnackbarCreate(false)}
      >
        <div>
          <CustomAlert
            onClose={() => setSuccessSnackbarCreate(false)}
            severity="success"
          >
            Ruta creada con éxito.
          </CustomAlert>
        </div>
      </Snackbar >
      <Snackbar
        open={successSnackbarEdit}
        autoHideDuration={6000}
        onClose={() => setSuccessSnackbarEdit(false)}
      >
        <div>
          <CustomAlert
            onClose={() => setSuccessSnackbarEdit(false)}
            severity="success"
          >
            Ruta editada con éxito.
          </CustomAlert>
        </div>
      </Snackbar >
    </>
  );
};

export default RouteTable;
