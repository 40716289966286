import { FC, Fragment } from "react";
import { StyledButton, StyledCardFixHeightRow2, StyledGridCard3, StyledGridCard4, StyledGridMiniCard, StyledTextFieldComentario, StyledTextFieldReadOnlyCard3 } from '../styles';
import { CardHeader, Typography, CardContent, Grid } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { Contador, InfoContAdicional } from "../../../../client";
import ChatIcon from '@mui/icons-material/Chat';
import { MeterLocationEnum } from "../../../../constants/meterLocationEnum";


type TAdditionalInfoSection = {
  additionalInfo?: InfoContAdicional;
  currentMeter?: Contador;
  setAdditionalInfo: (info: InfoContAdicional) => void;
  updateAdditionalInfo: VoidFunction
}

export const AdditionalInfoSection: FC<{ props: TAdditionalInfoSection }> = (({ props }) => {
  const writeCommentary = (comment: string) => {
    props.setAdditionalInfo({ ...props.additionalInfo, comentario: comment });
  }

  return (
    <Fragment>
      <StyledGridCard3 item xs={7}>
        <StyledCardFixHeightRow2>
          <CardHeader
            title={
              <Grid
                item
                container
                direction="row"
                style={{ "marginBottom": "-2.5vw", "marginTop": "-0.5vw" }}
                sx={{
                  alignItems: "center",
                  justifyContent: "flex-start"
                }}>
                <InfoIcon />&nbsp;
                <Typography>INFORMACIÓN ADICIONAL</Typography>
              </Grid>
            } />

          <CardContent>
            <StyledGridCard3 item xs={12} container direction="row">
              <StyledGridMiniCard item xs={6}>
                <StyledTextFieldReadOnlyCard3 color="error" id="filled-read-only-input" label="Ubicación"
                  value={MeterLocationEnum[props.additionalInfo?.ubicacion?.toString() ?? ""] ?? ""}
                  InputProps={{ readOnly: true }} variant="filled" />

                <StyledTextFieldReadOnlyCard3 color="error" id="filled-read-only-input2" label="Precintado"
                  value={props.additionalInfo?.precintado ? "Sí" : "No"} InputProps={{ readOnly: true }} variant="filled" />

                <StyledTextFieldReadOnlyCard3 color="error" id="filled-read-only-input3" label="Calibre"
                  value={props.additionalInfo?.calibre ?? ""} InputProps={{ readOnly: true }} variant="filled" />
              </StyledGridMiniCard>

              <Grid item xs={6}>
                <StyledTextFieldReadOnlyCard3 color="error" id="filled-read-only-input" label="Fecha alta"
                  value={new Date(props.currentMeter?.fechaAlta ?? '')} InputProps={{ readOnly: true }}
                  variant="filled" />

                <StyledTextFieldReadOnlyCard3 color="error" id="filled-read-only-input2" label="Fecha baja"
                  value={new Date(props.currentMeter?.fechaBaja ?? '')} InputProps={{ readOnly: true }}
                  variant="filled" />

                <StyledTextFieldReadOnlyCard3 color="error" id="filled-read-only-input" label="Número basuras"
                  value={props.currentMeter?.numBasuras ?? ""} InputProps={{ readOnly: true }} variant="filled" />
              </Grid>
            </StyledGridCard3>
          </CardContent>
        </StyledCardFixHeightRow2>
      </StyledGridCard3>
      <StyledGridCard4 item xs={3}>
        <StyledCardFixHeightRow2>
          <CardContent>
            <Grid container direction="row">
              <Grid item xs={4}>
                <Typography>Comentario</Typography>
              </Grid>
              <Grid item xs={8} style={{ textAlign: "end" }}>
                <StyledButton variant="outlined" size="large" color="error" onClick={props.updateAdditionalInfo}>
                  <ChatIcon />
                </StyledButton>
              </Grid>

              <StyledTextFieldComentario color="error" id="standard-multiline-flexible" multiline rows={8} variant="standard"
                value={props.additionalInfo?.comentario} onChange={(evt) => writeCommentary(evt.target.value)} fullWidth />
            </Grid>
          </CardContent>
        </StyledCardFixHeightRow2>
      </StyledGridCard4>
    </Fragment>
  );
})