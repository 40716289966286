import { DeleteResponse, InfoContAdicional } from "../../client";
import { InfoContAdicionalApi } from "../../client/apis/InfoContAdicionalApi";
import { Api, ApiClient } from "./ApiClient";



export default class InfoContAdicionalRepository{


    private static getInfoContAdicionalApiClient() {
        return ApiClient.getClient(Api.InfoContAdicionalApi) as InfoContAdicionalApi;
    }

    static async getAllInfoContAdicional(): Promise<InfoContAdicional[]>{
        const infoContAdicionalApi=this.getInfoContAdicionalApiClient();
        const allInfoContAdicionales: InfoContAdicional[]=await infoContAdicionalApi.getAllInfoContAdicional();

        return allInfoContAdicionales;
    }

    static async getInfoContAdicionalByReference(referencia: string): Promise<InfoContAdicional>{
        const infoContAdicionalApi=this.getInfoContAdicionalApiClient();
        const infoContAdicional: InfoContAdicional = await infoContAdicionalApi.getInfoContAdicionalByReference(referencia);

        return infoContAdicional;
    }

    static async createInfoContAdicional(newInfoContAdicional: InfoContAdicional): Promise<InfoContAdicional>{
        const infoContAdicionalApi=this.getInfoContAdicionalApiClient();
        const infoContAdicional: InfoContAdicional = await infoContAdicionalApi.createInfoContAdicional(newInfoContAdicional);

        return infoContAdicional;
    }

    static async updateInfoContAdicionalByReference(referencia:string,updateInfoContAdicional: InfoContAdicional): Promise<InfoContAdicional>{
        const infoContAdicionalApi=this.getInfoContAdicionalApiClient();
        const infoContAdicional: InfoContAdicional= await infoContAdicionalApi.updateInfoContAdicionalByReference(referencia,updateInfoContAdicional);

        return infoContAdicional;
    }

    static async deleteInfoContAdicionalByReference(referencia: string): Promise<DeleteResponse>{
        const infoContAdicionalApi=this.getInfoContAdicionalApiClient();
        let response: DeleteResponse = await infoContAdicionalApi.deleteInfoContAdicionalByReference(referencia);
        return response;
    }


}