import { Button, Divider, styled, TextField, DialogTitle, Grid } from "@mui/material";
import OwnColors from "../../constants/OwnColors";

const StyledDiv = styled('div')(({ theme }) => ({
    width: "auto",
    margin: theme.spacing(0, 10, 0, 10),
    '& .theme--header': {
        backgroundColor: OwnColors.LECOlightRed,
        color: "black"
    },
}));

const StyledGridRowItem = styled(Grid)(() => ({
    "&:hover .MuiInputLabel-root": {
        color: OwnColors.LECOCorporate,
    },
    "& .MuiInputLabel-root.Mui-focused": {
        color: OwnColors.LECOCorporate,
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: OwnColors.LECOCorporate,
    },
    "& .MuiInputLabel-root": {
        color: OwnColors.LECOMediumGrey,
    },
}));

const StyledDialogTitleReadingDialogTypography = styled(DialogTitle)(() => ({
    background: OwnColors.LECOlightRed,
    borderRadius: 5,
}));

const StyledGridReadingDialogContainer = styled(Grid)(() => ({
    width: 500,
    height: 500,
    paddingTop: 10
}));

const StyledGridReadingDialogTextfields = styled(TextField)(() => ({
    marginBottom: 5
}));

const StyledGridReadingDialogDivider = styled(Divider)(() => ({
    background: OwnColors.LECODarkGrey,
    height: 1,
    width: '100%'
}));

const StyledGridReadingDialogSaveButton = styled(Button)(() => ({
    background: OwnColors.LECODarkDodgeBlue,
    color: OwnColors.white
}));

const StyledGridReadingDialogCancelButton = styled(Button)(() => ({
    background: OwnColors.LECOCorporate,
    color: OwnColors.white
}));

export { StyledDiv, StyledGridRowItem, StyledDialogTitleReadingDialogTypography, StyledGridReadingDialogContainer, StyledGridReadingDialogTextfields, StyledGridReadingDialogCancelButton, StyledGridReadingDialogDivider, StyledGridReadingDialogSaveButton };