import { FC, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { HistoricoLecturasViewModel } from "../../viewmodels/HistoricoLecturasViewModel";
import HistoricTable from "./components/HistoricTable";
import {
  CounterHistoryFilter,
  HistoricoLecturas,
  HistoricoLecturasPaged,
} from "../../client";
import {
  StyledAnnouncementIcon,
  StyledBreadcrumbs,
  StyledTypography,
} from "../../components/CustomBreadCrumbs";

const PendingCountersView: FC = () => {
  const currentDate = new Date();
  const initDate = new Date();
  initDate.setUTCMonth(currentDate.getMonth() - 6);

  const [activePage, setActivePage] = useState(0);
  const [sortingField, setSortingField] = useState("");
  const [sortingType, setSortingType] = useState("");
  const [tableContent, setTableContent] = useState<HistoricoLecturas[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [filter, setFilter] = useState<CounterHistoryFilter>({
    firstReadingDate: initDate,
    lastReadingDate: currentDate,
  });

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      setTableContent([]);
      HistoricoLecturasViewModel.getInstance()
        .getAllPendingCounters(0, pageSize, filter, sortingField, sortingType)
        .then((response: HistoricoLecturasPaged) => {
          setActivePage(response.number ?? 0);
          setTableContent([...(response.content ?? [])]);
          setTotalItems(response.totalElements ?? 0);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    })();
  }, [filter, sortingField, sortingType, pageSize]);

  const handlePageChange = (value: number) => {
    setIsLoading(true);
    setTableContent([]);
    HistoricoLecturasViewModel.getInstance()
      .getAllPendingCounters(value, pageSize, filter, sortingField, sortingType)
      .then((response: HistoricoLecturasPaged) => {
        setActivePage(response.number ?? 0);
        setTableContent(response.content ?? []);
        setTotalItems(response.totalElements ?? 0);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <div>
      <StyledBreadcrumbs aria-label="breadcrumb">
        <StyledTypography color="textPrimary">
          <StyledAnnouncementIcon />
          Contadores pendientes
        </StyledTypography>
      </StyledBreadcrumbs>
      <HistoricTable
        historico={tableContent}
        activePage={activePage}
        totalItems={totalItems}
        filter={filter}
        isLoading={isLoading}
        sortingField={sortingField}
        sort={sortingType}
        handlePageChange={handlePageChange}
        setFilter={setFilter}
        setSortingField={setSortingField}
        setSort={setSortingType}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </div>
  );
};

export default observer(PendingCountersView);
