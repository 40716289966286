import { styled } from "@mui/material/styles";
import OwnColors from "../../../../constants/OwnColors";
import { ListItem, Typography } from "@mui/material";

const StyledListItem = styled(ListItem)(({ theme }) => ({
    backgroundColor: OwnColors.LECOCorporate,
    color: "white",
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2.2), 
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    backgroundColor: OwnColors.LECOCorporate,
    color: "white",
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(1), 
    fontWeight: "bold"
}));

export { StyledListItem, StyledTypography };
