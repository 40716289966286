/* tslint:disable */
/* eslint-disable */
/**
 * Aforadores
 * Proxy Service Api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { InfoContAdicional } from './InfoContAdicional';
import {
    InfoContAdicionalFromJSON,
    InfoContAdicionalFromJSONTyped,
    InfoContAdicionalToJSON,
} from './InfoContAdicional';
import type { Ruta } from './Ruta';
import {
    RutaFromJSON,
    RutaFromJSONTyped,
    RutaToJSON,
} from './Ruta';

/**
 * 
 * @export
 * @interface Contador
 */
export interface Contador {
    /**
     * 
     * @type {string}
     * @memberof Contador
     */
    cadastralReference?: string;
    /**
     * 
     * @type {string}
     * @memberof Contador
     */
    direccion?: string;
    /**
     * 
     * @type {string}
     * @memberof Contador
     */
    estado?: ContadorEstadoEnum;
    /**
     * 
     * @type {string}
     * @memberof Contador
     */
    fechaAlta?: string;
    /**
     * 
     * @type {string}
     * @memberof Contador
     */
    fechaBaja?: string;
    /**
     * 
     * @type {Ruta}
     * @memberof Contador
     */
    idRuta?: Ruta;
    /**
     * 
     * @type {number}
     * @memberof Contador
     */
    lastModified?: number;
    /**
     * 
     * @type {number}
     * @memberof Contador
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof Contador
     */
    longitude?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Contador
     */
    mensual?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Contador
     */
    needsSorting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Contador
     */
    nocturno?: ContadorNocturnoEnum;
    /**
     * 
     * @type {number}
     * @memberof Contador
     */
    numBasuras?: number;
    /**
     * 
     * @type {string}
     * @memberof Contador
     */
    numeroCont?: string;
    /**
     * 
     * @type {number}
     * @memberof Contador
     */
    ordenRuta?: number;
    /**
     * 
     * @type {string}
     * @memberof Contador
     */
    referencia?: string;
    /**
     * 
     * @type {string}
     * @memberof Contador
     */
    titular?: string;
    /**
     * 
     * @type {string}
     * @memberof Contador
     */
    user?: string;
    /**
     * 
     * @type {InfoContAdicional}
     * @memberof Contador
     */
    infoContAdicional?: InfoContAdicional;
}


/**
 * @export
 */
export const ContadorEstadoEnum = {
    Creado: 'CREADO',
    Alta: 'ALTA',
    Baja: 'BAJA',
    Bajaconfirmada: 'BAJACONFIRMADA',
    Eliminado: 'ELIMINADO'
} as const;
export type ContadorEstadoEnum = typeof ContadorEstadoEnum[keyof typeof ContadorEstadoEnum];

/**
 * @export
 */
export const ContadorNocturnoEnum = {
    Normal: 'Normal',
    General: 'General',
    Diurno: 'Diurno',
    Nocturno: 'Nocturno'
} as const;
export type ContadorNocturnoEnum = typeof ContadorNocturnoEnum[keyof typeof ContadorNocturnoEnum];


/**
 * Check if a given object implements the Contador interface.
 */
export function instanceOfContador(value: object): value is Contador {
    return true;
}

export function ContadorFromJSON(json: any): Contador {
    return ContadorFromJSONTyped(json, false);
}

export function ContadorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Contador {
    if (json == null) {
        return json;
    }
    return {
        
        'cadastralReference': json['cadastralReference'] == null ? undefined : json['cadastralReference'],
        'direccion': json['direccion'] == null ? undefined : json['direccion'],
        'estado': json['estado'] == null ? undefined : json['estado'],
        'fechaAlta': json['fechaAlta'] == null ? undefined : json['fechaAlta'],
        'fechaBaja': json['fechaBaja'] == null ? undefined : json['fechaBaja'],
        'idRuta': json['idRuta'] == null ? undefined : RutaFromJSON(json['idRuta']),
        'lastModified': json['lastModified'] == null ? undefined : json['lastModified'],
        'latitude': json['latitude'] == null ? undefined : json['latitude'],
        'longitude': json['longitude'] == null ? undefined : json['longitude'],
        'mensual': json['mensual'] == null ? undefined : json['mensual'],
        'needsSorting': json['needsSorting'] == null ? undefined : json['needsSorting'],
        'nocturno': json['nocturno'] == null ? undefined : json['nocturno'],
        'numBasuras': json['numBasuras'] == null ? undefined : json['numBasuras'],
        'numeroCont': json['numeroCont'] == null ? undefined : json['numeroCont'],
        'ordenRuta': json['ordenRuta'] == null ? undefined : json['ordenRuta'],
        'referencia': json['referencia'] == null ? undefined : json['referencia'],
        'titular': json['titular'] == null ? undefined : json['titular'],
        'user': json['user'] == null ? undefined : json['user'],
        'infoContAdicional': json['infoContAdicional'] == null ? undefined : InfoContAdicionalFromJSON(json['infoContAdicional']),
    };
}

export function ContadorToJSON(value?: Contador | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cadastralReference': value['cadastralReference'],
        'direccion': value['direccion'],
        'estado': value['estado'],
        'fechaAlta': value['fechaAlta'],
        'fechaBaja': value['fechaBaja'],
        'idRuta': RutaToJSON(value['idRuta']),
        'lastModified': value['lastModified'],
        'latitude': value['latitude'],
        'longitude': value['longitude'],
        'mensual': value['mensual'],
        'needsSorting': value['needsSorting'],
        'nocturno': value['nocturno'],
        'numBasuras': value['numBasuras'],
        'numeroCont': value['numeroCont'],
        'ordenRuta': value['ordenRuta'],
        'referencia': value['referencia'],
        'titular': value['titular'],
        'user': value['user'],
        'infoContAdicional': InfoContAdicionalToJSON(value['infoContAdicional']),
    };
}

