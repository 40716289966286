import { SyntheticEvent, useState } from "react";
import {
  GridCallbackDetails,
  GridColDef,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
  Contador,
  ContadorEstadoEnum,
  ContadorPaged,
  InfoContAdicional,
  Ruta,
  CounterFilter,
} from "../../../client";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Divider, Grid, IconButton, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ContadorViewModel } from "../../../viewmodels/ContadorViewModel";
import CounterForm from "./CounterForm";
import { InfoContAdicionalViewModel } from "../../../viewmodels/InfoContAdicionalViewModel";
import { RutaViewModel } from "../../../viewmodels/RutaViewModel";
import Snackbar from "@mui/material/Snackbar";
import { checkRoles } from "../../../infrastructure/utils/CheckRoles";
import _ from "lodash";
import { UserViewModel } from "../../../viewmodels/UserViewModel";
import ClearIcon from "@mui/icons-material/Clear";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import {
  StyledCreateButton,
  StyledDataGrid,
  StyledDeleteButton,
  StyledDiv,
  StyledEditButton,
  StyledGeolocationButton,
} from "../styles";
import { CustomAlert } from "../../../components/CustomAlert";
import { DEFAULT_GRID_INITIAL_STATE } from "../../../constants/TableConstants";
import { useNavigate } from "react-router-dom";

export interface IMeterTable {
  contadores: Contador[];
  setContadores: (val: Contador[]) => void;
  activePage: number;
  totalItems: number;
  filter: CounterFilter;
  isLoading: boolean;
  handlePageChange: (value: number, details?: GridCallbackDetails) => void;
  setFilter: (value: CounterFilter) => void;
  setSortingField: (value: string) => void;
  setSort: (value: string) => void;
  sortingField: string;
  sort: string;
}

const MeterTable = ({
  contadores,
  setContadores,
  activePage,
  totalItems,
  filter,
  isLoading,
  setFilter = (_value: CounterFilter) => {},
  setSortingField = (_value: string) => {},
  setSort = (_value: string) => {},
  sortingField = "",
  sort = "",
  handlePageChange,
}: IMeterTable) => {
  const handleSortChange = (
    params: GridSortModel,
    _details?: GridCallbackDetails
  ) => {
    if (params.length !== 0) {
      setSortingField(params[0].field.toLowerCase() ?? "");
      setSort(params[0].sort?.toUpperCase() ?? "");
    } else {
      setSortingField("");
      setSort("");
    }
  };

  const renderEditButton = (params) => {
    return (
      <strong>
        <StyledEditButton
          variant="contained"
          onClick={async () => handleClickOpenEdit(params.row.referencia)}
        >
          <EditIcon />
        </StyledEditButton>
      </strong>
    );
  };
  const renderGeolocationButton = (params) => {
    return (
      <strong>
        <StyledGeolocationButton
          variant="contained"
          onClick={async () =>
            handleClickOpenGeolocation(params.row.referencia)
          }
        >
          <EditLocationAltIcon />
        </StyledGeolocationButton>
      </strong>
    );
  };
  const renderDeleteButton = (params) => {
    return (
      <strong>
        <StyledDeleteButton
          variant="contained"
          onClick={() => handleClickOpenDelete(params.row.referencia)}
        >
          <DeleteIcon />
        </StyledDeleteButton>
      </strong>
    );
  };

  const CustomToolbar = () => (
    <GridToolbarContainer sx={{ justifyContent: "flex-end" }}>
      <Grid>
        <GridToolbarFilterButton />
      </Grid>
      <Grid>
        {checkRoles(["ADMIN", "OFICINA"]) && (
          <StyledCreateButton
            variant="contained"
            onClick={() => handleClickOpenCreate()}
          >
            Nuevo contador
          </StyledCreateButton>
        )}
      </Grid>
    </GridToolbarContainer>
  );

  const hideColumn = () => !checkRoles(["ADMIN", "OFICINA"]);

  const hideColumnUserDelete = () => !checkRoles(["ADMIN"]);

  const columns: GridColDef[] = [
    {
      field: "referencia",
      headerClassName: "theme--header",
      headerName: "Referencia",
      type: "number",
      flex: 1.3,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "numeroCont",
      headerClassName: "theme--header",
      headerName: "Número contador",
      type: "string",
      flex: 1.7,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "idRuta",
      headerClassName: "theme--header",
      headerName: "Ruta",
      type: "string",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params) => params.value?.idRuta,
      valueGetter: (params) => params.row.idRuta,
    },
    {
      field: "ordenRuta",
      headerClassName: "theme--header",
      headerName: "Orden Ruta",
      type: "string",
      flex: 1.3,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "titular",
      headerClassName: "theme--header",
      headerName: "Titular",
      type: "string",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "direccion",
      headerClassName: "theme--header",
      headerName: "Dirección",
      type: "string",
      flex: 1.2,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "estado",
      headerClassName: "theme--header",
      headerName: "Estado",
      type: "string",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "user",
      headerClassName: "theme--header",
      headerName: "Eliminado por",
      type: "string",
      flex: 1.5,
      align: "center",
      headerAlign: "center",
      hideable: hideColumnUserDelete(),
    },
    {
      field: "numBasuras",
      headerClassName: "theme--header",
      headerName: "Número basuras",
      type: "string",
      flex: 1.6,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "editar",
      headerClassName: "theme--header",
      headerName: "Editar",
      type: "string",
      flex: 0.8,
      renderCell: renderEditButton,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "geolocation",
      headerClassName: "theme--header",
      headerName: "Geolocalización",
      type: "string",
      flex: 0.8,
      renderCell: renderGeolocationButton,
      headerAlign: "center",
      align: "center",
      
    },
    {
      field: "eliminar",
      headerClassName: "theme--header",
      headerName: "Eliminar",
      type: "string",
      flex: 1,
      renderCell: renderDeleteButton,
      headerAlign: "center",
      align: "center",
      hideable: hideColumn(),
    },
  ];

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openHistoricExist, setOpenHistoricExist] = useState(false);
  const [openDeleteConfirmSuccess, setOpenDeleteConfirmSuccess] =
    useState(false);
  const [editCounter, setEditCounter] = useState<Contador>();
  const [editInfoContAdicional, setEditInfoContAdicional] =
    useState<InfoContAdicional>();
  const [rutas, setRutas] = useState<Ruta[]>();
  let currentContador = ContadorViewModel.getInstance().currentContador;

  const [successSnackbarCreate, setSuccessSnackbarCreate] = useState(false);
  const [successSnackbarEdit, setSuccessSnackbarEdit] = useState(false);

  const [referenceFilter, setReferenceFilter] = useState("");
  const [counterFilter, setCounterFilter] = useState("");
  const [address, setAddress] = useState("");

  const navigate = useNavigate();

  let items = contadores.slice();

  const handleClickOpenDelete = (ref: string) => {
    setOpenConfirmDelete(true);
    currentContador = items.find((item) => item.referencia === ref);
    ContadorViewModel.getInstance().setCurrentContador(currentContador);
  };

  const handleCloseHistoricExist = (
    _event?: SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") return;

    setOpenHistoricExist(false);
  };

  const handleClickOpenCreate = async () => {
    await RutaViewModel.getInstance().getRutas();
    setRutas(RutaViewModel.getInstance().rutas);
    setOpenCreate(true);
  };

  const handleClickOpenEdit = async (reference: string) => {
    await ContadorViewModel.getInstance().getContadorByReference(reference);
    await InfoContAdicionalViewModel.getInstance().getInfoContAdicionalByReference(
      reference
    );
    await RutaViewModel.getInstance().getRutas();
    setEditCounter(ContadorViewModel.getInstance().currentContador);
    setEditInfoContAdicional(
      InfoContAdicionalViewModel.getInstance().currentInfoContAdicional
    );
    setRutas(RutaViewModel.getInstance().rutas);
    setOpenEdit(true);
  };

  const handleClickOpenGeolocation = async (reference: string) => {
    navigate(`/localizationContador/${reference}`);
  };

  const handleCloseConfirmDelete = () => setOpenConfirmDelete(false);

  const handleCloseDeleteConfirmSuccess = (
    _event?: SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") return;

    setOpenDeleteConfirmSuccess(false);
  };

  const deleteContador = (contador: Contador) => {
    const filteredAllContadores = _.filter(contadores, (o) => {
      return (
        o.idRuta === contador.idRuta && o.referencia !== contador.referencia
      );
    });
    const orderedAllContadores = _.orderBy(
      filteredAllContadores,
      "ordenRuta",
      "asc"
    );
    orderedAllContadores?.forEach(async (i, index) => {
      i.ordenRuta = index + 1;
      await ContadorViewModel.getInstance().updateContadorByReference(
        i.referencia!,
        i
      );
    });
    contador.estado = "ELIMINADO" as ContadorEstadoEnum;
    contador.user = UserViewModel.getInstance().loggedUser?.email;
    ContadorViewModel.getInstance().updateContadorByReference(
      contador.referencia!,
      contador
    );
    const index = items.indexOf(contador);
    if (index > -1) {
      contadores.splice(index, 1);
    }
    setOpenConfirmDelete(false);
    setOpenDeleteConfirmSuccess(true);
  };

  const handleClickCloseEdit = async (contadorEditado?: Contador) => {
    if (contadorEditado !== undefined) {
      const contadorToPush: Contador = {
        referencia: contadorEditado.referencia,
        numeroCont: contadorEditado.numeroCont,
        estado: contadorEditado.estado,
        titular: contadorEditado.titular,
        direccion: contadorEditado.direccion,
        idRuta: contadorEditado.idRuta,
        ordenRuta: contadorEditado.ordenRuta,
        numBasuras: contadorEditado.numBasuras,
        fechaAlta: contadorEditado.fechaAlta,
        mensual: contadorEditado.mensual,
        nocturno: contadorEditado.nocturno,
        infoContAdicional: contadorEditado.infoContAdicional,
      };
      contadores.forEach((c: Contador) => {
        if (c.referencia === contadorToPush.referencia) {
          c.numeroCont = contadorToPush.numeroCont;
          c.idRuta = contadorToPush.idRuta;
          c.ordenRuta = contadorToPush.ordenRuta;
          c.titular = contadorEditado.titular;
          c.direccion = contadorEditado.direccion;
          c.estado = contadorEditado.estado;
          c.numBasuras = contadorEditado.numBasuras;
          c.fechaAlta = contadorEditado.fechaAlta;
          c.fechaBaja = contadorEditado.fechaBaja;
          c.nocturno = contadorEditado.nocturno;
          c.infoContAdicional = contadorEditado.infoContAdicional;
          c.user = contadorEditado.user;
        }
      });

      setSuccessSnackbarEdit(true);
      setOpenEdit(false);
    } else {
      setOpenEdit(false);
    }

    const contadoresFiltered = await ContadorViewModel.getInstance()
      .getAllContadoresByFilter(activePage, 20, filter, sortingField, sort)
      .then((counters: ContadorPaged) => counters.content);
    setContadores(contadoresFiltered ?? []);
  };

  const handleClickCloseCreate = (contadorCreado?: Contador) => {
    if (contadorCreado !== undefined) {
      const contadorToPush: Contador = {
        referencia: contadorCreado.referencia,
        numeroCont: contadorCreado.numeroCont,
        estado: contadorCreado.estado,
        titular: contadorCreado.titular,
        direccion: contadorCreado.direccion,
        idRuta: contadorCreado.idRuta,
        ordenRuta: contadorCreado.ordenRuta,
        numBasuras: contadorCreado.numBasuras,
        fechaAlta: contadorCreado.fechaAlta,
        mensual: contadorCreado.mensual,
        nocturno: contadorCreado.nocturno,
        infoContAdicional: contadorCreado.infoContAdicional,
      };
      const contadoresVM = ContadorViewModel.getInstance().contadores ?? [];
      setSuccessSnackbarCreate(true);
      contadoresVM.push(contadorToPush);
      ContadorViewModel.getInstance().contadores = contadoresVM;
    }
    setOpenCreate(false);
  };

  return (
    <>
      <Dialog open={openCreate} onClose={() => setOpenCreate(false)}>
        <DialogContent>
          <CounterForm onClose={handleClickCloseCreate} rutas={rutas ?? []} />
        </DialogContent>
      </Dialog>
      <Dialog open={openEdit} onClose={() => setOpenEdit(false)}>
        <DialogContent>
          <CounterForm
            onClose={handleClickCloseEdit}
            editCounter={editCounter}
            editInfoContAdicional={editInfoContAdicional}
            rutas={rutas ?? []}
          />
        </DialogContent>
      </Dialog>
      <StyledDiv>
        <Grid item container direction="row" sx={{ alignItems: "center" }}>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <TextField
              label="Referencia de contador"
              color="error"
              value={referenceFilter}
              onChange={(e) => {
                setReferenceFilter(e.target.value);
                setFilter({ ...filter, referencia: e.target.value });
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      setReferenceFilter("");
                      setFilter({ ...filter, referencia: undefined });
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <TextField
              label="Numero de contador"
              color="error"
              value={counterFilter}
              onChange={(e) => {
                setCounterFilter(e.target.value);
                setFilter({ ...filter, numeroCont: e.target.value });
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      setCounterFilter("");
                      setFilter({ ...filter, numeroCont: undefined });
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <TextField
              label="Dirección"
              color="error"
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
                setFilter({ ...filter, direccion: e.target.value });
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      setCounterFilter("");
                      setFilter({ ...filter, numeroCont: undefined });
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Divider style={{ height: 20, backgroundColor: "white" }} />
        <StyledDataGrid
          getRowId={(row) => row.referencia}
          rows={contadores}
          columns={columns}
          loading={isLoading}
          rowCount={totalItems}
          initialState={DEFAULT_GRID_INITIAL_STATE && {columns: {
            columnVisibilityModel: {
              geolocation: checkRoles(["ADMIN", "OFICINA"])
            }
          }}}
          page={activePage}
          onPageChange={handlePageChange}
          paginationMode="server"
          sortingMode="server"
          autoHeight
          components={{
            Toolbar: CustomToolbar,
          }}
          onSortModelChange={handleSortChange}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          localeText={{
            footerTotalVisibleRows: (visibleCount, totalCount) =>
              `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,
            noRowsLabel: "No hay resultados",
          }}
        />
      </StyledDiv>
      <Dialog
        open={openConfirmDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          ¿Desea eliminar el siguiente contador?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            align="center"
            color="inherit"
          >
            Referencia: <b>{currentContador?.referencia}</b> - Número
            contador:&nbsp;
            <b>{currentContador?.numeroCont}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDelete} color="error">
            Cancelar
          </Button>
          <Button
            onClick={() => deleteContador(currentContador!)}
            color="primary"
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openHistoricExist}
        autoHideDuration={3000}
        onClose={handleCloseHistoricExist}
      >
        <div>
          <CustomAlert onClose={handleCloseHistoricExist} severity="error">
            El contador tiene lecturas, no se puede eliminar.
          </CustomAlert>
        </div>
      </Snackbar>
      <Snackbar
        open={openDeleteConfirmSuccess}
        autoHideDuration={3000}
        onClose={handleCloseDeleteConfirmSuccess}
      >
        <div>
          <CustomAlert
            onClose={handleCloseDeleteConfirmSuccess}
            severity="success"
          >
            Contador eliminado con éxito
          </CustomAlert>
        </div>
      </Snackbar>
      <Snackbar
        open={successSnackbarCreate}
        autoHideDuration={6000}
        onClose={() => setSuccessSnackbarCreate(false)}
      >
        <div>
          <CustomAlert
            onClose={() => setSuccessSnackbarCreate(false)}
            severity="success"
          >
            Contador creado con éxito
          </CustomAlert>
        </div>
      </Snackbar>
      <Snackbar
        open={successSnackbarEdit}
        autoHideDuration={6000}
        onClose={() => setSuccessSnackbarEdit(false)}
      >
        <div>
          <CustomAlert
            onClose={() => setSuccessSnackbarEdit(false)}
            severity="success"
          >
            Contador editado con éxito
          </CustomAlert>
        </div>
      </Snackbar>
    </>
  );
};

export default MeterTable;
