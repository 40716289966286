/* tslint:disable */
/* eslint-disable */
/**
 * Aforadores
 * Proxy Service Api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Credentials
 */
export interface Credentials {
    /**
     * 
     * @type {string}
     * @memberof Credentials
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Credentials
     */
    password?: string;
}

/**
 * Check if a given object implements the Credentials interface.
 */
export function instanceOfCredentials(value: object): value is Credentials {
    return true;
}

export function CredentialsFromJSON(json: any): Credentials {
    return CredentialsFromJSONTyped(json, false);
}

export function CredentialsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Credentials {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'] == null ? undefined : json['email'],
        'password': json['password'] == null ? undefined : json['password'],
    };
}

export function CredentialsToJSON(value?: Credentials | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'password': value['password'],
    };
}

