import { styled, TextField } from "@mui/material";
import OwnColors from "../../constants/OwnColors";

export const StyledTextField = styled(TextField)(() => ({
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: OwnColors.LECOlightRed,
    },
    "&:hover .MuiInputLabel-root": {
      color: OwnColors.LECOCorporate,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: OwnColors.LECOCorporate,
    },
  }));