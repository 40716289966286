import {
  Button,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  AlertColor,
} from "@mui/material";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import { FC, Fragment, MutableRefObject, useEffect, useRef, useState } from "react";
import {
  StyledButton,
  StyledCardFixHeightRow1,
  StyledDatePickerNewReading,
  StyledFormControlReadOnlyCard2,
  StyledGridReadOnlyCard1,
  StyledSaveIcon,
  StyledTextFieldReadOnlyCard2,
} from "../styles";
import {
  Contador,
  HistoricoLecturas,
  HistoricoLecturasTipoLecturaEnum,
  User,
} from "../../../../client";
import { incidentCodes } from "../../../../constants/IncidentCodes";
import SessionStorage from "../../../../data/sessionStorage/SessionStorage";
import { DatePickerSlotsComponentsProps, LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import {
  calculateTotalConsumption,
  createNewReading,
} from "../../../../utils/reading-helper";
import {
  capitalizeReadingType,
  compareTo,
} from "../../../../utils/strings-helper";
import { getDefaultSlotProps } from "../../../../components/datepicker/datePickerUtils";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

type TMeterReadingSection = {
  savingFunction: (value: HistoricoLecturas) => Promise<void>;
  setSnackbarOpen: (severity: AlertColor, message: string) => void;
  snackbarOpen: boolean;
  lastHistory?: HistoricoLecturas;
  lastCharged?: HistoricoLecturas;
  currentMeter?: Contador;
  currentUser?: User;
};

export const MeterReadingSection: FC<{ props: TMeterReadingSection }> = ({
  props,
}) => {
  const [readingType, setReadingType] = useState<
    HistoricoLecturasTipoLecturaEnum | undefined
  >(undefined);
  const [readingDate, setReadingDate] = useState<Date | undefined>(new Date());
  const [incidentCode, setIncidentCode] = useState<string>();
  const [openDatepicker, setOpenDatepicker] = useState(false);
  const [readingValue, setReadingValue] = useState(0);
  const [newHistory, setNewHistory] = useState<HistoricoLecturas>({});
  const [warningIncidentCode, setWarningIncidentCode] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");

  const pickerRef = useRef() as MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    let [type, code, date] = [
      SessionStorage.getSelectedReadingType(),
      SessionStorage.getSelectedIncidentCode(),
      SessionStorage.getSelectedDate(),
    ];

    if (type !== null && type !== "")
      setReadingType(HistoricoLecturasTipoLecturaEnum[type]);

    if (code && code !== "") setIncidentCode(code);

    if (date !== null && date !== "") setReadingDate(new Date(parseInt(date)));
  }, []);

  const handleSubmit = () => {
    if (
      readingDate !== null &&
      readingDate?.toString() !== "" &&
      readingValue !== null &&
      readingType !== undefined &&
      incidentCode !== undefined
    ) {
      if (readingValue >= 0 && !isNaN(Number(readingValue))) {
        newHistory.codigoIncidencia = incidentCode;
        newHistory.tipoLectura = readingType;
        newHistory.fechaLectura = readingDate?.getTime() ?? 0;
        newHistory.lecturaContador = readingValue.toString();
        newHistory.consumo = "0";

        let newHistoryCreated = createNewReading(
          newHistory,
          props.currentMeter,
          props.currentUser?.name ?? ""
        );

        setNewHistory(newHistoryCreated);

        if (incidentCode === "Aviso lectura") {
          props.savingFunction(newHistoryCreated);
        } else handleCheckIncidentCode(newHistoryCreated, props?.lastHistory);
      } else props.setSnackbarOpen("error", "El dato de lectura es incorrecto");
    } else props.setSnackbarOpen("error", "Introduce los datos necesarios");
  };

  const handleCheckIncidentCode = (
    newReading: HistoricoLecturas,
    lastReading?: HistoricoLecturas
  ) => {
    let consumo = lastReading
      ? calculateTotalConsumption(
          Number(lastReading.lecturaContador ?? 0),
          Number(newReading.lecturaContador ?? 0)
        )
      : 0;

    newReading.consumo =
      newReading.tipoLectura === HistoricoLecturasTipoLecturaEnum.Facturada
        ? consumo.toString()
        : "0";

    if (consumo === 0) {
      if (incidentCode === "Sin consumo") {
        props.savingFunction(newReading);
      } else openWarningDialog("Sin consumo");
    } else if (consumo <= 99 && consumo > 0) {
      props.savingFunction(newReading);
    } else if (consumo > 99) {
      if (incidentCode === "Alto consumo") {
        props.savingFunction(newReading);
      } else openWarningDialog("Alto consumo");
    } else if (incidentCode === "Lectura última mal") {
      props.savingFunction(newReading);
    } else openWarningDialog("Lectura última mal");

    setReadingValue(0);
  };

  const openWarningDialog = (message: string) => {
    setWarningIncidentCode(true);
    setWarningMessage(message);
  };

  const formatLastHistory = (history?: HistoricoLecturas) =>
    history
      ? `${history?.lecturaContador} - ${new Date(
          history?.fechaLectura!
        ).toLocaleDateString("Es-es")} - ${history?.tipoLectura} - ${
          history?.codigoIncidencia
        }`
      : "(No existe lectura anterior)";

  return (
    <Fragment>
      <Grid item xs={5}>
        <StyledCardFixHeightRow1>
          <CardHeader
            title={
              <Grid
                item
                container
                direction="row"
                style={{ marginBottom: "-2.5vw", marginTop: "-0.5vw" }}
                sx={{
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Grid item xs={4} container direction="row">
                  <Grid>
                    <QueryBuilderIcon />
                    &nbsp;
                  </Grid>
                  <Grid>
                    <Typography>Lectura</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={8} style={{ textAlign: "end" }}>
                  <StyledButton
                    variant="outlined"
                    size="large"
                    color="error"
                    disabled={props.snackbarOpen}
                    onClick={() => handleSubmit()}
                  >
                    <StyledSaveIcon /> Guardar
                  </StyledButton>
                </Grid>
              </Grid>
            }
          ></CardHeader>
          <CardContent style={{ width: "inherit" }}>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                <StyledDatePickerNewReading
                  format="dd/MM/yyyy"
                  label="Fecha de lectura"
                  value={readingDate}
                  open={openDatepicker}
                  onClose={() => setOpenDatepicker(false)}
                  onChange={(value) =>
                    setReadingDate(new Date((value as number)?.toString() ?? ""))
                  }
                  ref={pickerRef}
                  slotProps={getDefaultSlotProps({
                    value: readingDate ?? new Date(),
                    pickerRef: pickerRef,
                    onClickCalendar: () => setOpenDatepicker(true),
                    onClickClear: () => setReadingDate(new Date()),
                  }) as DatePickerSlotsComponentsProps<unknown>}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <StyledTextFieldReadOnlyCard2
                required
                id="outlined-required"
                label="Lectura actual"
                value={readingValue ?? ""}
                onChange={(evt) => setReadingValue(Number(evt.target.value))}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <StyledFormControlReadOnlyCard2
                variant="outlined"
                fullWidth
                style={{ marginTop: 0, marginBottom: 0, width: "60%" }}
              >
                <InputLabel>Código de incidencia *</InputLabel>
                <Select
                  defaultValue={""}
                  value={incidentCode ?? ""}
                  onChange={(evt) => setIncidentCode(evt.target.value)}
                  name="Código de incidencia"
                  label="Código de incidencia  ."
                  fullWidth
                  required
                >
                  {incidentCodes.map((code: string, ix: number) => (
                    <MenuItem key={`${code}-${ix}`} value={code}>
                      {code}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControlReadOnlyCard2>
            </Grid>

            <Grid item xs={12}>
              <StyledFormControlReadOnlyCard2
                variant="outlined"
                fullWidth
                style={{ marginTop: 0, marginBottom: 0, width: "60%" }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Tipo lectura *
                </InputLabel>
                <Select
                  defaultValue={""}
                  labelId="demo-simple-select-outlined-label-required"
                  id="demo-simple-select-outlined-required"
                  value={readingType ?? ""}
                  onChange={(evt) =>
                    setReadingType(
                      HistoricoLecturasTipoLecturaEnum[evt.target.value]
                    )
                  }
                  name="Tipo lectura"
                  label="Tipo reading  ."
                  fullWidth
                  required
                >
                  {Object.keys(HistoricoLecturasTipoLecturaEnum)
                    .sort(compareTo)
                    .filter(
                      (reading: string) =>
                        reading !==
                          HistoricoLecturasTipoLecturaEnum.Eliminada &&
                        reading !== HistoricoLecturasTipoLecturaEnum.Aforador
                    )
                    .map((value: string, ix: number) => (
                      <MenuItem key={`${value}-${ix}`} value={value}>
                        {capitalizeReadingType(value)}
                      </MenuItem>
                    ))}
                </Select>
              </StyledFormControlReadOnlyCard2>
            </Grid>

            <StyledGridReadOnlyCard1 item xs={12}>
              <TextField
                color="error"
                id="filled-read-only-input"
                label="Última lectura"
                value={formatLastHistory(props.lastHistory)}
                InputProps={{ readOnly: true }}
                variant="filled"
                fullWidth
              />
            </StyledGridReadOnlyCard1>

            <StyledGridReadOnlyCard1 item xs={12}>
              <TextField
                color="error"
                id="filled-read-only-input"
                label="Última lectura facturada"
                value={formatLastHistory(props.lastCharged)}
                InputProps={{ readOnly: true }}
                variant="filled"
                fullWidth
              />
            </StyledGridReadOnlyCard1>
          </CardContent>
        </StyledCardFixHeightRow1>
      </Grid>
      <Dialog open={warningIncidentCode}>
        <DialogTitle>
          El código de incidencia calculado es:&nbsp;
          <strong>{warningMessage}</strong>. Usted ha seleccionado:&nbsp;
          <strong>{incidentCode}</strong>
        </DialogTitle>
        <DialogTitle>¿Desea continuar de todas formas?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setWarningIncidentCode(false)} color="error">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              props.savingFunction(newHistory);
              setWarningIncidentCode(false);
            }}
            color="primary"
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
