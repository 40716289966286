/* tslint:disable */
/* eslint-disable */
/**
 * Aforadores
 * Proxy Service Api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Contador } from './Contador';
import {
    ContadorFromJSON,
    ContadorFromJSONTyped,
    ContadorToJSON,
} from './Contador';

/**
 * 
 * @export
 * @interface ContadorPaged
 */
export interface ContadorPaged {
    /**
     * 
     * @type {Array<Contador>}
     * @memberof ContadorPaged
     */
    content?: Array<Contador>;
    /**
     * 
     * @type {boolean}
     * @memberof ContadorPaged
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContadorPaged
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContadorPaged
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ContadorPaged
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof ContadorPaged
     */
    numberOfElements?: number;
    /**
     * 
     * @type {number}
     * @memberof ContadorPaged
     */
    size?: number;
    /**
     * 
     * @type {number}
     * @memberof ContadorPaged
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof ContadorPaged
     */
    totalPages?: number;
}

/**
 * Check if a given object implements the ContadorPaged interface.
 */
export function instanceOfContadorPaged(value: object): value is ContadorPaged {
    return true;
}

export function ContadorPagedFromJSON(json: any): ContadorPaged {
    return ContadorPagedFromJSONTyped(json, false);
}

export function ContadorPagedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContadorPaged {
    if (json == null) {
        return json;
    }
    return {
        
        'content': json['content'] == null ? undefined : ((json['content'] as Array<any>).map(ContadorFromJSON)),
        'empty': json['empty'] == null ? undefined : json['empty'],
        'first': json['first'] == null ? undefined : json['first'],
        'last': json['last'] == null ? undefined : json['last'],
        'number': json['number'] == null ? undefined : json['number'],
        'numberOfElements': json['numberOfElements'] == null ? undefined : json['numberOfElements'],
        'size': json['size'] == null ? undefined : json['size'],
        'totalElements': json['totalElements'] == null ? undefined : json['totalElements'],
        'totalPages': json['totalPages'] == null ? undefined : json['totalPages'],
    };
}

export function ContadorPagedToJSON(value?: ContadorPaged | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'content': value['content'] == null ? undefined : ((value['content'] as Array<any>).map(ContadorToJSON)),
        'empty': value['empty'],
        'first': value['first'],
        'last': value['last'],
        'number': value['number'],
        'numberOfElements': value['numberOfElements'],
        'size': value['size'],
        'totalElements': value['totalElements'],
        'totalPages': value['totalPages'],
    };
}

