import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginView from "../../views/login/LoginView";
import HomeView from "../../views/home/HomeView";
import MeterReadingView from "../../views/reading/meterReading/MeterReadingView";
import MeterSelectorView from "../../views/reading/meterSelector/MeterSelectorView";
import RouteSelectorToSortView from "../../views/sortRoute/routeSelector/RouteSelectorToSortView";
import SortRouteView from "../../views/sortRoute/routeSorting/SortRouteView";
import HistoricView from "../../views/historic/HistoricView";
import ProtectedRoute from "./ProtectedRoute";
import MeterControlView from "../../views/meterControl/MeterControlView";
import RouteControlView from "../../views/routeControl/RouteControlView";
import PendingCountersView from "../../views/pending/PendingCountersView";
import StatsView from "../../views/stats/StatsView";
import UserControlView from "../../views/userControl/UserControlView";
import SessionStorage from "../../data/sessionStorage/SessionStorage";
import { AppSkeleton } from "../../components/skeleton/skeleton/AppSkeleton";
import LocalizationContadorView from "../../views/localizationContador/LocalizationContadorView";

interface ICustomRoute {
  path: string;
  element: JSX.Element;
  roles?: string[];
}

const InitialComponent = () => <>{SessionStorage.isLoggedIn() ? <HomeView /> : <LoginView />}</>;

export const Router = () => {

  const routes: ICustomRoute[] = [
    {
      path: "/",
      element: <InitialComponent />
    },
    {
      path: "/login",
      element: <LoginView />
    },
    {
      path: "/home",
      element: <HomeView />,
      roles: ["ADMIN", "OFICINA", "AFORADOR"]
    },
    {
      path: "/selectContador",
      element: <MeterSelectorView />,
      roles: ["ADMIN", "OFICINA"]
    },
    {
      path: "/lecturaContador",
      element: <MeterReadingView />,
      roles: ["ADMIN", "OFICINA"]
    },
    {
      path: "/selectRutaToSort",
      element: <RouteSelectorToSortView />,
      roles: ["ADMIN", "AFORADOR"]
    },
    {
      path: "/sortRuta",
      element: <SortRouteView />,
      roles: ["ADMIN", "AFORADOR"]
    },
    {
      path: "/historic",
      element: <HistoricView />,
      roles: ["ADMIN", "OFICINA"]
    },
    {
      path: "/localizationContador/:referencia",
      element: <LocalizationContadorView />,
      roles: ["ADMIN", "OFICINA"]
    },
    {
      path: "/contadorControl",
      element: <MeterControlView />,
      roles: ["ADMIN", "OFICINA"]
    },
    {
      path: "/rutaControl",
      element: <RouteControlView />,
      roles: ["ADMIN", "AFORADOR"]
    },
    {
      path: "/pending",
      element: <PendingCountersView />,
      roles: ["ADMIN", "AFORADOR"]
    },
    {
      path: "/stats",
      element: <StatsView />,
      roles: ["ADMIN"]
    },
    {
      path: "/userControl",
      element: <UserControlView />,
      roles: ["ADMIN"]
    }
  ]

  return (
    <BrowserRouter>
      <Routes>
        {routes.map(route => (
          <Route
            key={route.path}
            path={route.path}
            element={
              route.roles ?
                <ProtectedRoute allowedRoles={route.roles}>
                  <AppSkeleton component={route.element} />
                </ProtectedRoute>
                :
                <AppSkeleton component={route.element} />
            }
          />
        ))}
      </Routes>
    </BrowserRouter>
  )
};
