import { FC, useEffect } from "react";
import { observer } from "mobx-react";
import UserTable from "./components/UserTable";
import { UserViewModel } from "../../viewmodels/UserViewModel";
import {
  StyledBreadcrumbs,
  StyledPeopleIcon,
  StyledTypography,
} from "../../components/CustomBreadCrumbs";

const UserControlView: FC = () => {
  useEffect(() => {
    UserViewModel.getInstance().getUsers();
  }, []);

  return (
    <div>
      <StyledBreadcrumbs aria-label="breadcrumb">
        <StyledTypography color="textPrimary">
          <StyledPeopleIcon />
          Gestión de usuarios
        </StyledTypography>
      </StyledBreadcrumbs>
      <UserTable users={UserViewModel.getInstance().allUsers ?? []} />
    </div>
  );
};

export default observer(UserControlView);
