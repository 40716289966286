import { makeAutoObservable } from "mobx";
import { InfoContAdicional } from "../client";
import InfoContAdicionalRepository from "../data/repositories/InfoContAdicionalRepository";

export class InfoContAdicionalViewModel {
    private static instance: InfoContAdicionalViewModel | null = null;


    static getInstance(): InfoContAdicionalViewModel {
        if (!InfoContAdicionalViewModel.instance) {
            InfoContAdicionalViewModel.instance = new InfoContAdicionalViewModel();
        }
        return InfoContAdicionalViewModel.instance;
    }

    currentInfoContAdicional?: InfoContAdicional = undefined;
    infoContAdicionales: InfoContAdicional[] | undefined = undefined;

    private constructor() {
        makeAutoObservable(this);
        InfoContAdicionalRepository.getAllInfoContAdicional().then((res: InfoContAdicional[] | undefined) => {
            this.setInfoContAdicionales(res);
        });
        this.currentInfoContAdicional = {
            referencia: undefined,
            comentario: undefined,
            foto: undefined,
            gps: undefined,
            ubicacion: undefined,
            precintado: undefined,
            marca: undefined,
            modelo: undefined,
            fechaInstalacion: undefined,
            tipoLectura: undefined,
            procesado: undefined,
            calibre: undefined
        }
    }

    async createInfoContAdicional(infoContAdicional: InfoContAdicional) {
        return InfoContAdicionalRepository.createInfoContAdicional(infoContAdicional)
    }

    async getAllInfoContAdicional() {
        const newInfoContAdicionales = await InfoContAdicionalRepository.getAllInfoContAdicional();
        this.setInfoContAdicionales(newInfoContAdicionales);
    }

    async getInfoContAdicionalByReference(referencia: string) {
        const newCurrentInfoContAdicional = await InfoContAdicionalRepository.getInfoContAdicionalByReference(referencia);
        this.setCurrentInfoContAdicional(newCurrentInfoContAdicional);
    }

    async updateInfoContAdicionalByReference(referencia: string, infoContAdicional: InfoContAdicional) {
        return InfoContAdicionalRepository.updateInfoContAdicionalByReference(referencia, infoContAdicional);
    }

    async deleteInfoContAdicionalByReference(referencia: string) {
        await InfoContAdicionalRepository.deleteInfoContAdicionalByReference(referencia);
    }


    public setCurrentInfoContAdicional(infoContAdicional?: InfoContAdicional) {
        this.currentInfoContAdicional = infoContAdicional;
    }

    public setInfoContAdicionalValue(key: keyof InfoContAdicional, value: any) {
        this.currentInfoContAdicional && (this.currentInfoContAdicional[key] = value);
    }

    public setInfoContAdicionales(newValue: InfoContAdicional[] | undefined) {
        this.infoContAdicionales = newValue;
    }

}