import { TextField, FormControl, InputLabel, Select, MenuItem, Card, CardContent, CardHeader, Button, Snackbar, styled, Grid } from "@mui/material";
import TimelineIcon from '@mui/icons-material/Timeline';
import { useState } from "react";
import { Ruta } from "../../../client";
import { RutaViewModel } from "../../../viewmodels/RutaViewModel";
import OwnColors from "../../../constants/OwnColors";
import { CustomAlert } from "../../../components/CustomAlert";

const StyledCard = styled(Card)(({ theme }) => ({
    minWidth: 275,
}))

const StyledForm = styled('form')(({ theme }) => ({
    "& .MuiInputLabel-root.Mui-focused": {
        color: OwnColors.LECOCorporate
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: OwnColors.LECOCorporate
    }
}))

export interface IRouteForm {
    onClose: Function;
    editRuta?: Ruta;
}

const RouteForm = (params: Readonly<IRouteForm>) => {
    let ruta: Ruta | undefined

    const [editRuta] = useState(params.editRuta)

    const [idRuta, setIdRuta] = useState(editRuta ? editRuta.idRuta! : "")
    const [descripcion, setDescripcion] = useState(editRuta ? editRuta.descripcion! : "")
    const [estado, setEstado] = useState(editRuta?.estado)


    const [errorSnackbarCreate, setErrorSnackbarCreate] = useState(false)
    const [errorSnackbarEdit, setErrorSnackbarEdit] = useState(false)

    const getStatusValue = () => {
        if (estado === undefined) return undefined;
        if (estado) return "true"
        else return "false";
    }

    const submit = async () => {
        if (params.editRuta) await submitEdit()
        else await submitCreate()
    }

    const fixIdRuta = (id: string) => id.split(" ").join("_");

    const submitCreate = async () => {
        try {
            ruta = RutaViewModel.getInstance().currentRuta
            ruta!.idRuta = fixIdRuta(idRuta)
            ruta!.descripcion = descripcion
            ruta!.estado = estado ?? false
            //Rellenar objetos con los parametros de los formularios
            RutaViewModel.getInstance().createRuta(ruta!)
            params.onClose(ruta)
        }
        catch (error) {
            setErrorSnackbarCreate(true)
        }
    }

    const submitEdit = async () => {
        try {
            ruta = RutaViewModel.getInstance().currentRuta
            ruta!.idRuta = fixIdRuta(idRuta)
            ruta!.descripcion = descripcion
            ruta!.estado = estado ?? false
            //Rellenar objetos con los parametros de los formularios
            await RutaViewModel.getInstance().updateRutaById(idRuta, ruta!)
            params.onClose(ruta)
        }
        catch (error) {
            setErrorSnackbarEdit(true)
        }
    }

    return (
        (<Grid item xs={12}>
            <StyledForm onSubmit={() => submit()}> {/*El campo "action" queda invalidado pasándole como parámetro una funcion de javascript vacía*/}
                <Grid
                    item
                    container
                    spacing={6}
                    direction="row"
                    sx={{
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                    <Grid container item xs={12} spacing={3} style={{ "marginBottom": "2vw", "marginTop": "2vw" }} sx={{
                        justifyContent: "center"
                    }}>
                        <Grid item xs={10}>
                            <StyledCard>
                                <CardHeader
                                    title={
                                        <Grid
                                            item
                                            container
                                            direction="row"
                                            style={{ "marginBottom": "-3vw", "marginTop": "-1vw" }}
                                            sx={{
                                                alignItems: "center",
                                                justifyContent: "flex-start"
                                            }}>
                                            <TimelineIcon />&nbsp;
                                            <p>Información de la ruta</p>
                                        </Grid>
                                    }
                                >
                                </CardHeader>
                                <CardContent>
                                    <TextField
                                        color="error"
                                        autoFocus
                                        variant="outlined"
                                        margin="dense"
                                        value={idRuta}
                                        onChange={(event) => setIdRuta(event.target.value)}
                                        label="Id Ruta"
                                        fullWidth
                                        disabled={params.editRuta !== undefined}
                                        required
                                    />
                                    <TextField
                                        color="error"
                                        variant="outlined"
                                        margin="dense"
                                        value={descripcion}
                                        onChange={(event) => setDescripcion(event.target.value)}
                                        label="Descripción"
                                        type="text"
                                        fullWidth
                                        required
                                    />
                                    <FormControl variant="outlined" fullWidth style={{ "marginTop": 10, "marginBottom": 10 }}>
                                        <InputLabel color="error">Estado *</InputLabel>
                                        <Select
                                            defaultValue={""}
                                            color="error"
                                            variant="outlined"
                                            value={getStatusValue()}
                                            onChange={(event) => setEstado(event.target.value === "true")}
                                            label="Estado"
                                            fullWidth
                                            required
                                        >
                                            <MenuItem value="true">Alta</MenuItem>
                                            <MenuItem value="false">Baja</MenuItem>
                                        </Select>
                                    </FormControl>
                                </CardContent>
                            </StyledCard>
                        </Grid>
                        <Grid
                            item
                            container
                            direction="row"
                            xs={10}
                            sx={{
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                            <Grid
                                item
                                container
                                xs={6}
                                sx={{
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                <Button variant="contained" color="error" onClick={() => params.onClose()}>
                                    Cancelar
                                </Button>
                            </Grid>
                            <Grid
                                item
                                container
                                xs={6}
                                sx={{
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                <Button variant="contained" color="primary" type="submit">
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </StyledForm>
            <Snackbar open={errorSnackbarCreate} autoHideDuration={6000} onClose={() => setErrorSnackbarCreate(false)}>
                <div>
                    <CustomAlert onClose={() => setErrorSnackbarCreate(false)} severity="error">
                        Algo ha ido mal. La ruta no ha sido creada.
                    </CustomAlert>
                </div>
            </Snackbar>
            <Snackbar open={errorSnackbarEdit} autoHideDuration={6000} onClose={() => setErrorSnackbarEdit(false)}>
                <div>
                    <CustomAlert onClose={() => setErrorSnackbarEdit(false)} severity="error">
                        Algo ha ido mal. La ruta no ha sido editada.
                    </CustomAlert>
                </div>
            </Snackbar>
        </Grid >)
    );
}

export default RouteForm;
