import { Autocomplete, styled, Button, Typography, FormControl } from "@mui/material";
import OwnColors from "../../../constants/OwnColors";

const StyledDiv = styled('div')(() => ({
  display: 'block',
  textAlign: 'center'
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(3, 0, 3, 0),
  minWidth: 220,

  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: OwnColors.LECOCorporate
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: OwnColors.LECOCorporate
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: OwnColors.LECOCorporate
  }
}));

const StyledAutocomplete = styled(Autocomplete)(() => ({
  "&:hover .MuiInputLabel-root": {
    color: OwnColors.LECOCorporate
  },
}));

const StyledTypography = styled(Typography)(() => ({
  "&:hover .MuiInputLabel-root": {
    color: OwnColors.LECOCorporate
  },
}));

const StyledButton = styled(Button)(() => ({
  "&:hover .MuiInputLabel-root": {
    color: OwnColors.LECOCorporate
  },
}));

export { StyledAutocomplete, StyledButton, StyledDiv, StyledFormControl, StyledTypography }