import { MutableRefObject, useRef, useState } from "react";
import {
  DataGrid,
  GridCallbackDetails,
  GridCellParams,
  GridColDef,
  GridSortModel,
} from "@mui/x-data-grid";
import {
  CounterHistoryFilter,
  CounterHistoryFilterIncidentCodeEnum,
  CounterHistoryFilterReadingTypeEnum,
  HistoricoLecturas,
} from "../../../client";
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import {
  getDateString,
  getDateTimeStringWithoutHoursMinSec,
  getDateTimeString,
} from "../../../utils/DateUtils";
import ExportCsv from "../../../components/csvExport/ExportCsv";
import { incidentCodes } from "../../../constants/IncidentCodes";
import { Link, useNavigate } from "react-router-dom";
import { ContadorViewModel } from "../../../viewmodels/ContadorViewModel";
import { HistoricoLecturasViewModel } from "../../../viewmodels/HistoricoLecturasViewModel";
import { InfoContAdicionalViewModel } from "../../../viewmodels/InfoContAdicionalViewModel";
import { checkRoles } from "../../../infrastructure/utils/CheckRoles";
import { HistoricoLecturasTipoLecturaEnum } from "../../../client/models/HistoricoLecturas";
import { StyledRowItem } from "../styles";
import {
  DEFAULT_GRID_INITIAL_STATE,
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
} from "../../../constants/TableConstants";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getDefaultSlotProps } from "../../../components/datepicker/datePickerUtils";

export interface IHistoricTable {
  historico: HistoricoLecturas[];
  activePage: number;
  totalItems: number;
  filter: CounterHistoryFilter;
  isLoading: boolean;
  field?: string;
  sort?: string;
  handlePageChange: (value: number) => void;
  setFilter: (value: CounterHistoryFilter) => void;
  setSortingField: (value: string) => void;
  setSort: (value: string) => void;
  pageSize: number;
  setPageSize: (value: number) => void;
}

const columns: GridColDef[] = [
  {
    field: "referencia",
    headerClassName: "theme--header",
    headerName: "Referencia",
    type: "string",
    minWidth: 150,
    flex: 1.2,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) => params.value?.referencia?.referencia ?? "",
    renderCell: (params: GridCellParams) => {
      let show = checkRoles(["OFICINA", "AFORADOR"]);
      let bold =
        params.row.tipoLectura === HistoricoLecturasTipoLecturaEnum.Facturada;
      return show ? (
        <Link to="#" style={{ fontWeight: bold ? "bold" : "normal" }}>
          {params.row.referencia?.referencia}
        </Link>
      ) : (
        <></>
      );
    },
  },
  {
    field: "lecturaContador",
    headerClassName: "theme--header",
    headerName: "Lectura contador",
    type: "string",
    minWidth: 180,
    flex: 1.2,
    headerAlign: "center",
    align: "center",
    renderCell: (params: GridCellParams) => {
      let bold =
        params.row.tipoLectura === HistoricoLecturasTipoLecturaEnum.Facturada;
      return (
        <p style={{ fontWeight: bold ? "bold" : "normal" }}>
          {params.row.lecturaContador}
        </p>
      );
    },
  },
  {
    field: "fechaLectura",
    headerClassName: "theme--header",
    headerName: "Fecha lectura",
    type: "string",
    minWidth: 180,
    flex: 1.2,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) =>
      getDateString(
        params.value?.fechaLectura
          ? new Date(params.value?.fechaLectura)
          : undefined
      ),
    renderCell: (params: GridCellParams) => {
      let bold =
        params.row.tipoLectura === HistoricoLecturasTipoLecturaEnum.Facturada;
      let date = getDateTimeStringWithoutHoursMinSec(
        params.row.fechaLectura && new Date(params.row.fechaLectura)
      );
      return (
        <p style={{ fontWeight: bold ? "bold" : "normal" }}>{date ?? "-"}</p>
      );
    },
  },
  {
    field: "fechaGrabacionBBDD",
    headerClassName: "theme--header",
    headerName: "Fecha grabacion BBDD",
    type: "string",
    minWidth: 220,
    flex: 1.2,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) =>
      getDateTimeString(
        params.value?.fechaGrabacionBBDD
          ? new Date(params.value?.fechaGrabacionBBDD)
          : undefined
      ),
    renderCell: (params: GridCellParams) => {
      let bold =
        params.row.tipoLectura === HistoricoLecturasTipoLecturaEnum.Facturada;
      let date = getDateTimeString(
        params.row.fechaLectura && new Date(params.row.fechaGrabacionBBDD)
      );
      return (
        <p style={{ fontWeight: bold ? "bold" : "normal" }}>{date ?? "-"}</p>
      );
    },
  },
  {
    field: "tipoLectura",
    headerClassName: "theme--header",
    headerName: "Tipo lectura",
    type: "string",
    minWidth: 150,
    flex: 1.2,
    headerAlign: "center",
    align: "center",
    renderCell: (params: GridCellParams) => {
      let bold =
        params.row.tipoLectura === HistoricoLecturasTipoLecturaEnum.Facturada;
      return (
        <p style={{ fontWeight: bold ? "bold" : "normal" }}>
          {params.row.tipoLectura}
        </p>
      );
    },
  },
  {
    field: "codigoIncidencia",
    headerClassName: "theme--header",
    headerName: "Codigo incidencia",
    type: "string",
    minWidth: 180,
    flex: 1.2,
    headerAlign: "center",
    align: "center",
    renderCell: (params: GridCellParams) => {
      let bold =
        params.row.tipoLectura === HistoricoLecturasTipoLecturaEnum.Facturada;
      return (
        <p style={{ fontWeight: bold ? "bold" : "normal" }}>
          {params.row.codigoIncidencia}
        </p>
      );
    },
  },
  {
    field: "fechaFacturacion",
    headerClassName: "theme--header",
    headerName: "Fecha facturacion",
    type: "string",
    minWidth: 180,
    flex: 1.2,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) =>{
      return getDateString(
        params.value?.fechaFacturacion
          ? new Date(params.value?.fechaFacturacion)
          : undefined
      )},
    renderCell: (params: GridCellParams) => {
      let bold =
        params.row.tipoLectura === HistoricoLecturasTipoLecturaEnum.Facturada;
      let date = getDateTimeStringWithoutHoursMinSec(
        params.row.fechaFacturacion && new Date(params.row.fechaFacturacion)
      );
      return (
        <p style={{ fontWeight: bold ? "bold" : "normal" }}>{date ?? "-"}</p>
      );
    },
  },
  {
    field: "user",
    headerClassName: "theme--header",
    headerName: "Usuario",
    type: "string",
    minWidth: 120,
    flex: 1.2,
    headerAlign: "center",
    align: "center",
    renderCell: (params: GridCellParams) => {
      let bold =
        params.row.tipoLectura === HistoricoLecturasTipoLecturaEnum.Facturada;
      return (
        <p style={{ fontWeight: bold ? "bold" : "normal" }}>
          {params.row.user}
        </p>
      );
    },
  },
];

const HistoricTable = ({
  historico,
  totalItems,
  filter,
  isLoading,
  field,
  sort,
  handlePageChange = (_value: number) => {},
  setFilter = (_value: CounterHistoryFilter) => {},
  setSortingField = (_value: string) => {},
  setSort = (_value: string) => {},
  setPageSize,
}: IHistoricTable) => {
  const [selectedDateInit, setSelectedDateInit] = useState<Date | null>(null);
  const [selectedDateEnd, setSelectedDateEnd] = useState<Date | null>(null);
  const [selectedFacturationDateInit, setSelectedFacturationDateInit] =
    useState<Date | null>(null);
  const [selectedFacturationDateEnd, setSelectedFacturationDateEnd] =
    useState<Date | null>(null);
  const [selectedInitRecordDate, setSelectedInitRecordDate] =
    useState<Date | null>(null);
  const [selectedEndRecordDate, setSelectedEndRecordDate] =
    useState<Date | null>(null);
  const [referenceFilter, setReferenceFilter] = useState("");
  const [userFilter, setUserFilter] = useState("");
  const [lectureTypeFilter, setLectureTypeFilter] = useState(undefined);
  const [datePickerOpen, setDatePickerOpen] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const pickerRefs = [
    useRef() as MutableRefObject<HTMLInputElement>,
    useRef() as MutableRefObject<HTMLInputElement>,
    useRef() as MutableRefObject<HTMLInputElement>,
    useRef() as MutableRefObject<HTMLInputElement>,
    useRef() as MutableRefObject<HTMLInputElement>,
    useRef() as MutableRefObject<HTMLInputElement>,
  ];

  const navigate = useNavigate();

  const handleDatePickerClose = () =>
    setDatePickerOpen([false, false, false, false, false, false]);

  const handleDateChangeInit = (date) => {
    if (date === null) {
      setSelectedDateInit(date);
      setFilter({ ...filter, firstReadingDate: undefined });
    } else {
      const finalDate = new Date(date);
      if (!isNaN(finalDate.getTime())) {
        setSelectedDateInit(finalDate);
        setFilter({ ...filter, firstReadingDate: finalDate });
        datePickerOpen[0] = false;
        setDatePickerOpen([...datePickerOpen]);
      }
    }
  };

  const handleDateChangeEnd = (date) => {
    if (date === null) {
      setSelectedDateEnd(date);
      setFilter({ ...filter, lastReadingDate: undefined });
    } else {
      const finalDate = new Date(date);
      if (!isNaN(finalDate.getTime())) {
        setSelectedDateEnd(finalDate);
        setFilter({ ...filter, lastReadingDate: finalDate });
        datePickerOpen[1] = false;
        setDatePickerOpen([...datePickerOpen]);
      }
    }
  };

  const handleFacturationDateChangeInit = (date) => {
    if (date === null) {
      setSelectedFacturationDateInit(date);
      setFilter({ ...filter, firstPaymentDate: undefined });
    } else {
      const finalDate = new Date(date);
      if (!isNaN(finalDate.getTime())) {
        setSelectedFacturationDateInit(finalDate);
        setFilter({ ...filter, firstPaymentDate: finalDate });
        datePickerOpen[2] = false;
        setDatePickerOpen([...datePickerOpen]);
      }
    }
  };

  const handleFacturationDateChangeEnd = (date) => {
    if (date === null) {
      setSelectedFacturationDateEnd(date);
      setFilter({ ...filter, lastPaymentDate: undefined });
    } else {
      const finalDate = new Date(date);
      if (!isNaN(finalDate.getTime())) {
        setSelectedFacturationDateEnd(finalDate);
        setFilter({ ...filter, lastPaymentDate: finalDate });
        datePickerOpen[3] = false;
        setDatePickerOpen([...datePickerOpen]);
      }
    }
  };

  const handleRecordDateInitChange = (date) => {
    if (date === null) {
      setSelectedInitRecordDate(date);
      setFilter({ ...filter, firstRecordDate: undefined });
    } else {
      const finalDate = new Date(date);
      if (!isNaN(finalDate.getTime())) {
        setSelectedInitRecordDate(finalDate);
        setFilter({ ...filter, firstRecordDate: finalDate });
        datePickerOpen[4] = false;
        setDatePickerOpen([...datePickerOpen]);
      }
    }
  };

  const handleRecordDateEndChange = (date) => {
    if (date === null) {
      setSelectedEndRecordDate(date);
      setFilter({ ...filter, lastRecordDate: undefined });
    } else {
      const finalDate = new Date(date);
      if (!isNaN(finalDate.getTime())) {
        setSelectedEndRecordDate(finalDate);
        setFilter({ ...filter, lastRecordDate: finalDate });
        datePickerOpen[5] = false;
        setDatePickerOpen([...datePickerOpen]);
      }
    }
  };

  const handleReferenceFilter = (reference) => {
    setReferenceFilter(reference);
    setFilter({ ...filter, counterRef: reference ?? undefined });
  };

  const handleCodeFilter = (value: string) =>
    setFilter({
      ...filter,
      incidentCode: CounterHistoryFilterIncidentCodeEnum[value] ?? undefined,
    });

  const handleUserFilter = (user) => {
    setUserFilter(user);
    setFilter({ ...filter, username: user ?? undefined });
  };

  const handleLectureTypeFilter = (reading) => {
    setLectureTypeFilter(reading.target.value);
    setFilter({
      ...filter,
      readingType: reading ? reading.target.value : undefined,
    });
  };

  const compareTo = (a: string, b: string) => {
    if (a > b) return 1;
    if (a === b) return 0;
    return -1;
  };

  const createSelectValues = () =>
    Object.keys(CounterHistoryFilterReadingTypeEnum)
      .sort(compareTo)
      .map((v) => (
        <MenuItem key={v} value={v}>
          {v}
        </MenuItem>
      ));

  const handleSortChange = (
    model: GridSortModel,
    _details: GridCallbackDetails
  ) => {
    if (model.length !== 0) {
      setSortingField(model[0].field.toLowerCase() ?? "");
      setSort(model[0].sort?.toUpperCase() ?? "");
    } else {
      setSortingField("");
      setSort("");
    }
  };

  const buildIncidentCodeSelect = () =>
    Object.keys(CounterHistoryFilterIncidentCodeEnum).map(
      (val: string, index: number) => (
        <MenuItem value={val} key={val}>
          {incidentCodes[index] ?? ""}
        </MenuItem>
      )
    );

  const handleRefClick = async (reference: string) => {
    const counterVM = ContadorViewModel.getInstance();
    await counterVM.getContadorByReference(reference);

    if (counterVM.currentContador) {
      await InfoContAdicionalViewModel.getInstance().getInfoContAdicionalByReference(
        ContadorViewModel.getInstance().currentContador!.referencia!
      );
      await HistoricoLecturasViewModel.getInstance().getHistoricoLecturasByReference(
        ContadorViewModel.getInstance().currentContador!.referencia!
      );
      window.scrollTo(0, 0);
      navigate("/lecturaContador");
    }
  };

  return (
    <Grid m={"0px 80px"}>
      <Grid px={2}>
        <Grid container direction="row" alignItems="center" columnSpacing={4}>
          <StyledRowItem item xs={3} style={{ textAlign: "center" }}>
            <TextField
              variant="standard"
              label="Referencia de contador"
              color="error"
              value={referenceFilter}
              onChange={(e) => handleReferenceFilter(e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => handleReferenceFilter("")}>
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />
          </StyledRowItem>
          <StyledRowItem item xs={3} style={{ textAlign: "center" }}>
            <FormControl fullWidth sx={{ maxWidth: { md: "50%" } }}>
              <InputLabel id="enumSelectValues">Tipo de lectura</InputLabel>
              <Select
                variant="standard"
                defaultValue={""}
                value={lectureTypeFilter ?? ""}
                labelId="enumSelectValues"
                onChange={handleLectureTypeFilter}
              >
                <MenuItem key="Ninguna" value={undefined}>
                  Ninguna
                </MenuItem>
                {createSelectValues()}
              </Select>
            </FormControl>
          </StyledRowItem>
          <StyledRowItem item xs={3} style={{ textAlign: "center" }}>
            <FormControl fullWidth sx={{ maxWidth: { md: "50%" } }}>
              <InputLabel id="enumSelectValues">Código incidencia</InputLabel>
              <Select
                variant="standard"
                defaultValue={""}
                labelId="enumSelectValues"
                onChange={(evt) => handleCodeFilter(evt.target.value)}
              >
                <MenuItem key="" selected>
                  Todos
                </MenuItem>
                {buildIncidentCodeSelect()}
              </Select>
            </FormControl>
          </StyledRowItem>
          <StyledRowItem item xs={3} style={{ textAlign: "center" }}>
            <TextField
              variant="standard"
              label="Usuario"
              color="error"
              value={userFilter}
              onChange={(e) => handleUserFilter(e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => handleUserFilter("")}>
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />
          </StyledRowItem>
        </Grid>
        <Grid item container direction="row" alignItems="center" my={3}>
          <StyledRowItem item xs={4} style={{ textAlign: "center" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid container rowGap={2} justifyContent={"space-evenly"}>
                <Grid item>
                  <DatePicker
                    format="dd/MM/yyyy"
                    label="Fecha de lectura inicial"
                    value={selectedDateInit}
                    open={datePickerOpen[0]}
                    onClose={handleDatePickerClose}
                    onChange={handleDateChangeInit}
                    ref={pickerRefs[0]}
                    slotProps={getDefaultSlotProps({
                      value: selectedDateInit,
                      pickerRef: pickerRefs[0],
                      onClickCalendar: () => {
                        datePickerOpen[0] = true;
                        setDatePickerOpen([...datePickerOpen]);
                      },
                      onClickClear: () => handleDateChangeInit(null),
                    })}
                  />
                </Grid>
                <Grid item>
                  <DatePicker
                    format="dd/MM/yyyy"
                    label="Fecha de lectura final"
                    value={selectedDateEnd}
                    onChange={handleDateChangeEnd}
                    open={datePickerOpen[1]}
                    onClose={handleDatePickerClose}
                    ref={pickerRefs[1]}
                    slotProps={getDefaultSlotProps({
                      value: selectedDateEnd,
                      pickerRef: pickerRefs[1],
                      onClickCalendar: () => {
                        datePickerOpen[1] = true;
                        setDatePickerOpen([...datePickerOpen]);
                      },
                      onClickClear: () => handleDateChangeEnd(null),
                    })}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          </StyledRowItem>
          <StyledRowItem item xs={4} style={{ textAlign: "center" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid container rowGap={2} justifyContent="space-evenly">
                <Grid item>
                  <DatePicker
                    format="dd/MM/yyyy"
                    label="Fecha de facturación inicial"
                    value={selectedFacturationDateInit}
                    open={datePickerOpen[2]}
                    onClose={handleDatePickerClose}
                    onChange={handleFacturationDateChangeInit}
                    ref={pickerRefs[2]}
                    slotProps={getDefaultSlotProps({
                      value: selectedFacturationDateInit,
                      pickerRef: pickerRefs[2],
                      onClickCalendar: () => {
                        datePickerOpen[2] = true;
                        setDatePickerOpen([...datePickerOpen]);
                      },
                      onClickClear: () => handleFacturationDateChangeInit(null),
                    })}
                  />
                </Grid>
                <Grid item>
                  <DatePicker
                    format="dd/MM/yyyy"
                    label="Fecha de facturación final"
                    value={selectedFacturationDateEnd}
                    open={datePickerOpen[3]}
                    onClose={handleDatePickerClose}
                    onChange={handleFacturationDateChangeEnd}
                    ref={pickerRefs[3]}
                    slotProps={getDefaultSlotProps({
                      value: selectedFacturationDateEnd,
                      pickerRef: pickerRefs[3],
                      onClickCalendar: () => {
                        datePickerOpen[3] = true;
                        setDatePickerOpen([...datePickerOpen]);
                      },
                      onClickClear: () => handleFacturationDateChangeEnd(null),
                    })}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          </StyledRowItem>
          <StyledRowItem item xs={4} style={{ textAlign: "center" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid container rowGap={2} justifyContent="space-evenly">
                <Grid item>
                  <DatePicker
                    format="dd/MM/yyyy"
                    label="Fecha de grabación inicial"
                    value={selectedInitRecordDate}
                    open={datePickerOpen[4]}
                    onClose={handleDatePickerClose}
                    onChange={handleRecordDateInitChange}
                    ref={pickerRefs[4]}
                    slotProps={getDefaultSlotProps({
                      value: selectedInitRecordDate,
                      pickerRef: pickerRefs[4],
                      onClickCalendar: () => {
                        datePickerOpen[4] = true;
                        setDatePickerOpen([...datePickerOpen]);
                      },
                      onClickClear: () => handleRecordDateInitChange(null),
                    })}
                  />
                </Grid>
                <Grid item>
                  <DatePicker
                    format="dd/MM/yyyy"
                    label="Fecha de grabación final"
                    value={selectedEndRecordDate}
                    open={datePickerOpen[5]}
                    onClose={handleDatePickerClose}
                    onChange={handleRecordDateEndChange}
                    ref={pickerRefs[5]}
                    slotProps={getDefaultSlotProps({
                      value: selectedEndRecordDate,
                      pickerRef: pickerRefs[5],
                      onClickCalendar: () => {
                        datePickerOpen[5] = true;
                        setDatePickerOpen([...datePickerOpen]);
                      },
                      onClickClear: () => handleRecordDateEndChange(null),
                    })}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          </StyledRowItem>
        </Grid>
      </Grid>
      <Grid m={"8px 0px"}>
        <ExportCsv filter={filter} field={field} sort={sort} pending={false} />
      </Grid>
      <DataGrid
        onCellClick={(params: GridCellParams) => {
          if (params.field === "referencia")
            handleRefClick(params.row.referencia?.referencia);
        }}
        getRowId={(row) => row.idHistoricolecturas}
        rows={historico}
        columns={columns}
        loading={isLoading}
        rowCount={totalItems}
        initialState={DEFAULT_GRID_INITIAL_STATE}
        onPageSizeChange={(size: number, _details?: GridCallbackDetails) =>
          setPageSize(size)
        }
        onPageChange={handlePageChange}
        rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
        paginationMode="server"
        sortingMode="server"
        autoHeight
        /*components={{
                Toolbar: CustomToolbar
            }}*/
        onSortModelChange={handleSortChange}
        disableSelectionOnClick
        disableColumnMenu
        localeText={{
          footerTotalVisibleRows: (visibleCount, totalCount) =>
            `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,
          noRowsLabel: "No hay resultados",
        }}
      />
    </Grid>
  );
};

export default HistoricTable;
