import { useEffect, FC } from "react";
import { observer } from "mobx-react";
import { ContadorViewModel } from "../../viewmodels/ContadorViewModel";
import RouteTable from "./components/RouteTable";
import { RutaViewModel } from "../../viewmodels/RutaViewModel";
import { StyledBreadcrumbs, StyledTimelineIcon, StyledTypography } from "../../components/CustomBreadCrumbs";

const MeterControlView: FC = () => {
  useEffect(() => {
    RutaViewModel.getInstance().getRutas();
    ContadorViewModel.getInstance().getContadores();
  }, []);

  return (
    <div>
      <StyledBreadcrumbs aria-label="breadcrumb">
        <StyledTypography color="textPrimary">
          <StyledTimelineIcon />
          Gestión de rutas
        </StyledTypography>
      </StyledBreadcrumbs>
      <RouteTable
        rutas={RutaViewModel.getInstance().rutas ?? []}
        contadores={ContadorViewModel.getInstance().contadores ?? []}
      />
    </div>
  );
};

export default observer(MeterControlView);
