/* tslint:disable */
/* eslint-disable */
/**
 * Proxy Service API
 * Proxy Service API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DeleteResponse,
    DeleteResponseFromJSON,
    DeleteResponseToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    User,
    UserFromJSON,
    UserToJSON,
} from '../models';

export interface CreateAppUserRequest {
    user?: User;
}

export interface DeleteAppUserByIdRequest {
    idUser: number;
}

export interface GetAppUserByIdRequest {
    idUser: number;
}

export interface UpdateAppUserByIdRequest {
    idUser: number;
    user?: User;
}

/**
 * no description
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * Endpoint for createAppUser
     * createAppUser
     */
    async createAppUserRaw(requestParameters: CreateAppUserRequest): Promise<runtime.ApiResponse<User>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/appusers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserToJSON(requestParameters.user),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Endpoint for createAppUser
     * createAppUser
     */
    async createAppUser(user?: User): Promise<User> {
        const response = await this.createAppUserRaw({ user: user });
        return await response.value();
    }

    /**
     * deleteAppUserById
     * deleteAppUserById
     */
    async deleteAppUserByIdRaw(requestParameters: DeleteAppUserByIdRequest): Promise<runtime.ApiResponse<DeleteResponse>> {
        if (requestParameters.idUser === null || requestParameters.idUser === undefined) {
            throw new runtime.RequiredError('idUser','Required parameter requestParameters.idUser was null or undefined when calling deleteAppUserById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/appusers/{idUser}`.replace(`{${"idUser"}}`, encodeURIComponent(String(requestParameters.idUser))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteResponseFromJSON(jsonValue));
    }

    /**
     * deleteAppUserById
     * deleteAppUserById
     */
    async deleteAppUserById(idUser: number): Promise<DeleteResponse> {
        const response = await this.deleteAppUserByIdRaw({ idUser: idUser });
        return await response.value();
    }

    /**
     * Endpoint for list all users
     * getAllAppUsers
     */
    async getAllAppUsersRaw(): Promise<runtime.ApiResponse<Array<User>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/appusers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFromJSON));
    }

    /**
     * Endpoint for list all users
     * getAllAppUsers
     */
    async getAllAppUsers(): Promise<Array<User>> {
        const response = await this.getAllAppUsersRaw();
        return await response.value();
    }

    /**
     * getAppUserById
     * getAppUserById
     */
    async getAppUserByIdRaw(requestParameters: GetAppUserByIdRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.idUser === null || requestParameters.idUser === undefined) {
            throw new runtime.RequiredError('idUser','Required parameter requestParameters.idUser was null or undefined when calling getAppUserById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};
        
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/appusers/{idUser}`.replace(`{${"idUser"}}`, encodeURIComponent(String(requestParameters.idUser))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * getAppUserById
     * getAppUserById
     */
    async getAppUserById(idUser: number): Promise<User> {
        const response = await this.getAppUserByIdRaw({ idUser: idUser });
        return await response.value();
    }

    /**
     * updateAppUserById
     * updateAppUserById
     */
    async updateAppUserByIdRaw(requestParameters: UpdateAppUserByIdRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.idUser === null || requestParameters.idUser === undefined) {
            throw new runtime.RequiredError('idUser','Required parameter requestParameters.idUser was null or undefined when calling updateAppUserById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/appusers/{idUser}`.replace(`{${"idUser"}}`, encodeURIComponent(String(requestParameters.idUser))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserToJSON(requestParameters.user),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * updateAppUserById
     * updateAppUserById
     */
    async updateAppUserById(idUser: number, user?: User): Promise<User> {
        const response = await this.updateAppUserByIdRaw({ idUser: idUser, user: user });
        return await response.value();
    }

}
