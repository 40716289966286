import { Button, styled } from "@mui/material";
import OwnColors from "../../constants/OwnColors";

export const ExportCsvButton = styled(Button)(({ theme }) => ({
    textAlignLast: 'end',
    margin: theme.spacing(0, 0, 0, 0),
    color: OwnColors.LECOCorporate,
    borderColor: OwnColors.LECOCorporate,
}));

export const ExportIcon = styled('div')(() => ({
    marginLeft: 10,
}));