export const getDateString = (date?: Date) => date ? new Date(toUtc(date)).toLocaleDateString("es-Es") : "-";

export const getDateTimeString = (date?: Date) => date ? date.toLocaleString('es-Es'): "-";

export const getDateTimeStringWithoutHoursMinSec = (date?: Date) => date ? date.toLocaleString('es-Es', { year: 'numeric', month: 'numeric', day: 'numeric' }): "-";

export const toUtc = (date?: Date) => date ? Date.UTC(
  date.getFullYear(),
  date.getMonth(),
  date.getDate(),
  date.getHours(),
  date.getMinutes(),
  date.getSeconds(),
  date.getMilliseconds()
) : 0;