import { useEffect } from 'react';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Contador, HistoricoLecturas, HistoricoLecturasTipoLecturaEnum } from '../../../client';
import { observer } from 'mobx-react';
import { create, useTheme, addLicense } from '@amcharts/amcharts4/core';
import { XYChart, DateAxis, ValueAxis, LineSeries, XYCursor, XYChartScrollbar } from '@amcharts/amcharts4/charts';


export interface ICounterStats {
    contador?: Contador;
    data?: HistoricoLecturas[];
}

const CounterStats = ({ data }: ICounterStats) => {

    let chart: XYChart

    useEffect(() => {
        let chart_raw = create("chartdiv", XYChart);

        const datos = data?.map((item: HistoricoLecturas, index: number) => {
            const objeto = JSON.parse(JSON.stringify(item))
            if (item.tipoLectura === HistoricoLecturasTipoLecturaEnum.Facturada) {
                return ({ date: objeto.fechaLectura, name: "lectura" + index, value: objeto.consumo })
            }
            else return undefined;
        })

        chart_raw.paddingRight = 20;
        let dateAxis = chart_raw.xAxes.push(new DateAxis());
        dateAxis.renderer.grid.template.location = 0;

        let valueAxis = chart_raw.yAxes.push(new ValueAxis());
        valueAxis.tooltip!.disabled = true;
        valueAxis.renderer.minWidth = 35;

        let series = chart_raw.series.push(new LineSeries());
        series.dataFields.dateX = "date";
        series.dataFields.valueY = "value";

        series.tooltipText = "{valueY.value}";
        chart_raw.cursor = new XYCursor();

        let scrollbarX = new XYChartScrollbar();
        scrollbarX.series.push(series);
        chart_raw.scrollbarX = scrollbarX;

        chart_raw.data = datos!!;

        chart = chart_raw;

        return () => {
            chart.dispose();
        };

    }, [data]);



    useTheme(am4themes_animated);
    addLicense("ch-custom-attribution")


    return (
        <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
    )
}

export default observer(CounterStats)
