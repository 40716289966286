import { styled } from "@mui/material/styles";
import OwnColors from "../../../constants/OwnColors";
import { Button, Card, FormControl, Grid, TextField } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { DatePicker } from "@mui/x-date-pickers";

const StyledGridCard1 = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0, 2, 0, 0),
}));

const StyledGridCard3 = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2, 2, 0, 0),
}));

const StyledGridCard4 = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2, 0, 0, 0),
  maxHeight: 280,
}));

const StyledGridCard5 = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2, 0, 0, 0),
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: OwnColors.LECOlightRed,
  },
  "& .theme--header": {
    backgroundColor: OwnColors.LECOlightRed,
    color: "black",
  },
}));

const StyledGridCardContent5 = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(-4, 0, 0, 0),
}));

const StyledCardFixHeightRow1 = styled(Card)(() => ({
  height: 500,
}));

const StyledCardFixHeightRow2 = styled(Card)(() => ({
  maxHeight: 280,
}));

const StyledGridMiniCard = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0, 2, 0, 0),
}));

const StyledGridReadOnlyCard1 = styled(Grid)(({ theme }) => ({
  "&:hover .MuiInputLabel-root": {
    color: OwnColors.LECOCorporate,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: OwnColors.LECOCorporate,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: OwnColors.LECOCorporate,
  },
  "& .MuiInputLabel-root": {
    color: OwnColors.LECOMediumGrey,
  },
  padding: theme.spacing(0, 0, 2.75, 0),
  //width: "95%"
}));

const StyledTextFieldReadOnlyCard1 = styled(TextField)(({ theme }) => ({
  "&:hover .MuiInputLabel-root": {
    color: OwnColors.LECOCorporate,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: OwnColors.LECOCorporate,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: OwnColors.LECOCorporate,
  },
  "& .MuiInputLabel-root": {
    color: OwnColors.LECOMediumGrey,
  },
  padding: theme.spacing(0, 0, 2.75, 0),
  //width: "95%"
}));

const StyledFormControlReadOnlyCard2 = styled(FormControl)(({ theme }) => ({
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: OwnColors.LECOCorporate,
  },
  "&:hover .MuiInputLabel-root": {
    color: OwnColors.LECOCorporate,
  },
  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: OwnColors.LECOCorporate,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: OwnColors.LECOCorporate,
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: OwnColors.LECOCorporate,
  },
  padding: theme.spacing(0, 0, 1, 0),
  width: "60%",
}));
const StyledTextFieldReadOnlyCard2 = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: OwnColors.LECOCorporate,
  },
  "&:hover .MuiInputLabel-root": {
    color: OwnColors.LECOCorporate,
  },
  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: OwnColors.LECOCorporate,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: OwnColors.LECOCorporate,
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: OwnColors.LECOCorporate,
  },
  padding: theme.spacing(0, 0, 1, 0),
  width: "60%",
}));

const StyledTextFieldReadOnlyCard3 = styled(TextField)(({ theme }) => ({
  "&:hover .MuiInputLabel-root": {
    color: OwnColors.LECOCorporate,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: OwnColors.LECOCorporate,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: OwnColors.LECOCorporate,
  },
  "& .MuiInputLabel-root": {
    color: OwnColors.LECOMediumGrey,
  },
  padding: theme.spacing(0, 0, 1, 0),
  width: "95%",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textAlignLast: "end",
  margin: theme.spacing(0, 0, 0, 0),
  color: OwnColors.LECOCorporate,
  borderColor: OwnColors.LECOCorporate,
}));

const StyledTextFieldComentario = styled(TextField)(({ theme }) => ({
  "&:hover .MuiInputLabel-root": {
    color: OwnColors.LECOCorporate,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: OwnColors.LECOCorporate,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: OwnColors.LECOCorporate,
  },
  "& .MuiInputLabel-root": {
    color: OwnColors.LECOMediumGrey,
  },
  padding: theme.spacing(1, 0, 1.675, 0),
}));

const StyledSaveIcon = styled(SaveIcon)(({ theme }) => ({
  padding: theme.spacing(0, 1, 0, 0),
}));

const StyledDatePickerNewReading = styled(DatePicker)(({ theme }) => ({
  "&:hover .MuiInputLabel-root": {
    color: OwnColors.LECOCorporate,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: OwnColors.LECOCorporate,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: OwnColors.LECOCorporate,
  },
  "& .MuiInputLabel-root": {
    color: OwnColors.LECOMediumGrey,
  },
  padding: theme.spacing(0, 0, 1, 0),
  width: "60%",
}));

const StyledFormPaper = styled('form')(({ theme }) => ({
  marginTop: theme.spacing(5),
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
}));

const StyledGridPaper = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(5),
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
}));

const StyledTextFieldReadOnly = styled(TextField)(() => ({
  "&:hover .MuiInputLabel-root": {
    color: OwnColors.LECOCorporate,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: OwnColors.LECOCorporate,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: OwnColors.LECOCorporate,
  },
  "& .MuiInputLabel-root": {
    color: OwnColors.LECOMediumGrey,
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(0),
  width: "70%",

  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: OwnColors.LECOCorporate,
  },
  "&:hover .MuiInputLabel-root": {
    color: OwnColors.LECOCorporate,
  },
  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: OwnColors.LECOCorporate,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: OwnColors.LECOCorporate,
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: OwnColors.LECOCorporate,
  },
}));

const StyledDivRoot = styled('div')(({ theme }) => ({
  width: "100%",
  height: 200,
  backgroundColor: theme.palette.background.paper,
  marginTop: theme.spacing(2),
  borderColor: OwnColors.LECOCorporate,
}));

const StyledButtonSaveHis = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0, 1, 2, 0),
  color: OwnColors.LECOCorporate,
  borderColor: OwnColors.LECOCorporate,
}));

const StyledButtonDeleteHis = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0, 0, 2, 0),
  color: OwnColors.LECOCorporate,
  borderColor: OwnColors.LECOCorporate,
}));

export {
  StyledButton,
  StyledCardFixHeightRow2,
  StyledGridCard3,
  StyledGridCard4,
  StyledGridMiniCard,
  StyledTextFieldComentario,
  StyledTextFieldReadOnlyCard3,
  StyledGridCard1,
  StyledTextFieldReadOnlyCard1,
  StyledGridReadOnlyCard1,
  StyledCardFixHeightRow1,
  StyledSaveIcon,
  StyledDatePickerNewReading,
  StyledFormControlReadOnlyCard2,
  StyledTextFieldReadOnlyCard2,
  StyledDivRoot,
  StyledFormControl,
  StyledFormPaper,
  StyledGridPaper,
  StyledTextFieldReadOnly,
  StyledGridCard5,
  StyledGridCardContent5,
  StyledButtonSaveHis,
  StyledButtonDeleteHis,

};

