/* tslint:disable */
/* eslint-disable */
/**
 * Aforadores
 * Proxy Service Api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Contador } from './Contador';
import {
    ContadorFromJSON,
    ContadorFromJSONTyped,
    ContadorToJSON,
} from './Contador';

/**
 * 
 * @export
 * @interface HistoricoLecturas
 */
export interface HistoricoLecturas {
    /**
     * 
     * @type {string}
     * @memberof HistoricoLecturas
     */
    codigoIncidencia?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoricoLecturas
     */
    consumo?: string;
    /**
     * 
     * @type {number}
     * @memberof HistoricoLecturas
     */
    fechaFacturacion?: number;
    /**
     * 
     * @type {number}
     * @memberof HistoricoLecturas
     */
    fechaGrabacionBBDD?: number;
    /**
     * 
     * @type {number}
     * @memberof HistoricoLecturas
     */
    fechaLectura?: number;
    /**
     * 
     * @type {number}
     * @memberof HistoricoLecturas
     */
    idHistoricolecturas?: number;
    /**
     * 
     * @type {string}
     * @memberof HistoricoLecturas
     */
    lecturaContador?: string;
    /**
     * 
     * @type {boolean}
     * @memberof HistoricoLecturas
     */
    procesado?: boolean;
    /**
     * 
     * @type {Contador}
     * @memberof HistoricoLecturas
     */
    referencia?: Contador;
    /**
     * 
     * @type {string}
     * @memberof HistoricoLecturas
     */
    tipoLectura?: HistoricoLecturasTipoLecturaEnum;
    /**
     * 
     * @type {string}
     * @memberof HistoricoLecturas
     */
    user?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoricoLecturas
     */
    visible?: string;
}


/**
 * @export
 */
export const HistoricoLecturasTipoLecturaEnum = {
    Email: 'Email',
    Telefono: 'Telefono',
    Presencial: 'Presencial',
    Aforador: 'Aforador',
    Papel: 'Papel',
    Buzon: 'Buzon',
    Facturada: 'Facturada',
    Eliminada: 'Eliminada',
    Calculada: 'Calculada',
    Precinto: 'Precinto',
    Leido: 'Leido'
} as const;
export type HistoricoLecturasTipoLecturaEnum = typeof HistoricoLecturasTipoLecturaEnum[keyof typeof HistoricoLecturasTipoLecturaEnum];


/**
 * Check if a given object implements the HistoricoLecturas interface.
 */
export function instanceOfHistoricoLecturas(value: object): value is HistoricoLecturas {
    return true;
}

export function HistoricoLecturasFromJSON(json: any): HistoricoLecturas {
    return HistoricoLecturasFromJSONTyped(json, false);
}

export function HistoricoLecturasFromJSONTyped(json: any, ignoreDiscriminator: boolean): HistoricoLecturas {
    if (json == null) {
        return json;
    }
    return {
        
        'codigoIncidencia': json['codigoIncidencia'] == null ? undefined : json['codigoIncidencia'],
        'consumo': json['consumo'] == null ? undefined : json['consumo'],
        'fechaFacturacion': json['fechaFacturacion'] == null ? undefined : json['fechaFacturacion'],
        'fechaGrabacionBBDD': json['fechaGrabacionBBDD'] == null ? undefined : json['fechaGrabacionBBDD'],
        'fechaLectura': json['fechaLectura'] == null ? undefined : json['fechaLectura'],
        'idHistoricolecturas': json['idHistoricolecturas'] == null ? undefined : json['idHistoricolecturas'],
        'lecturaContador': json['lecturaContador'] == null ? undefined : json['lecturaContador'],
        'procesado': json['procesado'] == null ? undefined : json['procesado'],
        'referencia': json['referencia'] == null ? undefined : ContadorFromJSON(json['referencia']),
        'tipoLectura': json['tipoLectura'] == null ? undefined : json['tipoLectura'],
        'user': json['user'] == null ? undefined : json['user'],
        'visible': json['visible'] == null ? undefined : json['visible'],
    };
}

export function HistoricoLecturasToJSON(value?: HistoricoLecturas | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'codigoIncidencia': value['codigoIncidencia'],
        'consumo': value['consumo'],
        'fechaFacturacion': value['fechaFacturacion'],
        'fechaGrabacionBBDD': value['fechaGrabacionBBDD'],
        'fechaLectura': value['fechaLectura'],
        'idHistoricolecturas': value['idHistoricolecturas'],
        'lecturaContador': value['lecturaContador'],
        'procesado': value['procesado'],
        'referencia': ContadorToJSON(value['referencia']),
        'tipoLectura': value['tipoLectura'],
        'user': value['user'],
        'visible': value['visible'],
    };
}

