/* tslint:disable */
/* eslint-disable */
/**
 * Aforadores
 * Proxy Service Api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HistoricoLecturas } from './HistoricoLecturas';
import {
    HistoricoLecturasFromJSON,
    HistoricoLecturasFromJSONTyped,
    HistoricoLecturasToJSON,
} from './HistoricoLecturas';

/**
 * 
 * @export
 * @interface HistoricoLecturasPaged
 */
export interface HistoricoLecturasPaged {
    /**
     * 
     * @type {Array<HistoricoLecturas>}
     * @memberof HistoricoLecturasPaged
     */
    content?: Array<HistoricoLecturas>;
    /**
     * 
     * @type {boolean}
     * @memberof HistoricoLecturasPaged
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HistoricoLecturasPaged
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HistoricoLecturasPaged
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof HistoricoLecturasPaged
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof HistoricoLecturasPaged
     */
    numberOfElements?: number;
    /**
     * 
     * @type {number}
     * @memberof HistoricoLecturasPaged
     */
    size?: number;
    /**
     * 
     * @type {number}
     * @memberof HistoricoLecturasPaged
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof HistoricoLecturasPaged
     */
    totalPages?: number;
}

/**
 * Check if a given object implements the HistoricoLecturasPaged interface.
 */
export function instanceOfHistoricoLecturasPaged(value: object): value is HistoricoLecturasPaged {
    return true;
}

export function HistoricoLecturasPagedFromJSON(json: any): HistoricoLecturasPaged {
    return HistoricoLecturasPagedFromJSONTyped(json, false);
}

export function HistoricoLecturasPagedFromJSONTyped(json: any, ignoreDiscriminator: boolean): HistoricoLecturasPaged {
    if (json == null) {
        return json;
    }
    return {
        
        'content': json['content'] == null ? undefined : ((json['content'] as Array<any>).map(HistoricoLecturasFromJSON)),
        'empty': json['empty'] == null ? undefined : json['empty'],
        'first': json['first'] == null ? undefined : json['first'],
        'last': json['last'] == null ? undefined : json['last'],
        'number': json['number'] == null ? undefined : json['number'],
        'numberOfElements': json['numberOfElements'] == null ? undefined : json['numberOfElements'],
        'size': json['size'] == null ? undefined : json['size'],
        'totalElements': json['totalElements'] == null ? undefined : json['totalElements'],
        'totalPages': json['totalPages'] == null ? undefined : json['totalPages'],
    };
}

export function HistoricoLecturasPagedToJSON(value?: HistoricoLecturasPaged | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'content': value['content'] == null ? undefined : ((value['content'] as Array<any>).map(HistoricoLecturasToJSON)),
        'empty': value['empty'],
        'first': value['first'],
        'last': value['last'],
        'number': value['number'],
        'numberOfElements': value['numberOfElements'],
        'size': value['size'],
        'totalElements': value['totalElements'],
        'totalPages': value['totalPages'],
    };
}

