const OwnColors = {
  LECOCorporate: '#c40627', //Red
  LECOCorporateLight: '#fa617a',
  LECODarkDodgeBlue: '#0074e4',

  LECOLightGrey: '#e8e8e8',
  LECOMediumGrey: 'dddddd',
  LECODarkGrey: '#3b3939',
  LECOlightRed: '#ffebee',
  LECOWarning: '#fcc603',

  white: "#ffffff"
}

export default OwnColors