/* tslint:disable */
/* eslint-disable */
/**
 * Aforadores
 * Proxy Service Api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    idRole?: number;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    role?: string;
}

/**
 * Check if a given object implements the Role interface.
 */
export function instanceOfRole(value: object): value is Role {
    return true;
}

export function RoleFromJSON(json: any): Role {
    return RoleFromJSONTyped(json, false);
}

export function RoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Role {
    if (json == null) {
        return json;
    }
    return {
        
        'idRole': json['idRole'] == null ? undefined : json['idRole'],
        'role': json['role'] == null ? undefined : json['role'],
    };
}

export function RoleToJSON(value?: Role | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'idRole': value['idRole'],
        'role': value['role'],
    };
}

