import { FC, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { HistoricoLecturasViewModel } from "../../viewmodels/HistoricoLecturasViewModel";
import HistoricTable from "./components/HistoricTable";
import {
  CounterHistoryFilter,
  HistoricoLecturas,
  HistoricoLecturasPaged,
} from "../../client";
import { checkRoles } from "../../infrastructure/utils/CheckRoles";
import { Breadcrumbs } from "@mui/material";
import {
  StyledBox,
  StyledHistoryIcon,
  StyledTypography,
} from "../../components/CustomBreadCrumbs";

const HistoricView: FC = () => {
  const [filter, setFilter] = useState<CounterHistoryFilter>({});
  const [activePage, setActivePage] = useState(0);
  const [sortingField, setSortingField] = useState("");
  const [sortingType, setSortingType] = useState("");
  const [tableContent, setTableContent] = useState<HistoricoLecturas[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(20);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      setTableContent([]);
      HistoricoLecturasViewModel.getInstance()
        .getAllCounterReadingHistoryByFilter(
          0,
          pageSize,
          filter,
          sortingField,
          sortingType
        )
        .then((response: HistoricoLecturasPaged) => {
          setActivePage(response.number ?? 0);
          setTableContent([...(response.content ?? [])]);
          setTotalItems(response.totalElements ?? 0);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    })();
  }, [filter, sortingField, sortingType, pageSize]);

  const itemsByRole = (historicos: HistoricoLecturas[]) =>
    checkRoles(["ADMIN"])
      ? historicos
      : historicos.filter((item) => item.tipoLectura !== "Eliminada");

  const handlePageChange = (value: number) => {
    setIsLoading(true);
    setTableContent([]);
    HistoricoLecturasViewModel.getInstance()
      .getAllCounterReadingHistoryByFilter(
        value,
        pageSize,
        filter,
        sortingField,
        sortingType
      )
      .then((response: HistoricoLecturasPaged) => {
        setActivePage(response.number ?? 0);
        setTableContent([...(response.content ?? [])]);
        setTotalItems(response.totalElements ?? 0);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <div>
      <StyledBox>
        <Breadcrumbs aria-label="breadcrumb">
          <StyledTypography color="textPrimary">
            <StyledHistoryIcon />
            Filtrado de registros
          </StyledTypography>
        </Breadcrumbs>
      </StyledBox>
      <HistoricTable
        historico={itemsByRole(tableContent)}
        activePage={activePage}
        totalItems={totalItems}
        filter={filter}
        isLoading={isLoading}
        field={sortingField}
        sort={sortingType}
        handlePageChange={handlePageChange}
        setFilter={setFilter}
        setSortingField={setSortingField}
        setSort={setSortingType}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </div>
  );
};

export default observer(HistoricView);
