import React from 'react';
import TextField from '@mui/material/TextField';
import { StyledAutocomplete, StyledDiv, StyledFormControl } from '../styles';
import { Ruta } from '../../../../client/models/Ruta';
import { RutaViewModel } from '../../../../viewmodels/RutaViewModel';


export interface ISelectors {
    rutas: Ruta[];
}

const SelectorToSortForm = ({ rutas }: ISelectors) => {
    const vmRuta = RutaViewModel.getInstance();

    const handleChange = (_event, value) => {
        vmRuta.setCurrentRuta(value);
    }

    return (
        <StyledDiv>
            <StyledFormControl>
                <StyledAutocomplete
                    id="combo-box-demo"
                    options={rutas}
                    getOptionLabel={(ruta) => (ruta as Ruta).idRuta?.toString() ?? ''}
                    onChange={handleChange}
                    style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Ruta" variant="outlined" />}
                />
            </StyledFormControl>
        </StyledDiv>
    );
}

export default SelectorToSortForm;