import { CloudDownload } from "@mui/icons-material";
import { useState } from "react";
import { CounterHistoryFilter, HistoricoLecturas, HistoricoLecturasPaged } from "../../client";
import { HistoricoLecturasViewModel } from "../../viewmodels/HistoricoLecturasViewModel";
import { ExportCsvButton, ExportIcon } from "./styles";
import { unparse } from "papaparse";
import { getDateString, getDateTimeString } from "../../utils/DateUtils";
import { Alert, CircularProgress, Grid, Snackbar } from "@mui/material";

type TExportCSV = {
  pending: boolean;
  filter: CounterHistoryFilter;
  field?: string;
  sort?: string;
};

type TExportPending = {
  referencia?: string;
  ruta?: string;
  lectura?: string;
  fechaLectura?: string;
  fechaFacturacion?: string;
  fechaGrabacionBBDD?: string;
  tipoLectura?: string;
  codigoIncidencia?: string;
  usuario?: string;
};

type TExportHistory = {
  idHistoricoLecturas?: string;
  referencia?: string;
  lectura?: string;
  fechaLectura?: string;
  fechaFacturacion?: string;
  fechaGrabacionBBDD?: string;
  tipoLectura?: string;
  codigoIncidencia?: string;
  usuario?: string;
};

const ExportCsv = (props: TExportCSV) => {
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarError, setSnackbarError] = useState(false);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarError(false);
  };

  const exportingFunction = props.pending
    ? HistoricoLecturasViewModel.getInstance().getAllPendingCounters
    : HistoricoLecturasViewModel.getInstance()
      .getAllCounterReadingHistoryByFilter;

  const mapToExportPending = (value: HistoricoLecturas): TExportPending => {
    return {
      referencia: value.referencia?.referencia ?? "-",
      ruta: value.referencia?.idRuta?.idRuta ?? "-",
      lectura: value.lecturaContador ?? "-",
      fechaLectura: getDateString(
        value.fechaLectura ? new Date(value.fechaLectura) : undefined
      ),
      fechaFacturacion: getDateString(
        value.fechaFacturacion ? new Date(value.fechaFacturacion) : undefined
      ),
      fechaGrabacionBBDD: getDateTimeString(
        value.fechaGrabacionBBDD ? new Date(value.fechaGrabacionBBDD) : undefined
      ),
      tipoLectura: value.tipoLectura ?? "-",
      codigoIncidencia: value.codigoIncidencia ?? "-",
      usuario: value.user ?? "-",
    } as TExportPending;
  };

  const mapToExportingHistory = (value: HistoricoLecturas): TExportHistory => {
    return {
      idHistoricoLecturas: value.idHistoricolecturas ?? "-",
      referencia: value.referencia?.referencia ?? "-",
      lectura: value.lecturaContador ?? "-",
      fechaLectura: getDateString(
        value.fechaLectura ? new Date(value.fechaLectura) : undefined
      ),
      fechaFacturacion: getDateString(
        value.fechaFacturacion ? new Date(value.fechaFacturacion) : undefined
      ),
      fechaGrabacionBBDD: getDateTimeString(
        value.fechaGrabacionBBDD ? new Date(value.fechaGrabacionBBDD) : undefined
      ),
      tipoLectura: value.tipoLectura ?? "-",
      codigoIncidencia: value.codigoIncidencia ?? "-",
      usuario: value.user ?? "-",
    } as TExportHistory;
  };

  const documentDownload = async () => {
    setIsLoading(true);
    exportingFunction(0, 0, props.filter, props.field ?? "", props.sort ?? "")
      .then((response: HistoricoLecturasPaged) => {
        if (response.content) {
          const parsed = props.pending
            ? unparse(response.content.map(mapToExportPending), {
              delimiter: ";"
            })
            : unparse(response.content.map(mapToExportingHistory), {
              delimiter: ";"
            });

          const blob = new Blob([parsed], { type: "text/plain" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `export_${new Date().getTime()}.csv`);
          document.body.append(link);
          link.click();
          document.body.removeChild(link);
        }
        setIsLoading(false);
        setSnackbarOpen(true);
      })
      .catch(() => {
        setIsLoading(false);
        setSnackbarError(true);
        setSnackbarOpen(true);
      });
  };

  return (
    <Grid item xs={4}>
      <Grid
        container
        sx={{
          alignItems: "flex-start",
          justifyContent: "center"
        }}>
        <Grid item xs={12}>
          <ExportCsvButton variant="outlined" onClick={documentDownload}>
            Exportar a csv
            <ExportIcon>
              {isLoading ? <CircularProgress size={20} /> : <CloudDownload />}
            </ExportIcon>
          </ExportCsvButton>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        autoHideDuration={5000}
      >
        <div>
          <Alert severity={snackbarError ? "error" : "success"}>
            {snackbarError
              ? "Ocurrió un error al crear el fichero"
              : "Fichero creado exitósamente"}
          </Alert>
        </div>
      </Snackbar>
    </Grid>
  );
};

export default ExportCsv;
