import { Role, User } from "../../client";


export default class SessionStorage {
    static getToken() {
        const token = localStorage.getItem('token')
        return token ?? ""
    }

    static setToken(token: string) {
        localStorage.setItem("token", token);
    }

    static setLocalStorageUser(user: User | null) {
        if (user !== null && user !== undefined) {
            localStorage.setItem("userId", user.idUser!.toString());
            localStorage.setItem("email", user.email!);
        } else {
            localStorage.setItem("userId", "");
            localStorage.setItem("email", "");
        }

    }

    static getLocalStorageUser() {
        return [localStorage.getItem("userId"), SessionStorage.getToken()];
    }

    static isLoggedIn() {
        const token = localStorage.getItem('token')
        return (token?.length ?? 0) > 0
    }

    static isTokenValid() {
        const token = localStorage.getItem('token')
        let decodedToken;
        try {
            decodedToken = JSON.parse(atob(token!.split('.')[1]));
        } catch (e) {
            decodedToken = null;
        }
        if (decodedToken != null) {
            const expDate = new Date(0)
            expDate.setUTCSeconds(decodedToken.exp)
            const today = new Date()
            if (expDate <= today) {
                SessionStorage.setToken("");
                SessionStorage.setLocalStorageUser(null);
                SessionStorage.setRoles([]);
            }
            return expDate > today
        }
    }

    static getCurrentPath() {
        if (!localStorage.getItem('currentPath'))
            localStorage.setItem('currentPath', "/")
        return localStorage.getItem('currentPath')!
    }

    static setCurrentPath(path: string) {
        localStorage.setItem('currentPath', path)
    }


    static getRoles() {
        const roles = localStorage.getItem('roles')
        return roles ? JSON.parse(roles) : []
    }

    static setRoles(roles: Role[]) {
        if (roles !== null && roles !== undefined) {
            localStorage.setItem('roles', JSON.stringify(roles))
        } else {
            localStorage.setItem('roles', JSON.stringify([]))
        }
    }

    static setSelectedDate(date: string) {
        sessionStorage.setItem("fechaLectura", date);
    }

    static getSelectedDate() {
        return sessionStorage.getItem("fechaLectura");
    }

    static setSelectedReadingType(readingType: string) {
        sessionStorage.setItem("tipoLectura", readingType);
    }

    static getSelectedReadingType() {
        return sessionStorage.getItem("tipoLectura");
    }

    static setSelectedIncidentCode(incidentCode: string) {
        sessionStorage.setItem("codigoIncidencia", incidentCode);
    }

    static getSelectedIncidentCode() {
        return sessionStorage.getItem("codigoIncidencia");
    }

}