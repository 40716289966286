import { makeAutoObservable } from "mobx";
import { Credentials, LoginResponse, User } from "../client";
import UserRepository from "../data/repositories/UserRepository";
import SessionStorage from "../data/sessionStorage/SessionStorage";

export class UserViewModel {
  private static instance: UserViewModel | null = null;

  static getInstance(): UserViewModel {
    if (!UserViewModel.instance) {
      UserViewModel.instance = new UserViewModel();
    }
    return UserViewModel.instance;
  }

  loggedUser: User | undefined;
  currentUser: User | undefined;
  allUsers: User[] | undefined = undefined;

  loginResponse: LoginResponse | undefined = undefined;
  token: string;

  private constructor() {
    makeAutoObservable(this);
    this.currentUser = {
      idUser: undefined,
      email: undefined,
      name: undefined,
      password: undefined,
      roles: undefined,
      processed: undefined,
    };
    this.token = "";
  }

  async createUser(user: User) {
    return UserRepository.createUser(user);
  }

  async getUsers() {
    const updatedUsers = await UserRepository.getUsers();
    this.setAllUsers(updatedUsers);
  }

  async getUserById(id: number) {
    return UserRepository.getUser(id);
  }

  async updateUserById(id: number, user: User) {
    return UserRepository.updateUserById(id, user);
  }

  async deleteUser(id: number) {
    await UserRepository.deleteUserById(id);
  }

  public setLoggedUser(user: User | undefined) {
    this.loggedUser = user;
  }

  public setLoggedUserValue(key: keyof User, value: any) {
    this.loggedUser && (this.loggedUser[key] = value);
  }

  public setCurrentUser(user: User | undefined) {
    this.currentUser = user;
  }

  public setCurrentUserValue(key: keyof User, value: any) {
    this.currentUser && (this.currentUser[key] = value);
  }

  public setAllUsers(allUsers: User[] | undefined) {
    this.allUsers = allUsers;
  }

  //login
  public login(credentials: Credentials): Promise<boolean> {
    return new Promise((resolve) => {
      if (
        credentials?.email &&
        credentials?.email?.length > 0 &&
        credentials?.password &&
        credentials?.password?.length > 0
      ) {
        UserRepository.login(credentials)
          .then((res: LoginResponse | undefined) => {
            if (res?.token && res?.user) {
              UserViewModel.getInstance().setToken(res.token);
              UserViewModel.getInstance().setLoggedUser(res.user);
              SessionStorage.setToken(res.token);
              SessionStorage.setLocalStorageUser(res.user);
              SessionStorage.setRoles(res.user.roles ?? []);
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch(() => resolve(false))
          .finally(() => {
            UserRepository.getUsers().then((res: User[] | undefined) => {
              this.allUsers = res;
            });
          });
      } else {
        resolve(false);
      }
    });
  }

  public logout() {
    UserViewModel.getInstance().setLoggedUser(undefined);
    SessionStorage.setToken("");
    SessionStorage.setLocalStorageUser(null);
    SessionStorage.setRoles([]);
  }

  public setToken(token: string) {
    this.token = token;
  }

  loadLocalStorageUser(): boolean {
    const [userId, userToken] = SessionStorage.getLocalStorageUser();
    if (userId && userToken) {
      this.token = userToken;
      return true;
    } else {
      return false;
    }
  }
}
