/* tslint:disable */
/* eslint-disable */
/**
 * Aforadores
 * Proxy Service Api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CounterHistoryFilter
 */
export interface CounterHistoryFilter {
    /**
     * 
     * @type {string}
     * @memberof CounterHistoryFilter
     */
    counterRef?: string;
    /**
     * 
     * @type {Date}
     * @memberof CounterHistoryFilter
     */
    firstPaymentDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CounterHistoryFilter
     */
    firstReadingDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CounterHistoryFilter
     */
    firstRecordDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof CounterHistoryFilter
     */
    incidentCode?: CounterHistoryFilterIncidentCodeEnum;
    /**
     * 
     * @type {Date}
     * @memberof CounterHistoryFilter
     */
    lastPaymentDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CounterHistoryFilter
     */
    lastReadingDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CounterHistoryFilter
     */
    lastRecordDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof CounterHistoryFilter
     */
    readingType?: CounterHistoryFilterReadingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CounterHistoryFilter
     */
    routeId?: string;
    /**
     * 
     * @type {string}
     * @memberof CounterHistoryFilter
     */
    username?: string;
}


/**
 * @export
 */
export const CounterHistoryFilterIncidentCodeEnum = {
    Compromised: 'COMPROMISED',
    StandardReading: 'STANDARD_READING',
    ReadingAdvice: 'READING_ADVICE',
    EstimatedReading: 'ESTIMATED_READING',
    ZeroConsumption: 'ZERO_CONSUMPTION',
    DumpOnly: 'DUMP_ONLY',
    HighConsumption: 'HIGH_CONSUMPTION',
    Start: 'START',
    RetrievingQuantity: 'RETRIEVING_QUANTITY',
    Closed: 'CLOSED',
    CounterMalfunction: 'COUNTER_MALFUNCTION',
    LastReadingError: 'LAST_READING_ERROR',
    Monthly: 'MONTHLY',
    NumberReturning: 'NUMBER_RETURNING'
} as const;
export type CounterHistoryFilterIncidentCodeEnum = typeof CounterHistoryFilterIncidentCodeEnum[keyof typeof CounterHistoryFilterIncidentCodeEnum];

/**
 * @export
 */
export const CounterHistoryFilterReadingTypeEnum = {
    Email: 'Email',
    Telefono: 'Telefono',
    Presencial: 'Presencial',
    Aforador: 'Aforador',
    Papel: 'Papel',
    Buzon: 'Buzon',
    Facturada: 'Facturada',
    Eliminada: 'Eliminada',
    Calculada: 'Calculada',
    Precinto: 'Precinto',
    Leido: 'Leido'
} as const;
export type CounterHistoryFilterReadingTypeEnum = typeof CounterHistoryFilterReadingTypeEnum[keyof typeof CounterHistoryFilterReadingTypeEnum];


/**
 * Check if a given object implements the CounterHistoryFilter interface.
 */
export function instanceOfCounterHistoryFilter(value: object): value is CounterHistoryFilter {
    return true;
}

export function CounterHistoryFilterFromJSON(json: any): CounterHistoryFilter {
    return CounterHistoryFilterFromJSONTyped(json, false);
}

export function CounterHistoryFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): CounterHistoryFilter {
    if (json == null) {
        return json;
    }
    return {
        
        'counterRef': json['counterRef'] == null ? undefined : json['counterRef'],
        'firstPaymentDate': json['firstPaymentDate'] == null ? undefined : (new Date(json['firstPaymentDate'])),
        'firstReadingDate': json['firstReadingDate'] == null ? undefined : (new Date(json['firstReadingDate'])),
        'firstRecordDate': json['firstRecordDate'] == null ? undefined : (new Date(json['firstRecordDate'])),
        'incidentCode': json['incidentCode'] == null ? undefined : json['incidentCode'],
        'lastPaymentDate': json['lastPaymentDate'] == null ? undefined : (new Date(json['lastPaymentDate'])),
        'lastReadingDate': json['lastReadingDate'] == null ? undefined : (new Date(json['lastReadingDate'])),
        'lastRecordDate': json['lastRecordDate'] == null ? undefined : (new Date(json['lastRecordDate'])),
        'readingType': json['readingType'] == null ? undefined : json['readingType'],
        'routeId': json['routeId'] == null ? undefined : json['routeId'],
        'username': json['username'] == null ? undefined : json['username'],
    };
}

export function CounterHistoryFilterToJSON(value?: CounterHistoryFilter | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'counterRef': value['counterRef'],
        'firstPaymentDate': value['firstPaymentDate'] == null ? undefined : ((value['firstPaymentDate']).toISOString().substring(0,10)),
        'firstReadingDate': value['firstReadingDate'] == null ? undefined : ((value['firstReadingDate']).toISOString().substring(0,10)),
        'firstRecordDate': value['firstRecordDate'] == null ? undefined : ((value['firstRecordDate']).toISOString().substring(0,10)),
        'incidentCode': value['incidentCode'],
        'lastPaymentDate': value['lastPaymentDate'] == null ? undefined : ((value['lastPaymentDate']).toISOString().substring(0,10)),
        'lastReadingDate': value['lastReadingDate'] == null ? undefined : ((value['lastReadingDate']).toISOString().substring(0,10)),
        'lastRecordDate': value['lastRecordDate'] == null ? undefined : ((value['lastRecordDate']).toISOString().substring(0,10)),
        'readingType': value['readingType'],
        'routeId': value['routeId'],
        'username': value['username'],
    };
}

