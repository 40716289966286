/* tslint:disable */
/* eslint-disable */
/**
 * Aforadores
 * Proxy Service Api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteResponse
 */
export interface DeleteResponse {
    /**
     * 
     * @type {string}
     * @memberof DeleteResponse
     */
    message?: string;
}

/**
 * Check if a given object implements the DeleteResponse interface.
 */
export function instanceOfDeleteResponse(value: object): value is DeleteResponse {
    return true;
}

export function DeleteResponseFromJSON(json: any): DeleteResponse {
    return DeleteResponseFromJSONTyped(json, false);
}

export function DeleteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'message': json['message'] == null ? undefined : json['message'],
    };
}

export function DeleteResponseToJSON(value?: DeleteResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'message': value['message'],
    };
}

