import { UserViewModel } from "../../../viewmodels/UserViewModel";
import {
  GridColDef,
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Grid, Button, Snackbar } from "@mui/material";
import {
  StyledButtonCreate,
  StyledButtonDelete,
  StyledButtonEdit,
  StyledDataGridRoot,
  StyledDiv,
} from "../styles";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { User } from "../../../client";
import UserForm from "./UserForm";
import { CustomAlert } from "../../../components/CustomAlert";
import { SyntheticEvent, useState } from "react";
import { DEFAULT_GRID_INITIAL_STATE, DEFAULT_ROWS_PER_PAGE_OPTIONS } from "../../../constants/TableConstants";

export interface IUserTable {
  users: User[];
}

const UserTable = ({ users }: IUserTable) => {
  const renderEditButton = (params: GridRenderCellParams) => {
    return (
      <strong>
        <StyledButtonEdit
          variant="contained"
          onClick={() => handleClickOpenEdit(params.row.email)}
        >
          <EditIcon />
        </StyledButtonEdit>
      </strong>
    );
  };
  const renderDeleteButton = (params: GridRenderCellParams) => {
    return (
      <strong>
        {params.row.idUser !== 1 && (
          <StyledButtonDelete
            variant="contained"
            onClick={() => handleClickOpenDelete(params.row.email)}
          >
            <DeleteIcon />
          </StyledButtonDelete>
        )}
      </strong>
    );
  };
  const CustomToolbar = () => (
    <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
      <Grid item xs={6}>
        <GridToolbarFilterButton />
      </Grid>
      <Grid item xs={6}>
        <StyledButtonCreate
          variant="contained"
          onClick={handleClickOpenCreate}
        >
          Nuevo usuario
        </StyledButtonCreate>
      </Grid>
    </GridToolbarContainer>
  );

  const columns: GridColDef[] = [
    {
      field: "email",
      headerClassName: "theme--header",
      headerName: "Email",
      type: "string",
      flex: 0.5,
    },
    {
      field: "name",
      headerClassName: "theme--header",
      headerName: "Nombre",
      type: "string",
      flex: 0.5,
    },
    {
      field: "processed",
      headerClassName: "theme--header",
      headerName: "Procesado",
      type: "string",
      flex: 0.2,
      valueGetter: (params) => params.row.processed ? "SI" : "NO",
    },
    {
      field: "roles",
      headerClassName: "theme--header",
      headerName: "Roles",
      type: "string",
      flex: 0.5,
      valueGetter: (params) => {
        let rolesString = "";
        params.row.roles?.forEach((i) => {
          if (rolesString !== "") rolesString += ", " + i.role;
          else rolesString += i.role;
        });
        return rolesString;
      },
    },
    {
      field: "editar",
      headerClassName: "theme--header",
      headerName: "Editar",
      type: "string",
      flex: 0.15,
      renderCell: renderEditButton,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "eliminar",
      headerClassName: "theme--header",
      headerName: "Eliminar",
      type: "string",
      flex: 0.15,
      renderCell: renderDeleteButton,
      headerAlign: "center",
      align: "center",
    },
  ];

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [editUser, setEditUser] = useState<User>();
  const [openEdit, setOpenEdit] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openDeleteConfirmSuccess, setOpenDeleteConfirmSuccess] = useState(false);
  const [successSnackbarCreate, setSuccessSnackbarCreate] = useState(false);
  const [successSnackbarEdit, setSuccessSnackbarEdit] = useState(false);

  let currentUser = UserViewModel.getInstance().currentUser;
  let items = users.slice();

  const handleClickOpenCreate = () => setOpenCreate(true);

  const handleClickCloseCreate = (userCreado?: User) => {
    if (userCreado !== undefined) {
      setSuccessSnackbarCreate(true);
      users.push(userCreado);
    }
    setOpenCreate(false);
  };

  const handleClickOpenEdit = async (email: string) => {
    const userToEdit = items.find((item) => item.email === email);
    UserViewModel.getInstance().setCurrentUser(userToEdit);
    setEditUser(UserViewModel.getInstance().currentUser);
    setOpenEdit(true);
  };

  const handleClickCloseEdit = (userEditado?: User) => {
    if (userEditado !== undefined) {
      users.forEach((i, index) => {
        if (i.idUser === userEditado.idUser) {
          users.splice(index, 1);
          users.push(userEditado);
        }
      });
      setSuccessSnackbarEdit(true);
    }
    setOpenEdit(false);
  };

  const handleClickOpenDelete = (email: string) => {
    const userToDelete = items.find((item) => item.email === email);
    UserViewModel.getInstance().setCurrentUser(userToDelete);
    setOpenConfirmDelete(true);
  };

  const handleCloseConfirmDelete = () => setOpenConfirmDelete(false);

  const handleCloseDeleteConfirmSuccess = (
    _event?: SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") return;

    setOpenDeleteConfirmSuccess(false);
  };

  const deleteUser = (user: User) => {
    UserViewModel.getInstance().deleteUser(user.idUser!);
    const index = items.indexOf(user);
    if (index > -1) {
      users.splice(index, 1);
    }
    setOpenConfirmDelete(false);
    setOpenDeleteConfirmSuccess(true);
  };

  return (
    <>
      <Dialog open={openCreate} onClose={() => setOpenCreate(false)}>
        <DialogContent>
          <UserForm onClose={handleClickCloseCreate} />
        </DialogContent>
      </Dialog>
      <Dialog open={openEdit} onClose={() => setOpenEdit(false)}>
        <DialogContent>
          <UserForm onClose={handleClickCloseEdit} editUser={editUser} />
        </DialogContent>
      </Dialog>
      <StyledDiv>
        <StyledDataGridRoot
          getRowId={(row) => row.email}
          rows={items}
          columns={columns}
          initialState={DEFAULT_GRID_INITIAL_STATE}
          rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
          autoHeight
          components={{
            Toolbar: CustomToolbar,
          }}
          disableSelectionOnClick
          disableColumnMenu
        />
      </StyledDiv>
      <Dialog
        open={openConfirmDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          ¿Desea eliminar el siguiente usuario?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            align="center"
            color="inherit"
          >
            Usuario: <b>{currentUser?.email}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDelete} color="error">
            Cancelar
          </Button>
          <Button
            onClick={() => deleteUser(currentUser!)}
            color="primary"
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openDeleteConfirmSuccess}
        autoHideDuration={3000}
        onClose={handleCloseDeleteConfirmSuccess}
      >
        <div>
          <CustomAlert
            onClose={handleCloseDeleteConfirmSuccess}
            severity="success"
          >
            Usuario eliminado con éxito
          </CustomAlert>
        </div>
      </Snackbar>
      <Snackbar
        open={successSnackbarCreate}
        autoHideDuration={6000}
        onClose={() => setSuccessSnackbarCreate(false)}
      >
        <div>
          <CustomAlert
            onClose={() => setSuccessSnackbarCreate(false)}
            severity="success"
          >
            Usuario creado con éxito.
          </CustomAlert>
        </div>
      </Snackbar>
      <Snackbar
        open={successSnackbarEdit}
        autoHideDuration={6000}
        onClose={() => setSuccessSnackbarEdit(false)}
      >
        <div>
          <CustomAlert
            onClose={() => setSuccessSnackbarEdit(false)}
            severity="success"
          >
            Usuario editado con éxito.
          </CustomAlert>
        </div>
      </Snackbar>
    </>
  );
};

export default UserTable;
