import AlarmIcon from "@mui/icons-material/Alarm";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import HistoryIcon from "@mui/icons-material/History";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PeopleIcon from "@mui/icons-material/People";
import SpeedIcon from "@mui/icons-material/Speed";
import TimelineIcon from "@mui/icons-material/Timeline";
import { List, ListItem, ListItemIcon, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { checkRoles } from "../../../../infrastructure/utils/CheckRoles";
import { StyledListItem, StyledTypography } from "./DrawerStyles";

interface IMenuOption {
  label: string;
  icon: JSX.Element;
  path: string;
  roles: string[];
}

const menuOptions: IMenuOption[] = [
  {
    label: "Lectura en oficinas",
    icon: <AlarmIcon />,
    path: "/selectContador",
    roles: ["OFICINA"],
  },
  {
    label: "Ordenación de rutas",
    icon: <ImportExportIcon />,
    path: "/selectRutaToSort",
    roles: ["ADMIN", "AFORADOR"],
  },
  {
    label: "Filtrado de registros",
    icon: <HistoryIcon />,
    path: "/historic",
    roles: ["ADMIN", "OFICINA"],
  },
  {
    label: "Contadores pendientes",
    icon: <AnnouncementIcon />,
    path: "/pending",
    roles: ["ADMIN", "AFORADOR"],
  },
  {
    label: "Estadísticas",
    icon: <EqualizerIcon />,
    path: "/stats",
    roles: ["ADMIN"],
  },
  {
    label: "Gestión de contadores",
    icon: <SpeedIcon />,
    path: "/contadorControl",
    roles: ["ADMIN", "OFICINA"],
  },
  {
    label: "Gestión de rutas",
    icon: <TimelineIcon />,
    path: "/rutaControl",
    roles: ["ADMIN", "AFORADOR"],
  },
  {
    label: "Gestión de usuarios",
    icon: <PeopleIcon />,
    path: "/userControl",
    roles: ["ADMIN"],
  },
];

export const MenuContent = () => {
  return (
    <List sx={{ padding: 0 }}>
      <StyledListItem>
        <StyledTypography>Opciones</StyledTypography>
      </StyledListItem>
      {menuOptions.map((option) => {
        if (checkRoles(option.roles)) {
          return (
            <ListItem
              key={option.label}
              component={Link}
              to={option.path}
              disableGutters
              sx={{ padding: "10px 16px", textDecoration: "none" }}
              style={{ color: "rgba(0, 0, 0, 0.87)" }}
            >
              {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
              <Typography>{option.label}</Typography>
            </ListItem>
          );
        }
        return null;
      })}
    </List>
  );
};
