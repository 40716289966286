import { useEffect, useRef } from "react";
import leaflet from "leaflet";

export default function Map({ initialLatitude, initialLongitude, onMarkerDragEnd }) {
  const mapRef = useRef();
  const markerRef = useRef();

  useEffect(() => {
    if (initialLatitude === undefined || initialLongitude === undefined) {
      console.error(
        "Debes proporcionar las coordenadas iniciales (latitude y longitude)."
      );
      return;
    }

    // Create new map
    mapRef.current = leaflet
      .map("map")
      .setView([initialLatitude, initialLongitude], 18);

    leaflet
      .tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 19,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      })
      .addTo(mapRef.current);

    // Add marker
    markerRef.current = leaflet
      .marker([initialLatitude, initialLongitude], {
        draggable: true,
        autoPan: true,
      })
      .on("dragend", function (event) {
        const latlng = event.target.getLatLng();
        if (onMarkerDragEnd) {
          onMarkerDragEnd(latlng.lat, latlng.lng); // Update coords 
        }
      })
      .addTo(mapRef.current);

    return () => {
      // Clena map when component removed
      if (mapRef.current) {
        mapRef.current.remove();
      }
    };

  }, [initialLatitude, initialLongitude, onMarkerDragEnd]);
  
  return <div id="map" style={{ height: "400px", width: "100%" }}></div>;
}
