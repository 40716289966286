import { FC, useState } from "react";
import { observer } from "mobx-react";
import CounterStats from "./components/CounterStats";
import { Contador } from "../../client";
import { Button, Card, FormControl, Grid, TextField } from "@mui/material";
import { ContadorViewModel } from "../../viewmodels/ContadorViewModel";
import { HistoricoLecturasViewModel } from "../../viewmodels/HistoricoLecturasViewModel";
import { StyledBreadcrumbs, StyledEqualizerIcon, StyledTypography } from "../../components/CustomBreadCrumbs";

const StatsView: FC = () => {
  const [contador] = useState<Contador>(
    ContadorViewModel.getInstance().currentContador!
  );
  const [referencia, setReferencia] = useState("");

  const handleSubmitButton = async () => {
    await HistoricoLecturasViewModel.getInstance().getHistoricoLecturasByReference(referencia);
  };

  return (
    <>
      <StyledBreadcrumbs aria-label="breadcrumb">
        <StyledTypography color="textPrimary">
          <StyledEqualizerIcon />
          Estadísticas de contadores y lecturas
        </StyledTypography>
      </StyledBreadcrumbs>
      <Grid container direction="row">
        <Grid item container xs={6} alignItems="center" justifyContent="center">
          <Card style={{ width: "90%" }}>
            <Grid style={{ margin: 25 }}>
              <div
                style={{
                  minHeight: 80,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <FormControl variant="outlined" style={{ marginTop: 0, marginBottom: 0, width: "80%" }}>
                  <TextField color="error" label="Referencia" variant="outlined" value={referencia} onChange={(event) => setReferencia(event.target.value)} />
                </FormControl>
                <Button
                  size="medium"
                  variant="outlined"
                  color="error"
                  onClick={handleSubmitButton}
                  style={{ width: "20%", height: 55 }}
                >
                  Buscar
                </Button>
              </div>
              <CounterStats
                contador={contador}
                data={
                  HistoricoLecturasViewModel.getInstance().historicoLecturas
                }
              />
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default observer(StatsView);
