import { DeleteResponse, Ruta } from "../../client";
import { RutaApi } from "../../client/apis/RutaApi";
import { Api, ApiClient } from "./ApiClient";

export default class RutaRepository {
  private static getRutaApiClient() {
    return ApiClient.getClient(Api.RutaApi) as RutaApi;
  }

  static async getRutas(): Promise<Ruta[]> {
    const rutaApi = this.getRutaApiClient();
    const rutas: Ruta[] = await rutaApi.getAllRutas();

    return rutas;
  }

  static async getRutaById(id: string): Promise<Ruta> {
    const rutaApi = this.getRutaApiClient();
    const ruta: Ruta = await rutaApi.getRutaById(id);

    return ruta;
  }

  static async createRuta(newRuta: Ruta): Promise<Ruta> {
    const rutaApi = this.getRutaApiClient();
    const ruta: Ruta = await rutaApi.createRuta(newRuta);

    return ruta;
  }

  static async updateRutaById(id: string, updateRuta: Ruta): Promise<Ruta> {
    const rutaApi = this.getRutaApiClient();
    const ruta: Ruta = await rutaApi.updateRutaById(id, updateRuta);

    return ruta;
  }

  static async deleteRutaById(id: string): Promise<DeleteResponse> {
    const rutaApi = this.getRutaApiClient();
    let response: DeleteResponse = await rutaApi.deleteRutaById(id);

    return response;
  }
}
