import { SxProps, Theme } from "@mui/material";
import OwnColors from "../../constants/OwnColors";

export const datePickerLabelStyle: SxProps<Theme> = {
  "&:hover .MuiInputLabel-root": {
    color: OwnColors.LECOCorporate,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: OwnColors.LECOCorporate,
  },
};
