import { observer } from "mobx-react";
import Selectors from "./components/Selectors";
import { StyledDivItem } from "./styles";
import { ContadorViewModel } from "../../../viewmodels/ContadorViewModel";
import { InfoContAdicionalViewModel } from "../../../viewmodels/InfoContAdicionalViewModel";
import { Contador } from "../../../client";
import { StyledBreadcrumbs, StyledSearchIcon, StyledTypography } from "../../../components/CustomBreadCrumbs";
import { FC, useEffect } from "react";

const MeterSelectorView: FC = () => {
  useEffect(() => {
    ContadorViewModel.getInstance().getContadores();
    ContadorViewModel.getInstance().setCurrentContador();
    InfoContAdicionalViewModel.getInstance().setCurrentInfoContAdicional();
  }, []);

  const contadores = ContadorViewModel.getInstance().contadores ?? [];

  const itemsNoLogicDeleted = (contadores: Contador[]) =>
    contadores.filter((item) => item.estado !== "ELIMINADO");

  return (
    <StyledDivItem>
      <StyledBreadcrumbs aria-label="breadcrumb">
        <StyledTypography color="textPrimary">
          <StyledSearchIcon />
          Selección de contador
        </StyledTypography>
      </StyledBreadcrumbs>
      <Selectors contadores={itemsNoLogicDeleted(contadores)} />
    </StyledDivItem>
  );
};

export default observer(MeterSelectorView);
