import { makeAutoObservable } from "mobx";
import { CounterHistoryFilter, HistoricoLecturas } from "../client";
import HistoricoLecturasRepository from "../data/repositories/HistoricoLecturasRepository";


export class HistoricoLecturasViewModel {
    private static instance: HistoricoLecturasViewModel | null = null;


    static getInstance(): HistoricoLecturasViewModel {
        if (!HistoricoLecturasViewModel.instance) {
            HistoricoLecturasViewModel.instance = new HistoricoLecturasViewModel();
        }
        return HistoricoLecturasViewModel.instance;
    }
    currentHistoricoLecturas: HistoricoLecturas;
    historicoLecturas: HistoricoLecturas[] | undefined = undefined;

    private constructor() {
        makeAutoObservable(this);
        HistoricoLecturasRepository.getAllHistoricoLecturas().then(
            (res) => {
                this.setHistoricoLecturas(res.content);
            }
        );
        this.currentHistoricoLecturas = {
            idHistoricolecturas: undefined,
            fechaLectura: undefined,
            lecturaContador: undefined,
            fechaGrabacionBBDD: undefined,
            tipoLectura: undefined,
            procesado: undefined,
            codigoIncidencia: undefined,
            fechaFacturacion: undefined,
        }
    }

    async createHistoricoLecturas(historicoLecturas: HistoricoLecturas) {
        return HistoricoLecturasRepository.createHistoricoLecturas(historicoLecturas)
    }

    async getAllHistoricoLecturas() {
        const historicoLecturasPaged = await HistoricoLecturasRepository.getAllHistoricoLecturas();
        this.setHistoricoLecturas(historicoLecturasPaged.content);
    }

    async getHistoricoLecturasById(id: number) {
        const newReadings = await HistoricoLecturasRepository.getHistoricoLecturasById(id);
        this.setCurrentHistoricoLecturas(newReadings);
    }

    async getHistoricoLecturasByReference(referencia: string) {
        const newReadings = await HistoricoLecturasRepository.getHistoricoLecturasByReference(referencia);
        this.setHistoricoLecturas(newReadings);
        return newReadings;
    }

    async updateHistoricoLecturasById(id: number, historicoLecturas: HistoricoLecturas) {
        return HistoricoLecturasRepository.updateHistoricoLecturasById(id, historicoLecturas);
    }

    async deleteHistoricoLecturasById(id: number) {
        await HistoricoLecturasRepository.deleteHistoricolecturasById(id);
    }

    public setCurrentHistoricoLecturas(historicoLecturas: HistoricoLecturas) {
        this.currentHistoricoLecturas = historicoLecturas;
    }

    public setCurrentHistoricoLecturasValue(key: keyof HistoricoLecturas, value: any) {
        this.currentHistoricoLecturas && (this.currentHistoricoLecturas[key] = value);
    }

    public setHistoricoLecturas(historicoLecturas: HistoricoLecturas[] | undefined) {
        this.historicoLecturas = historicoLecturas;
    }

    public getAllCounterReadingHistoryByFilter(
        offset: number,
        limit: number,
        counterHistoryFilter: CounterHistoryFilter,
        field?: string,
        sort?: string
    ) {
        return HistoricoLecturasRepository.getAllCounterReadingHistoryByFilter(
            counterHistoryFilter,
            field,
            sort,
            offset,
            limit
        );
    }

    public getAllPendingCounters(
        offset: number,
        limit: number,
        counterHistoryFilter: CounterHistoryFilter,
        field?: string,
        sort?: string
    ) {
        return HistoricoLecturasRepository.getAllPendingCounters(
            counterHistoryFilter,
            field,
            sort,
            offset,
            limit
        );
    }

}