import { AccountCircle } from "@mui/icons-material";
import { StyledRightSection } from "../../ToolbarStyles";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu/Menu";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useState } from "react";

const menuId = "primary-search-account-menu";

export interface RightSectionProps {
  loggedUser: boolean;
  logout: Function;
}

export const RightSection = (props: RightSectionProps) => {
  const navigate = useNavigate();

  const handleAccountIconClick = (event: any) => {
    if (props.loggedUser) {
      window.scroll(0, 0);
      setAnchorEl(event.currentTarget);
    } else {
      navigate("/login");
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => setAnchorEl(null);

  return (
    <StyledRightSection>
      {props.loggedUser ?
        <IconButton
          edge="end"
          aria-label="account of current user"
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={handleAccountIconClick}
          color="inherit"
        >
          <ExitToAppIcon color="inherit" />
        </IconButton>
        :
        <IconButton
          edge="end"
          aria-label="account of current user"
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={handleAccountIconClick}
          color="inherit"
        >
          <AccountCircle color="inherit" />
        </IconButton>
      }

      <Menu
        id="primary-search-account-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            props.logout();
            setAnchorEl(null);
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </StyledRightSection>
  );
};
