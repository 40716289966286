import {
  GridCellParams,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import {
  HistoricoLecturas,
  HistoricoLecturasTipoLecturaEnum,
} from "../../../../client";
import { checkRoles } from "../../../../infrastructure/utils/CheckRoles";
import { getDateString, getDateTimeString } from "../../../../utils/DateUtils";

export const useTableColumns = (items: HistoricoLecturas[]): GridColDef[] => [
  {
    field: "lecturaContador",
    headerClassName: "theme--header",
    headerName: "Lectura",
    type: "string",
    minWidth: 120,
    flex: 1,
    align: "center",
    headerAlign: "center",
    editable: false,
    description: "Lectura de contador",
    renderCell: (params: GridCellParams) => {
      let bold =
        params.row.tipoLectura === HistoricoLecturasTipoLecturaEnum.Facturada;
      return (
        <p style={{ fontWeight: bold ? "bold" : "normal" }}>
          {params.row.lecturaContador}
        </p>
      );
    },
  },
  {
    field: "fechaLectura",
    headerClassName: "theme--header",
    headerName: "Fecha lec.",
    type: "string",
    align: "center",
    editable: false,
    headerAlign: "center",
    minWidth: 120,
    flex: 1,
    description: "Fecha de lectura",
    renderCell: (params: GridCellParams) => {
      let bold =
        params.row.tipoLectura === HistoricoLecturasTipoLecturaEnum.Facturada;
      return (
        <p style={{ fontWeight: bold ? "bold" : "normal" }}>
          {getDateString(
            params.row.fechaLectura
              ? new Date(params.row.fechaLectura)
              : undefined
          )}
        </p>
      );
    },
  },
  {
    field: "tipoLectura",
    headerClassName: "theme--header",
    headerName: "Tipo lectura",
    type: "string",
    minWidth: 120,
    flex: 1,
    align: "center",
    headerAlign: "center",
    editable: false,
    description: "Tipo de lectura",
    renderCell: (params: GridCellParams) => {
      let bold =
        params.row.tipoLectura === HistoricoLecturasTipoLecturaEnum.Facturada;
      return (
        <p style={{ fontWeight: bold ? "bold" : "normal" }}>
          {params.row.tipoLectura}
        </p>
      );
    },
  },
  {
    field: "consumo",
    headerClassName: "theme--header",
    headerName: "Consumo",
    type: "string",
    minWidth: 90,
    flex: 1,
    align: "center",
    headerAlign: "center",
    editable: isAdminOfi(),
    description: "Consumo",
    renderCell: (params: GridCellParams) => {
      let bold =
        params.row.tipoLectura === HistoricoLecturasTipoLecturaEnum.Facturada;
      return (
        <p style={{ fontWeight: bold ? "bold" : "normal" }}>
          {params.row.consumo}
        </p>
      );
    },
  },
  {
    field: "fechaFacturacion",
    headerClassName: "theme--header",
    headerName: "Fecha fact.",
    type: "date",
    align: "center",
    editable: false,
    headerAlign: "center",
    minWidth: 120,
    flex: 1,
    description: "Fecha de facturación",
    renderCell: (params: GridCellParams) => {
      let bold =
        params.row.tipoLectura === HistoricoLecturasTipoLecturaEnum.Facturada;
      return (
        <p style={{ fontWeight: bold ? "bold" : "normal" }}>
          {getDateString(
            params.row.fechaFacturacion
              ? new Date(params.row.fechaFacturacion)
              : undefined
          )}
        </p>
      );
    },
  },
  {
    field: "codigoIncidencia",
    headerClassName: "theme--header",
    headerName: "Código",
    editable: false,
    type: "string",
    minWidth: 150,
    flex: 1,
    align: "center",
    headerAlign: "center",
    description: "Código de incidencia",
    renderCell: (params: GridCellParams) => {
      let bold =
        params.row.tipoLectura === HistoricoLecturasTipoLecturaEnum.Facturada;
      return (
        <p style={{ fontWeight: bold ? "bold" : "normal" }}>
          {params.row.codigoIncidencia}
        </p>
      );
    },
  },
  {
    field: "fechaGrabacionBBDD",
    headerClassName: "theme--header",
    editable: false,
    headerName: "Fecha grab.",
    type: "string",
    align: "center",
    headerAlign: "center",
    minWidth: 170,
    flex: 1,
    description: "Fecha de grabación BBDD",
    renderCell: (params: GridCellParams) => {
      let bold =
        params.row.tipoLectura === HistoricoLecturasTipoLecturaEnum.Facturada;
      return (
        <p style={{ fontWeight: bold ? "bold" : "normal" }}>
          {getDateTimeString(
            params.row.fechaGrabacionBBDD
              ? new Date(params.row.fechaGrabacionBBDD)
              : undefined
          )}
        </p>
      );
    },
  },
  {
    field: "user",
    headerClassName: "theme--header",
    headerName: "Usuario",
    type: "string",
    editable: false,
    minWidth: 130,
    flex: 1,
    align: "center",
    headerAlign: "center",
    description: "Usuario",
    renderCell: (params: GridCellParams) => {
      let bold =
        params.row.tipoLectura === HistoricoLecturasTipoLecturaEnum.Facturada;
      return (
        <p style={{ fontWeight: bold ? "bold" : "normal" }}>
          {params.row.user}
        </p>
      );
    },
  },
];

export const useTableCustomToolbar = (meterReference: string) => () =>
  (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          delimiter: ";",
          fileName: `Lecturas_${meterReference}`,
          fields: [
            "idHistoricolecturas",
            "referencia",
            "lecturaContador",
            "fechaLectura",
            "fechaFacturacion",
            "fechaGrabacionBBDD",
            "tipoLectura",
            "procesado",
            "codigoIncidencia",
            "user",
            "visible",
          ],
        }}
      />
    </GridToolbarContainer>
  );

const isAdminOfi = () => checkRoles(["ADMIN", "OFICINA"]);
