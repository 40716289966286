import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Card, CardContent, CardHeader, Button, Snackbar, Checkbox, FormControlLabel, FormGroup, FormLabel, styled } from "@mui/material";
import TimelineIcon from '@mui/icons-material/Timeline';
import { FormEvent, useState } from "react";
import { Role, User } from "../../../client";
import OwnColors from "../../../constants/OwnColors";
import { UserViewModel } from "../../../viewmodels/UserViewModel";
import { CustomAlert } from "../../../components/CustomAlert";

const StyledCardRoot = styled(Card)(({ theme }) => ({
    minWidth: 275,
}));

const StyledForm = styled('form')(({ theme }) => ({
    "& .MuiInputLabel-root.Mui-focused": {
        color: OwnColors.LECOCorporate
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: OwnColors.LECOCorporate
    }
}));

export interface IUserForm {
    onClose: Function;
    editUser?: User;
}

const UserForm = (params: Readonly<IUserForm>) => {

    const hasRole = (roles: Role[], role: string) => {
        return roles.find(i => i.role === role) !== undefined;
    }

    let user: User | undefined

    const [editUser,] = useState(params.editUser)

    const [idUser,] = useState(editUser?.idUser ?? 0)
    const [email, setEmail] = useState(editUser?.email ?? "")
    const [name, setName] = useState(editUser?.name ?? "")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [roles,] = useState<Role[]>(editUser?.roles ?? [])

    const [hasAdmin, setHasAdmin] = useState(editUser?.roles ? hasRole(editUser.roles, "ADMIN") : false)
    const [hasOficina, setHasOficina] = useState(editUser?.roles ? hasRole(editUser.roles, "OFICINA") : false)
    const [hasAforador, setHasAforador] = useState(editUser?.roles ? hasRole(editUser.roles, "AFORADOR") : false)

    const [processed, setProcessed] = useState(editUser?.processed);

    const [errorSnackbarCreate, setErrorSnackbarCreate] = useState(false)
    const [errorSnackbarEdit, setErrorSnackbarEdit] = useState(false)
    const [errorSnackbarPassword, setErrorSnackbarPassword] = useState(false)

    const getProcessedValue = () => {
        if (processed === undefined) return undefined;
        if (processed) return "true"
        else return "false";
    }

    const submit = async (e: FormEvent) => {
        e.preventDefault();

        if (params.editUser)
            await submitEdit()
        else
            await submitCreate()
    }

    const fixEmail = (email: string) => email.split(" ").join("");

    const submitCreate = async () => {
        if (password !== confirmPassword) {
            setErrorSnackbarPassword(true)
        } else {
            try {
                user = UserViewModel.getInstance().currentUser
                user!.idUser = undefined
                user!.email = fixEmail(email)
                user!.name = name
                user!.password = password
                user!.processed = processed ?? false

                if (hasAdmin) {
                    const adminRole: Role = { idRole: 1, role: "ADMIN" };
                    roles.push(adminRole)
                }

                if (hasOficina) {
                    const oficinaRole: Role = { idRole: 2, role: "OFICINA" };
                    roles.push(oficinaRole)
                }

                if (hasAforador) {
                    const aforadorRole: Role = { idRole: 3, role: "AFORADOR" };
                    roles.push(aforadorRole)
                }

                user!.roles = roles

                //Rellenar objetos con los parametros de los formularios
                user = await UserViewModel.getInstance().createUser(user!)
                params.onClose(user)
            }
            catch (error) {
                setErrorSnackbarCreate(true)
            }
        }
    }

    const submitEdit = async () => {
        if (password !== confirmPassword) {
            setErrorSnackbarPassword(true)
        } else {
            try {
                user = UserViewModel.getInstance().currentUser
                user!.idUser = idUser
                user!.email = fixEmail(email)
                user!.name = name
                if (password !== "") {
                    user!.password = password
                }
                user!.processed = processed ?? false

                const adminRole: Role = { idRole: 1, role: "ADMIN" };
                const oficinaRole: Role = { idRole: 2, role: "OFICINA" };
                const aforadorRole: Role = { idRole: 3, role: "AFORADOR" };

                if (hasAdmin) {
                    if (!hasRole(roles, "ADMIN")) {
                        roles.push(adminRole)
                    }
                } else {
                    let roleIndex = roles.indexOf(adminRole);
                    if (roleIndex !== -1) {
                        roles.splice(roleIndex, 1)
                    }
                }

                if (hasOficina) {
                    if (!hasRole(roles, "OFICINA")) {
                        roles.push(oficinaRole)
                    }
                } else {
                    let roleIndex = roles.indexOf(oficinaRole);
                    if (roleIndex !== -1) {
                        roles.splice(roleIndex, 1)
                    }
                }

                if (hasAforador) {
                    if (!hasRole(roles, "AFORADOR")) {
                        roles.push(aforadorRole)
                    }
                } else {
                    let roleIndex = roles.indexOf(aforadorRole);
                    if (roleIndex !== -1) {
                        roles.splice(roleIndex, 1)
                    }
                }

                user!.roles = roles
                //Rellenar objetos con los parametros de los formularios
                await UserViewModel.getInstance().updateUserById(idUser, user!)
                params.onClose(user)
            }
            catch (error) {
                setErrorSnackbarEdit(true)
            }
        }
    }

    return (
        (<Grid item xs={12}>
            <StyledForm onSubmit={submit}> {/*El campo "action" queda invalidado pasándole como parámetro una funcion de javascript vacía*/}
                <Grid
                    item
                    container
                    spacing={6}
                    direction="row"
                    sx={{
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                    <Grid item container xs={12} spacing={3} style={{ "marginBottom": "2vw", "marginTop": "2vw" }} sx={{
                        justifyContent: "center"
                    }}>
                        <Grid item xs={10}>
                            <StyledCardRoot>
                                <CardHeader
                                    title={
                                        <Grid
                                            item
                                            container
                                            direction="row"
                                            style={{ "marginBottom": "-3vw", "marginTop": "-1vw" }}
                                            sx={{
                                                alignItems: "center",
                                                justifyContent: "flex-start"
                                            }}>
                                            <TimelineIcon />&nbsp;
                                            <p>Información del usuario</p>
                                        </Grid>
                                    }
                                >
                                </CardHeader>
                                <CardContent>
                                    <TextField
                                        color="error"
                                        variant="outlined"
                                        margin="dense"
                                        value={email}
                                        onChange={(event) => setEmail(event.target.value)}
                                        label="Email"
                                        type="text"
                                        fullWidth
                                        required
                                    />
                                    <TextField
                                        color="error"
                                        variant="outlined"
                                        margin="dense"
                                        value={name}
                                        onChange={(event) => setName(event.target.value)}
                                        label="Nombre"
                                        type="text"
                                        fullWidth
                                        required
                                    />
                                    <TextField
                                        color="error"
                                        variant="outlined"
                                        margin="dense"
                                        value={password}
                                        onChange={(event) => setPassword(event.target.value)}
                                        label="Contraseña"
                                        type="password"
                                        required={!editUser}
                                        fullWidth
                                    />
                                    <TextField
                                        color="error"
                                        variant="outlined"
                                        margin="dense"
                                        value={confirmPassword}
                                        onChange={(event) => setConfirmPassword(event.target.value)}
                                        label="Confirmar contraseña"
                                        type="password"
                                        required={!editUser}
                                        fullWidth
                                    />
                                    <FormControl variant="outlined" fullWidth style={{ "marginTop": 10, "marginBottom": 10 }}>
                                        <InputLabel color="error">Procesado *</InputLabel>
                                        <Select
                                            defaultValue={""}
                                            color="error"
                                            variant="outlined"
                                            value={getProcessedValue() ?? ' '}
                                            onChange={(event) => setProcessed(event.target.value === "true")}
                                            label="Procesado"
                                            fullWidth
                                            required
                                        >
                                            <MenuItem value="true">Si</MenuItem>
                                            <MenuItem value="false">No</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {((editUser && editUser.idUser !== 1) || !editUser) && <FormControl variant="outlined" fullWidth style={{ "marginTop": 10, "marginBottom": 10 }}>
                                        <FormLabel color="error">Roles</FormLabel>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox checked={hasAdmin} onChange={(_event, value) => setHasAdmin(value)} name="ADMIN" color="error" />}
                                                label="ADMIN"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={hasOficina} onChange={(_event, value) => setHasOficina(value)} name="OFICINA" color="error" />}
                                                label="OFICINA"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={hasAforador} onChange={(_event, value) => setHasAforador(value)} name="AFORADOR" color="error" />}
                                                label="AFORADOR"
                                            />
                                        </FormGroup>
                                    </FormControl>}
                                </CardContent>
                            </StyledCardRoot>
                        </Grid>
                        <Grid
                            item
                            container
                            direction="row"
                            xs={10}
                            sx={{
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                            <Grid
                                item
                                container
                                xs={6}
                                sx={{
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                <Button variant="contained" color="error" onClick={() => params.onClose()}>
                                    Cancelar
                                </Button>
                            </Grid>
                            <Grid
                                item
                                container
                                xs={6}
                                sx={{
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                <Button variant="contained" color="primary" type="submit">
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </StyledForm>
            <Snackbar open={errorSnackbarCreate} autoHideDuration={6000} onClose={() => setErrorSnackbarCreate(false)}>
                <div>
                    <CustomAlert onClose={() => setErrorSnackbarCreate(false)} severity="error">
                        Algo ha ido mal. El usuario no ha sido creado.
                    </CustomAlert>
                </div>
            </Snackbar >
            <Snackbar open={errorSnackbarEdit} autoHideDuration={6000} onClose={() => setErrorSnackbarEdit(false)}>
                <div>
                    <CustomAlert onClose={() => setErrorSnackbarEdit(false)} severity="error">
                        Algo ha ido mal. El usuario no ha sido editado.
                    </CustomAlert>
                </div>
            </Snackbar>
            <Snackbar open={errorSnackbarPassword} autoHideDuration={4000} onClose={() => setErrorSnackbarPassword(false)}>
                <div>
                    <CustomAlert onClose={() => setErrorSnackbarPassword(false)} severity="error">
                        Error: Las contraseñas no coinciden
                    </CustomAlert>
                </div>
            </Snackbar>
        </Grid >)
    );
}

export default UserForm;
