import { Button, styled } from "@mui/material";
import OwnColors from "../../constants/OwnColors";

const StyledButtonEdit = styled(Button)(() => ({
  color: "white",
  backgroundColor: "CornflowerBlue",
  "&:hover": {
    backgroundColor: "darkblue",
  },
}));

const StyledButtonDelete = styled(Button)(() => ({
    color: "white",
    backgroundColor: "tomato",
    "&:hover": {
      backgroundColor: "darkred",
    },
}));

const StyledButtonCreate = styled(Button)(({ theme }) => ({
    fontSize: "14px",
    margin: theme.spacing(0.5, 1, 0.9, 0),
    float: "right",
    color: "white",
    backgroundColor: "seagreen",
    "&:hover": {
      backgroundColor: "darkgreen",
    },
}));

const StyledDiv = styled('div')(({ theme }) => ({
    width: "auto",
    margin: theme.spacing(0, 10, 0, 10),
    "& .theme--header": {
      backgroundColor: OwnColors.LECOlightRed,
      color: "black",
      justify: "center",
    },
}));

export {
    StyledButtonDelete,
    StyledButtonEdit,
    StyledButtonCreate,
    StyledDiv,
    
}
