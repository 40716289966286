import { FC, Fragment, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { ContadorViewModel } from "../../../viewmodels/ContadorViewModel";
import { HistoricoLecturasViewModel } from "../../../viewmodels/HistoricoLecturasViewModel";
import {
  HistoricoLecturas,
  HistoricoLecturasTipoLecturaEnum,
  InfoContAdicional,
} from "../../../client";
import { Snackbar, Alert, AlertColor } from "@mui/material";
import SessionStorage from "../../../data/sessionStorage/SessionStorage";
import { MeterReadingSection } from "./components/meter-reading-section";
import { MeterInfoSection } from "./components/meter-info-section";
import { StyledGridPaper, StyledGridReadOnlyCard1 } from "./styles";
import { UserViewModel } from "../../../viewmodels/UserViewModel";
import { ReadingHistorySection } from "./components/reading-history-section";
import { useNavigate } from "react-router-dom";
import { AdditionalInfoSection } from "./components/additional-info-section";
import { InfoContAdicionalViewModel } from "../../../viewmodels/InfoContAdicionalViewModel";
import { sortHistoryItemsByRecordDateDesc } from "../../../utils/reading-helper";
import { StyledAccessAlarmIcon, StyledBreadcrumbs, StyledSearchIcon, StyledTypography } from "../../../components/CustomBreadCrumbs";

const MeterReadingView: FC<{}> = () => {
  const navigate = useNavigate();

  const [historyItems, setHistoryItems] = useState<HistoricoLecturas[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [lastHistory, setLastHistory] = useState<HistoricoLecturas>();
  const [lastCharged, setLastCharged] = useState<HistoricoLecturas>();
  const [additionalInfo, setAdditionalInfo] = useState<InfoContAdicional>({});
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("success");

  useEffect(() => {
    (async () => {
      const currentContador = ContadorViewModel.getInstance().currentContador;

      if (
        currentContador?.referencia !== "" &&
        currentContador?.referencia !== undefined
      ) {
        const items: HistoricoLecturas[] =
          await HistoricoLecturasViewModel.getInstance().getHistoricoLecturasByReference(
            currentContador?.referencia ?? ""
          );

        await InfoContAdicionalViewModel.getInstance().getInfoContAdicionalByReference(
          currentContador.referencia ?? ""
        );
        setAdditionalInfo(
          InfoContAdicionalViewModel.getInstance().currentInfoContAdicional ??
          {}
        );
        filterAndSortHistories(items);
      } else navigate("/SelectContador");
    })();
  }, [navigate]);

  const filterAndSortHistories = (histories: HistoricoLecturas[]) => {
    const itemsByContadorSortByDate = sortHistoryItemsByRecordDateDesc(
      histories
    ).filter(
      (read) => read.tipoLectura !== HistoricoLecturasTipoLecturaEnum.Eliminada
    );

    let last = itemsByContadorSortByDate.filter(
      (reading: HistoricoLecturas) =>
        reading.codigoIncidencia !== "Aviso lectura" &&
        reading.tipoLectura !== HistoricoLecturasTipoLecturaEnum.Facturada
    )[0];

    let charged = itemsByContadorSortByDate.filter(
      (reading: HistoricoLecturas) =>
        reading.tipoLectura === HistoricoLecturasTipoLecturaEnum.Facturada
    )[0];

    setHistoryItems(itemsByContadorSortByDate);
    setLastCharged(charged);
    setLastHistory(last);
  };

  const handleOpenSnackbar = (severity: AlertColor, message: string) => {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setSnackbarOpen(true);
  };

  const updateAdditionalInfo = async () => {
    InfoContAdicionalViewModel.getInstance()
      .updateInfoContAdicionalByReference(
        ContadorViewModel.getInstance().currentContador?.referencia ?? "",
        additionalInfo
      )
      .then((value: InfoContAdicional) => {
        setAdditionalInfo(value);
        handleOpenSnackbar("success", "Información actualizada correctamente");
      })
      .catch(() =>
        handleOpenSnackbar(
          "error",
          "Ocurrió un error al actualizar la información"
        )
      );
  };

  const handleSaveReading = async (value: HistoricoLecturas) => {
    SessionStorage.setSelectedDate(value.fechaLectura?.toString() ?? "");
    SessionStorage.setSelectedReadingType(value.tipoLectura?.toString() ?? "");
    SessionStorage.setSelectedIncidentCode(value.codigoIncidencia ?? "");

    await HistoricoLecturasViewModel.getInstance()
      .createHistoricoLecturas(value)
      .then(() =>
        handleOpenSnackbar("success", "Lectura guardada correctamente")
      )
      .catch(() =>
        handleOpenSnackbar("error", "Ocurrió un error al guardar la lectura")
      );

    await HistoricoLecturasViewModel.getInstance()
      .getHistoricoLecturasByReference(
        ContadorViewModel.getInstance().currentContador?.referencia ?? ""
      )
      .then((histories: HistoricoLecturas[]) =>
        filterAndSortHistories([...histories])
      )
      .catch(() =>
        handleOpenSnackbar("error", "Ocurrió un error al recoger las lecturas")
      );
  };

  const handleUpdateRows = async (
    tableHistories: HistoricoLecturas[],
    successMessage: string,
    errorMessage: string
  ) => {
    if (tableHistories.length !== 0) {
      await Promise.all(
        tableHistories.map(async (history: HistoricoLecturas) =>
          HistoricoLecturasViewModel.getInstance().updateHistoricoLecturasById(
            history.idHistoricolecturas!,
            history
          )
        )
      )
        .then(async () => {
          await HistoricoLecturasViewModel.getInstance()
            .getHistoricoLecturasByReference(
              ContadorViewModel.getInstance().currentContador?.referencia ?? ""
            )
            .then((histories: HistoricoLecturas[]) =>
              filterAndSortHistories([...histories])
            );
          handleOpenSnackbar("success", successMessage);
        })
        .catch(() => handleOpenSnackbar("error", errorMessage));
    } else handleOpenSnackbar("info", "Sin cambios que guardar");
  };

  return (
    <Fragment>
      <StyledBreadcrumbs aria-label="breadcrumb">
        <StyledTypography color="inherit">
          <StyledSearchIcon />
          Selección de contador
        </StyledTypography>
        <StyledTypography color="textPrimary">
          <StyledAccessAlarmIcon />
          Nueva lectura
        </StyledTypography>
      </StyledBreadcrumbs>

      <StyledGridPaper item xs={12}>
        <StyledGridReadOnlyCard1 container item xs={12} justifyContent="center">
          <MeterInfoSection
            props={{
              currentMeter: ContadorViewModel.getInstance().currentContador,
            }}
          />
          <MeterReadingSection
            props={{
              savingFunction: handleSaveReading,
              setSnackbarOpen: handleOpenSnackbar,
              snackbarOpen: snackbarOpen,
              lastHistory: lastHistory,
              currentMeter: ContadorViewModel.getInstance().currentContador,
              currentUser: UserViewModel.getInstance().loggedUser,
              lastCharged: lastCharged,
            }}
          />
        </StyledGridReadOnlyCard1>

        <StyledGridReadOnlyCard1 container item xs={12} justifyContent="center">
          <AdditionalInfoSection
            props={{
              additionalInfo: additionalInfo,
              currentMeter: ContadorViewModel.getInstance().currentContador,
              setAdditionalInfo: setAdditionalInfo,
              updateAdditionalInfo: updateAdditionalInfo,
            }}
          />
        </StyledGridReadOnlyCard1>

        <StyledGridReadOnlyCard1 container item xs={12} justifyContent="center">
          <ReadingHistorySection
            props={{
              histories: historyItems,
              updateEditedRows: handleUpdateRows,
              loggedUser: UserViewModel.getInstance().loggedUser,
              meter: ContadorViewModel.getInstance().currentContador,
            }}
          />
        </StyledGridReadOnlyCard1>
      </StyledGridPaper>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <div>
          <Alert severity={alertSeverity}>{alertMessage}</Alert>
        </div>
      </Snackbar>
    </Fragment>
  );
};

export default observer(MeterReadingView);
