import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import {
  FC,
  Fragment,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import HistoryIcon from "@mui/icons-material/History";
import {
  Contador,
  HistoricoLecturas,
  HistoricoLecturasTipoLecturaEnum,
  User,
} from "../../../../client";
import {
  StyledButtonDeleteHis,
  StyledButtonSaveHis,
  StyledGridCard5,
  StyledGridCardContent5,
} from "../styles";
import { checkRoles } from "../../../../infrastructure/utils/CheckRoles";
import {
  DataGrid,
  GridCallbackDetails,
  GridEditCellProps,
  GridRowId,
  GridSelectionModel,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { useTableColumns, useTableCustomToolbar } from "./use-table-components";
import { sortHistoryItemsByRecordDateDesc } from "../../../../utils/reading-helper";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from "../../../../constants/TableConstants";
import { getDefaultSlotProps } from "../../../../components/datepicker/datePickerUtils";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

type TReadingHistorySection = {
  histories?: HistoricoLecturas[];
  updateEditedRows: (
    histories: HistoricoLecturas[],
    successMessage: string,
    errorMessage: string
  ) => void;
  loggedUser?: User;
  meter?: Contador;
};

type TDialogActions = {
  fn: VoidFunction;
};

export const ReadingHistorySection: FC<{ props: TReadingHistorySection }> = ({
  props,
}) => {
  const customToolbar = useTableCustomToolbar(props.meter?.referencia ?? "");
  const tableColumns = useTableColumns(props.histories ?? []);

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [editedConsumptionRows, setEditedConsumptionRows] = useState<
    HistoricoLecturas[]
  >([]);
  const [selectedDateInit, setSelectedDateInit] = useState<Date | null>(null);
  const [selectedDateEnd, setSelectedDateEnd] = useState<Date | null>(null);
  const [tableHistories, setTableHistories] = useState<HistoricoLecturas[]>([]);
  const [dialogActions, setDialogActions] = useState<TDialogActions>({
    fn: () => {},
  });
  const [dialogTitle, setDialogTitle] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [pageSize, setPageSize] = useState(30);

  const [openDatePicker, setOpenDatePicker] = useState([false, false]);

  const pickerRefs = [
    useRef() as MutableRefObject<HTMLInputElement>,
    useRef() as MutableRefObject<HTMLInputElement>,
  ];

  useEffect(() => {
    setTableHistories([
      ...sortHistoryItemsByRecordDateDesc(props.histories ?? []),
    ]);
  }, [props.histories]);

  const handleDateChangeInit = (date) => {
    setSelectedDateInit(date);
    applyFilters(date, selectedDateEnd);
  };

  const handleDateChangeEnd = (date) => {
    setSelectedDateEnd(date);
    applyFilters(selectedDateInit, date);
  };

  const applyFilters = (dateInit: Date | null, dateEnd: Date | null) => {
    const filteredHistoric = props.histories?.filter((h: HistoricoLecturas) => {
      if (dateInit !== null && dateEnd !== null) {
        return (
          h.fechaLectura! >= dateInit.getTime() &&
          h.fechaLectura! <= dateEnd.getTime()
        );
      } else if (dateInit !== null && dateEnd === null) {
        return h.fechaLectura! >= dateInit.getTime();
      } else if (dateInit === null && dateEnd !== null) {
        return h.fechaLectura! <= dateEnd.getTime();
      } else return true;
    });

    setTableHistories(
      sortHistoryItemsByRecordDateDesc([...(filteredHistoric ?? [])])
    );
  };

  const deleteHistory = () => {
    let historiesToDelete: HistoricoLecturas[] = [];

    selectionModel.forEach((selection: GridRowId) => {
      let id: number = +selection.valueOf();
      let history = tableHistories.find(
        (value: HistoricoLecturas) => value.idHistoricolecturas === id
      );

      if (history) {
        history.tipoLectura = HistoricoLecturasTipoLecturaEnum.Eliminada;
        history.user = props.loggedUser?.name ?? "";
        historiesToDelete.push(history);
      }
    });

    setSelectionModel([]);
    props.updateEditedRows(
      historiesToDelete,
      "Lectura(s) eliminada(s) correctamente",
      "Ocurrió un error al eliminar la(s) lectura(s)"
    );
  };

  const updateConsumption = () => {
    props.updateEditedRows(
      editedConsumptionRows,
      "Consumo(s) actualizado(s) correctamente",
      "Ocurrió un error al actualizar lo(s) consumo(s)"
    );
    setEditedConsumptionRows([]);
  };

  const setOpenDeleteHistoryDialog = () => {
    setDialogTitle("¿Desea eliminar la(s) lectura(s) seleccionada(s)?");
    setDialogActions({ fn: deleteHistory });
    setOpenDialog(true);
  };

  const setOpenUpdateConsumptionDialog = () => {
    setDialogTitle("¿Desea guardar lo(s) cambio(s) en el consumo?");
    setDialogActions({ fn: updateConsumption });
    setOpenDialog(true);
  };

  const handleConsumptionEdit = (params: GridEditCellProps) => {
    let editedHistory = tableHistories.find(
      (value: HistoricoLecturas) => value.idHistoricolecturas === params.id
    );

    if (editedHistory) {
      editedHistory.consumo = params.props?.value?.toString() ?? "0";
      let swap = editedConsumptionRows.filter(
        (value: HistoricoLecturas) => value.idHistoricolecturas !== params.id
      );
      setEditedConsumptionRows([...swap, editedHistory]);
    }
  };

  return (
    <Fragment>
      <StyledGridCard5 item xs={10}>
        <Card>
          <CardHeader
            title={
              <Grid
                item
                container
                direction="row"
                style={{ marginBottom: "-2.5vw", marginTop: "-0.5vw" }}
                sx={{
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <HistoryIcon />
                &nbsp;
                <Typography>HISTÓRICO LECTURAS</Typography>
              </Grid>
            }
          />

          <CardContent>
            <StyledGridCardContent5
              item
              xs={12}
              container
              direction="row"
              style={{ textAlign: "center" }}
            >
              <Grid item xs={2}></Grid>
              <Grid item xs={8}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={es}
                >
                  <DatePicker
                    format="dd/MM/yyyy"
                    label="Fecha de lectura inicial"
                    value={selectedDateInit}
                    onChange={handleDateChangeInit}
                    ref={pickerRefs[0]}
                    open={openDatePicker[0]}
                    onClose={() => setOpenDatePicker([false, false])}
                    slotProps={getDefaultSlotProps({
                      value: selectedDateInit,
                      pickerRef: pickerRefs[0],
                      onClickCalendar: () => {
                        openDatePicker[0] = true;
                        setOpenDatePicker([...openDatePicker]);
                      },
                      onClickClear: () => handleDateChangeInit(null),
                    })}
                  />

                  <DatePicker
                    format="dd/MM/yyyy"
                    label="Fecha de lectura final"
                    value={selectedDateEnd}
                    onChange={handleDateChangeEnd}
                    ref={pickerRefs[1]}
                    open={openDatePicker[1]}
                    onClose={() => setOpenDatePicker([false, false])}
                    slotProps={getDefaultSlotProps({
                      value: selectedDateEnd,
                      pickerRef: pickerRefs[1],
                      onClickCalendar: () => {
                        openDatePicker[1] = true;
                        setOpenDatePicker([...openDatePicker]);
                      },
                      onClickClear: () => handleDateChangeEnd(null),
                    })}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid
                item
                xs={2}
                style={{
                  textAlign: "end",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                {checkRoles(["ADMIN", "OFICINA"]) && (
                  <StyledButtonSaveHis
                    variant="outlined"
                    size="large"
                    color="error"
                    onClick={setOpenUpdateConsumptionDialog}
                  >
                    <SaveIcon />
                  </StyledButtonSaveHis>
                )}

                {checkRoles(["ADMIN", "OFICINA"]) && (
                  <StyledButtonDeleteHis
                    variant="outlined"
                    size="large"
                    color="error"
                    onClick={setOpenDeleteHistoryDialog}
                  >
                    <DeleteIcon />
                  </StyledButtonDeleteHis>
                )}
              </Grid>
            </StyledGridCardContent5>

            <Grid item xs={12}>
              <DataGrid
                getRowId={(row) => row.idHistoricolecturas}
                rows={tableHistories}
                columns={tableColumns}
                onPageSizeChange={(
                  size: number,
                  _details?: GridCallbackDetails
                ) => setPageSize(size)}
                // onEditCellPropsChange={handleConsumptionEdit}
                onCellEditCommit={handleConsumptionEdit}
                initialState={{
                  pagination: { pageSize: pageSize },
                }}
                autoHeight
                components={{ Toolbar: customToolbar }}
                disableSelectionOnClick
                disableColumnMenu
                checkboxSelection
                selectionModel={selectionModel}
                rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
                onSelectionModelChange={setSelectionModel}
                localeText={{
                  footerTotalVisibleRows: (
                    visibleCount: number,
                    totalCount: number
                  ) =>
                    `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,
                  noRowsLabel: "No hay resultados",
                }}
              />
            </Grid>
          </CardContent>
        </Card>
      </StyledGridCard5>
      <Dialog open={openDialog}>
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="error">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              dialogActions.fn();
              setOpenDialog(false);
            }}
            color="primary"
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
