import {
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  Contador,
  HistoricoLecturas,
  HistoricoLecturasTipoLecturaEnum,
} from "../../../client";
import { incidentCodes } from "../../../constants/IncidentCodes";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import {
  StyledDialogTitleReadingDialogTypography,
  StyledGridReadingDialogCancelButton,
  StyledGridReadingDialogContainer,
  StyledGridReadingDialogDivider,
  StyledGridReadingDialogSaveButton,
  StyledGridReadingDialogTextfields,
} from "../styles";
import {
  FC,
  Fragment,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { calculateTotalConsumption } from "../../../utils/reading-helper";
import SessionStorage from "../../../data/sessionStorage/SessionStorage";
import { capitalizeReadingType } from "../../../utils/strings-helper";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getDefaultSlotProps } from "../../../components/datepicker/datePickerUtils";
import { datePickerLabelStyle } from "../../../components/datepicker/datePickerStyle";

type TReadingDialog = {
  open: boolean;
  setOpen: (value: boolean) => void;
  meter?: Contador;
  lastHistory?: HistoricoLecturas;
  newHistory?: HistoricoLecturas;
  handleSaveReading: (
    lastReading: HistoricoLecturas,
    newReading: HistoricoLecturas
  ) => void;
};

export const ReadingDialog: FC<{ props: TReadingDialog }> = ({ props }) => {
  const [readingDate, setReadingDate] = useState<Date>(new Date());
  const [openDatepicker, setOpenDatepicker] = useState(false);
  const [readingType, setReadingType] = useState<HistoricoLecturasTipoLecturaEnum>(
    HistoricoLecturasTipoLecturaEnum.Calculada
  );
  const [incidentCode, setIncidentCode] = useState<string>();
  const [consumption, setConsumption] = useState<number | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const pickerRef = useRef() as MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    let date = SessionStorage.getSelectedDate();
    let type = SessionStorage.getSelectedReadingType();
    let code = SessionStorage.getSelectedIncidentCode();

    if (type !== null && type !== "")
      setReadingType(HistoricoLecturasTipoLecturaEnum[type]);

    if (code && code !== "") setIncidentCode(code);

    if (date !== null && date !== "") setReadingDate(new Date(parseInt(date)));
  }, []);

  const formatLastHistory = (history?: HistoricoLecturas): string => {
    if (
      history &&
      history.codigoIncidencia !== "Eliminado" &&
      history.tipoLectura !== HistoricoLecturasTipoLecturaEnum.Facturada
    )
      return `${history?.lecturaContador ?? ""} - ${
        (history?.fechaLectura &&
          new Date(history?.fechaLectura)?.toLocaleDateString("Es-es")) ??
        ""
      } - ${history?.tipoLectura ?? ""} - ${history?.codigoIncidencia ?? ""}`;

    return "No existe lectura anterior";
  };

  const handleDatepickerClose = () => setOpenDatepicker(false);

  // const handleChangeReadingType = (event: ChangeEvent<{ value: unknown }>) => {
  //   setReadingType(HistoricoLecturasTipoLecturaEnum[event.target.value]);
  // }

  const handleChangeReadingType = (
    event: SelectChangeEvent<HistoricoLecturasTipoLecturaEnum>
  ) => {
    setReadingType(HistoricoLecturasTipoLecturaEnum[event.target.value]);
  };

  const handleSaveNewReading = () => {
    if (consumption !== null && incidentCode !== undefined) {
      let history: HistoricoLecturas = {};

      history.fechaLectura = readingDate.getTime();
      history.tipoLectura = readingType;
      history.codigoIncidencia = incidentCode;
      history.lecturaContador = consumption.toString();
      history.consumo =
        readingType === HistoricoLecturasTipoLecturaEnum.Facturada
          ? calculateTotalConsumption(
              Number(props.lastHistory?.lecturaContador),
              consumption
            ).toString()
          : "0";

      setConsumption(null);
      SessionStorage.setSelectedDate(readingDate?.getTime()?.toString() ?? "");
      SessionStorage.setSelectedReadingType(readingType as string);
      SessionStorage.setSelectedIncidentCode(incidentCode);

      props.handleSaveReading(props.lastHistory!, history);
    } else setSnackbarOpen(true);
  };

  const handleDialogClose = () => {
    setConsumption(0);
    setReadingType(HistoricoLecturasTipoLecturaEnum.Calculada);
    props.setOpen(false);
  };

  return (
    <Fragment>
      <Dialog open={props.open} onClose={() => props.setOpen(false)}>
        <StyledDialogTitleReadingDialogTypography>
          <Typography align="center">Nueva lectura</Typography>
        </StyledDialogTitleReadingDialogTypography>
        <DialogContent>
          <StyledGridReadingDialogContainer
            container
            item
            spacing={2}
            justifyContent="center"
            alignContent="flex-start"
          >
            <Grid item xs={12}>
              <StyledGridReadingDialogTextfields
                color="error"
                id="filled-read-only-input"
                label="Referencia contador"
                defaultValue={props.meter?.referencia ?? ""}
                InputProps={{ readOnly: true }}
                variant="filled"
                fullWidth
              />
              <StyledGridReadingDialogTextfields
                color="error"
                id="filled-read-only-input2"
                label="Número contador"
                defaultValue={props.meter?.numeroCont}
                InputProps={{ readOnly: true }}
                variant="filled"
                fullWidth
              />
              <StyledGridReadingDialogTextfields
                color="error"
                id="filled-read-only-input3"
                label="Dirección"
                defaultValue={props.meter?.direccion ?? ""}
                InputProps={{ readOnly: true }}
                variant="filled"
                fullWidth
              />
              <StyledGridReadingDialogTextfields
                color="error"
                id="filled-read-only-input4"
                label="Última lectura"
                defaultValue={formatLastHistory(props.lastHistory)}
                InputProps={{ readOnly: true }}
                variant="filled"
                fullWidth
              />
            </Grid>
            <StyledGridReadingDialogDivider orientation="horizontal" />

            <Grid
              container
              item
              xs={12}
              sx={{
                justifyContent: "center",
                alignContent: "center",
                alignItems: "flex-end"
              }}
            >
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                  <DatePicker
                    format="dd/MM/yyyy"
                    label="Fecha de lectura"
                    value={readingDate}
                    onChange={(value) =>
                      setReadingDate(new Date(value?.toString() ?? ""))
                    }
                    open={openDatepicker}
                    onClose={handleDatepickerClose}
                    ref={pickerRef}
                    sx={{...datePickerLabelStyle, mr: "5px"}}
                    slotProps={getDefaultSlotProps({
                      value: readingDate,
                      pickerRef: pickerRef,
                      onClickCalendar: () => setOpenDatepicker(true),
                      onClickClear: () => setReadingDate(new Date()),
                    })}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={6}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ marginTop: 20 }}
                >
                  <InputLabel
                    color="error"
                    id="demo-simple-select-outlined-label"
                  >
                    Tipo lectura
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label-required"
                    id="demo-simple-select-outlined-required"
                    value={readingType ?? " "}
                    color="error"
                    onChange={handleChangeReadingType}
                    name="Tipo lectura"
                    label="Tipo lectura  ."
                    fullWidth
                    required
                  >
                    {Object.keys(HistoricoLecturasTipoLecturaEnum)
                      .sort((a: string, b: string) => (a > b ? 1 : -1)) // ?? 0)
                      .filter(
                        (reading: string) =>
                          reading !== HistoricoLecturasTipoLecturaEnum.Eliminada
                      )
                      .map((value: string, ix: number) => (
                        <MenuItem key={`${value}-${ix}`} value={value}>
                          {capitalizeReadingType(value)}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel color="error">Código de incidencia</InputLabel>
                  <Select
                    defaultValue={""}
                    value={incidentCode ?? " "}
                    onChange={(evt) => setIncidentCode(evt.target.value)}
                    name="Código de incidencia"
                    color="error"
                    label="Código de incidencia  ."
                    fullWidth
                    required
                  >
                    {/* <MenuItem style={{ fontStyle: 'italic' }} value={undefined} disabled selected>Pendiente de leer</MenuItem> */}
                    {incidentCodes.map((code: string, ix: number) => (
                      <MenuItem key={`${code}-${ix}`} value={code}>
                        {code}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  color="error"
                  style={{ marginLeft: 5 }}
                  id="text-field-consumption"
                  label="Lectura"
                  defaultValue={consumption}
                  onChange={(evt) => setConsumption(Number(evt.target.value))}
                  variant="filled"
                  fullWidth
                />
              </Grid>
            </Grid>
          </StyledGridReadingDialogContainer>

          <Grid container item xs={12}>
            <Grid
              container
              item
              xs={6}
              sx={{
                justifyContent: "center",
              }}
            >
              <StyledGridReadingDialogCancelButton onClick={handleDialogClose}>
                Cancelar
              </StyledGridReadingDialogCancelButton>
            </Grid>
            <Grid
              container
              item
              xs={6}
              sx={{
                justifyContent: "center",
              }}
            >
              <StyledGridReadingDialogSaveButton onClick={handleSaveNewReading}>
                Guardar lectura
              </StyledGridReadingDialogSaveButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <div>
          <Alert severity="error">
            Hay que introducir una lectura o código de incidencia válidos
          </Alert>
        </div>
      </Snackbar>
    </Fragment>
  );
};
