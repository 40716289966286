import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import {
  StyledBox,
  StyledBreadcrumbs,
  StyledLocationIcon,
  StyledTypography,
} from "../../components/CustomBreadCrumbs";
import { StyledTextField } from "./styled";
import Map from "../../components/Map";
import { LocalizationViewModel } from "../../viewmodels/LocalizationViewModel";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CustomAlert } from "../../components/CustomAlert";
import SpeedIcon from "@mui/icons-material/Speed";

const LocalizationContadorView: FC = () => {
  const { referencia } = useParams();
  const [localizationViewModel, setLocalizationViewModel] =
    useState<LocalizationViewModel>();
  const [random, setRandom] = useState(0);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openReferenceError, setOpenReferenceError] = useState(false);
  const [openCadastralRefError, setOpenCadastralRefError] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setLocalizationViewModel(new LocalizationViewModel(referencia ?? ""));
  }, []);

  const resetIframe = () => {
    setRandom((prevRandom) => prevRandom + 1);
  };

  const handleClickSave = () => {
    const isValidCadastralRef = [14, 20, 24].includes(
      localizationViewModel?.getFormData.cadastralRef.length ?? 0
    );

    if (referencia && isValidCadastralRef) {
      localizationViewModel?.handleSave(referencia).then(() => {
        setTimeout(() => navigate("/contadorControl"), 1000);
        setOpenSuccess(true);
      });
    } else {
      !isValidCadastralRef && setOpenCadastralRefError(true);
      !referencia && setOpenReferenceError(true);
    }
  };

  if (localizationViewModel?.getLoading) {
    return <div>Cargando datos del contador...</div>;
  }

  return (
    <Grid container>
      {/** BREADCRUMBS */}
      <StyledBox>
        <Grid container item gap={6}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography
              component={Link}
              to="/contadorControl"
              color="inherit"
              display="flex"
              sx={{ textDecoration: "none" }}
            >
              <SpeedIcon />
              Gestión de contadores
            </Typography>
            <StyledTypography color="textPrimary">
              <StyledLocationIcon />
              Geolocalización de contadores de agua
            </StyledTypography>
          </Breadcrumbs>
        </Grid>
      </StyledBox>

      {/** MAIN CONTAINER */}
      <Grid m="0px 80px" rowGap={2} container mt={2}>
        <Grid container gap={6}>
          <Grid item>
            <StyledTextField
              label="Referencia catastral"
              name="cadastralRef"
              value={localizationViewModel?.getFormData.cadastralRef}
              onChange={localizationViewModel?.handleChange}
              variant="outlined"
              color="error"
              style={{ minWidth: "300px", width: "auto" }}
            />
          </Grid>
          <Grid item>
            <StyledTextField
              label="Dirección"
              name="address"
              value={localizationViewModel?.getFormData.address}
              onChange={localizationViewModel?.handleChange}
              variant="outlined"
              color="error"
              style={{ minWidth: "500px", width: "auto" }}
            />
          </Grid>
        </Grid>
        {/** Plot location */}
        <Grid container>
          <Grid m="10px 0px">
            <Typography>Ubicación del inmueble</Typography>
          </Grid>
          <Grid container gap={6} display="flex" alignItems="center">
            <StyledTextField
              label="Latitud"
              name="latitude"
              value={localizationViewModel?.getFormData.latitude}
              onChange={localizationViewModel?.handleChange}
              variant="outlined"
              color="error"
              style={{ minWidth: "300px", width: "auto" }}
              helperText="Ejemplo: 42.147181467089324"
            />
            <StyledTextField
              label="Longitud"
              name="longitude"
              value={localizationViewModel?.getFormData.longitude}
              onChange={localizationViewModel?.handleChange}
              variant="outlined"
              color="error"
              style={{ minWidth: "300px", width: "auto" }}
              helperText="Ejemplo: -0.39515533059588664"
            />
            <Button
              id="bt-update-maps-castro"
              variant="contained"
              color="info"
              onClick={() => {
                localizationViewModel?.updateCoordinates();
                resetIframe();
              }}
              style={{ height: "fit-content" }}
            >
              Actualizar maps y catastro
            </Button>
            <Button
              id="bt-save"
              variant="contained"
              color="success"
              onClick={handleClickSave}
              style={{ height: "fit-content" }}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>

        {/** Maps and catastro iFrame */}
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={6} lg={6} p={4}>
            <Typography>Maps inmueble</Typography>
            <Map
              initialLatitude={localizationViewModel?.getLatitude}
              initialLongitude={localizationViewModel?.getLongitude}
              onMarkerDragEnd={localizationViewModel?.handleMarkerDragEnd}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6} p={4}>
            <Typography>Catastro</Typography>
            <Box height={"400px"}>
              <iframe
                key={random}
                title="Resultado de catastro"
                src={localizationViewModel?.catastroUrl}
                style={{ width: "100%", height: "100%" }}
              ></iframe>
            </Box>
          </Grid>
          <Grid
            container
            item
            xs={12}
            p={4}
            height="100%"
            justifyContent="center"
          >
            <Container style={{ padding: "0", width: "100%" }}>
              <Typography textAlign="left" width="100%">
                Resultado de catastro
              </Typography>
              <iframe
                title="Resultado de catastro"
                src={localizationViewModel?.catastroResultUrl}
                style={{ width: "100%", height: "100%" }}
              ></iframe>
            </Container>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenSuccess(false)}
      >
        <div>
          <CustomAlert onClose={() => setOpenSuccess(false)} severity="success">
            Contador actualizado exitosamente.
          </CustomAlert>
        </div>
      </Snackbar>
      <Snackbar
        open={openReferenceError}
        autoHideDuration={6000}
        onClose={() => setOpenReferenceError(false)}
      >
        <div>
          <CustomAlert
            onClose={() => setOpenReferenceError(false)}
            severity="error"
          >
            Referencia no encontrada.
          </CustomAlert>
        </div>
      </Snackbar>
      <Snackbar
        open={openCadastralRefError}
        autoHideDuration={6000}
        onClose={() => setOpenCadastralRefError(false)}
      >
        <div>
          <CustomAlert
            onClose={() => setOpenCadastralRefError(false)}
            severity="error"
          >
            La referencia catastral debe tener una longitud de 14, 20 o 24
            carácteres.
          </CustomAlert>
        </div>
      </Snackbar>
    </Grid>
  );
};

export default observer(LocalizationContadorView);
