import { CardContent, CardHeader, Typography, Grid } from "@mui/material"
import { StyledCardFixHeightRow1, StyledGridCard1, StyledTextFieldReadOnlyCard1 } from "../styles";
import PersonIcon from '@mui/icons-material/Person';
import { Contador } from "../../../../client";
import { getDateTimeString } from "../../../../utils/DateUtils";
import { FC } from "react";


type TMeterInfoSection = {
  currentMeter?: Contador;
}

export const MeterInfoSection: FC<{ props: TMeterInfoSection }> = (({ props }) => {
  return (
    <StyledGridCard1 item xs={5}>
      <StyledCardFixHeightRow1>
        <CardHeader
          title={
            <Grid
              item
              container
              direction="row"
              sx={{
                alignItems: "center",
                justifyContent: "flex-start",
                marginBottom: "-2.5vw",
                marginTop: "-0.5vw"
              }}>
              <PersonIcon />&nbsp;
              <Typography>{props.currentMeter?.estado}</Typography>
            </Grid>
          }
        >
        </CardHeader>
        <CardContent style={{ width: 'inherit' }}>
          <StyledTextFieldReadOnlyCard1 color="error" id="filled-read-only-input" label="Referencia contador"
            defaultValue={props.currentMeter?.referencia ?? ""} InputProps={{ readOnly: true }}
            variant="filled" fullWidth />

          <StyledTextFieldReadOnlyCard1 color="error" id="filled-read-only-input2" label="Número contador"
            defaultValue={props.currentMeter?.numeroCont ?? ""} InputProps={{ readOnly: true }}
            variant="filled" fullWidth />

          <StyledTextFieldReadOnlyCard1 color="error" id="filled-read-only-input3" label="Titular"
            defaultValue={props.currentMeter?.titular ?? ""} InputProps={{ readOnly: true }}
            variant="filled" fullWidth />

          <StyledTextFieldReadOnlyCard1 color="error" id="filled-read-only-input4" label="Dirección"
            defaultValue={props.currentMeter?.direccion ?? ""} InputProps={{ readOnly: true }}
            variant="filled" fullWidth />

          <StyledTextFieldReadOnlyCard1 color="error" id="filled-read-only-input4" label="Última modificación"
            defaultValue={getDateTimeString(props.currentMeter?.lastModified ? new Date(props.currentMeter.lastModified) : undefined)}
            InputProps={{ readOnly: true }} variant="filled" fullWidth />
        </CardContent>
      </StyledCardFixHeightRow1>
    </StyledGridCard1>
  );
})