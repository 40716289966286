import { BASE_PATH, BaseAPI, Configuration, ConfigurationParameters,  } from "../../client";
import { ContadorApi } from "../../client/apis/ContadorApi";
import { HistoricoLecturasApi } from "../../client/apis/HistoricoLecturasApi";
import { InfoContAdicionalApi } from "../../client/apis/InfoContAdicionalApi";
import { LoginApi } from "../../client/apis/LoginApi";
import { RutaApi } from "../../client/apis/RutaApi";
import { UserApi } from "../../client/apis/UserApi";
import SessionStorage from "../sessionStorage/SessionStorage";
import { getEnvURL_BE } from './../environments/Env';



export enum Api { ContadorApi, HistoricoLecturasApi, InfoContAdicionalApi, LoginApi, RutaApi, UserApi }

export class ApiClient {
    protected static config(token?: string | undefined): Configuration {
        const params: ConfigurationParameters = {};
        if (token) params.accessToken = token;

        params.basePath = BASE_PATH;

        if (getEnvURL_BE() !== "") {
            params.basePath = `${getEnvURL_BE()}/v1`;
        }

        params.headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        }

        return new Configuration(params);
    }

    public static getClient(type: Api): BaseAPI {
        switch (type) {
            case Api.LoginApi:
                return new LoginApi(this.config(SessionStorage.getToken()));
            case Api.ContadorApi:
                return new ContadorApi(this.config(SessionStorage.getToken()))
            case Api.HistoricoLecturasApi:
                return new HistoricoLecturasApi(this.config(SessionStorage.getToken()))
            case Api.InfoContAdicionalApi:
                return new InfoContAdicionalApi(this.config(SessionStorage.getToken()))
            case Api.RutaApi:
                return new RutaApi(this.config(SessionStorage.getToken()))
            case Api.UserApi:
                return new UserApi(this.config(SessionStorage.getToken()))
            default:
                throw new NotImplementedException(undefined)
        }
    }
}


class NotImplementedException extends Error {
    constructor(value: Api | undefined) {
        super("Api not implemented: " + value?.toString);
    }
}