/* tslint:disable */
/* eslint-disable */
/**
 * Proxy Service API
 * Proxy Service API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CounterHistoryFilter,
    CounterHistoryFilterFromJSON,
    CounterHistoryFilterToJSON,
    DeleteResponse,
    DeleteResponseFromJSON,
    DeleteResponseToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    HistoricoLecturas,
    HistoricoLecturasFromJSON,
    HistoricoLecturasToJSON,
    HistoricoLecturasPaged,
    HistoricoLecturasPagedFromJSON,
    HistoricoLecturasPagedToJSON,
} from '../models';

export interface CreateHistoricoLecturasRequest {
    historicoLecturas?: HistoricoLecturas;
}

export interface DeleteHistoricoLecturasByIdRequest {
    idHistoricoLecturas: number;
}

export interface GetAllCounterReadingHistoryByFilterRequest {
    offset: number;
    limit: number;
    field?: string;
    sort?: GetAllCounterReadingHistoryByFilterSortEnum;
    counterHistoryFilter?: CounterHistoryFilter;
}

export interface GetAllHistoricoLecturasRequest {
    offset: number;
    limit: number;
    field?: string;
    sort?: GetAllHistoricoLecturasSortEnum;
}

export interface GetHistoricoLecturasByIdRequest {
    idHistoricoLecturas: number;
}

export interface GetHistoricoLecturasByReferenceRequest {
    referencia: string;
}

export interface GetPendingCountersRequest {
    offset: number;
    limit: number;
    field?: string;
    sort?: GetPendingCountersSortEnum;
    counterHistoryFilter?: CounterHistoryFilter;
}

export interface UpdateHistoricoLecturasByIdRequest {
    idHistoricoLecturas: number;
    historicoLecturas?: HistoricoLecturas;
}

/**
 * no description
 */
export class HistoricoLecturasApi extends runtime.BaseAPI {

    /**
     * Endpoint for createHistoricoLecturas
     * createHistoricoLecturas
     */
    async createHistoricoLecturasRaw(requestParameters: CreateHistoricoLecturasRequest): Promise<runtime.ApiResponse<HistoricoLecturas>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/historicoLecturas`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HistoricoLecturasToJSON(requestParameters.historicoLecturas),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoricoLecturasFromJSON(jsonValue));
    }

    /**
     * Endpoint for createHistoricoLecturas
     * createHistoricoLecturas
     */
    async createHistoricoLecturas(historicoLecturas?: HistoricoLecturas): Promise<HistoricoLecturas> {
        const response = await this.createHistoricoLecturasRaw({ historicoLecturas: historicoLecturas });
        return await response.value();
    }

    /**
     * deleteHistoricoLecturasById
     * deleteHistoricoLecturasById
     */
    async deleteHistoricoLecturasByIdRaw(requestParameters: DeleteHistoricoLecturasByIdRequest): Promise<runtime.ApiResponse<DeleteResponse>> {
        if (requestParameters.idHistoricoLecturas === null || requestParameters.idHistoricoLecturas === undefined) {
            throw new runtime.RequiredError('idHistoricoLecturas','Required parameter requestParameters.idHistoricoLecturas was null or undefined when calling deleteHistoricoLecturasById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/historicoLecturas/{idHistoricoLecturas}`.replace(`{${"idHistoricoLecturas"}}`, encodeURIComponent(String(requestParameters.idHistoricoLecturas))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteResponseFromJSON(jsonValue));
    }

    /**
     * deleteHistoricoLecturasById
     * deleteHistoricoLecturasById
     */
    async deleteHistoricoLecturasById(idHistoricoLecturas: number): Promise<DeleteResponse> {
        const response = await this.deleteHistoricoLecturasByIdRaw({ idHistoricoLecturas: idHistoricoLecturas });
        return await response.value();
    }

    /**
     * getAllCounterReadingHistoryByFilter
     * getAllCounterReadingHistoryByFilter
     */
    async getAllCounterReadingHistoryByFilterRaw(requestParameters: GetAllCounterReadingHistoryByFilterRequest): Promise<runtime.ApiResponse<HistoricoLecturasPaged>> {
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getAllCounterReadingHistoryByFilter.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getAllCounterReadingHistoryByFilter.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.field !== undefined) {
            queryParameters['field'] = requestParameters.field;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/historicoLecturas-filter`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CounterHistoryFilterToJSON(requestParameters.counterHistoryFilter),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoricoLecturasPagedFromJSON(jsonValue));
    }

    /**
     * getAllCounterReadingHistoryByFilter
     * getAllCounterReadingHistoryByFilter
     */
    async getAllCounterReadingHistoryByFilter(offset: number, limit: number, field?: string, sort?: GetAllCounterReadingHistoryByFilterSortEnum, counterHistoryFilter?: CounterHistoryFilter): Promise<HistoricoLecturasPaged> {
        const response = await this.getAllCounterReadingHistoryByFilterRaw({ offset: offset, limit: limit, field: field, sort: sort, counterHistoryFilter: counterHistoryFilter });
        return await response.value();
    }

    /**
     * Endpoint for list all historicoLecturas
     * getAllHistoricoLecturas
     */
    async getAllHistoricoLecturasRaw(requestParameters: GetAllHistoricoLecturasRequest): Promise<runtime.ApiResponse<HistoricoLecturasPaged>> {
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getAllHistoricoLecturas.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getAllHistoricoLecturas.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.field !== undefined) {
            queryParameters['field'] = requestParameters.field;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/historicoLecturas`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoricoLecturasPagedFromJSON(jsonValue));
    }

    /**
     * Endpoint for list all historicoLecturas
     * getAllHistoricoLecturas
     */
    async getAllHistoricoLecturas(offset: number, limit: number, field?: string, sort?: GetAllHistoricoLecturasSortEnum): Promise<HistoricoLecturasPaged> {
        const response = await this.getAllHistoricoLecturasRaw({ offset: offset, limit: limit, field: field, sort: sort });
        return await response.value();
    }

    /**
     * getHistoricoLecturasById
     * getHistoricoLecturasById
     */
    async getHistoricoLecturasByIdRaw(requestParameters: GetHistoricoLecturasByIdRequest): Promise<runtime.ApiResponse<HistoricoLecturas>> {
        if (requestParameters.idHistoricoLecturas === null || requestParameters.idHistoricoLecturas === undefined) {
            throw new runtime.RequiredError('idHistoricoLecturas','Required parameter requestParameters.idHistoricoLecturas was null or undefined when calling getHistoricoLecturasById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/historicoLecturas/{idHistoricoLecturas}`.replace(`{${"idHistoricoLecturas"}}`, encodeURIComponent(String(requestParameters.idHistoricoLecturas))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoricoLecturasFromJSON(jsonValue));
    }

    /**
     * getHistoricoLecturasById
     * getHistoricoLecturasById
     */
    async getHistoricoLecturasById(idHistoricoLecturas: number): Promise<HistoricoLecturas> {
        const response = await this.getHistoricoLecturasByIdRaw({ idHistoricoLecturas: idHistoricoLecturas });
        return await response.value();
    }

    /**
     * getHistoricoLecturasByReference
     * getHistoricoLecturasByReference
     */
    async getHistoricoLecturasByReferenceRaw(requestParameters: GetHistoricoLecturasByReferenceRequest): Promise<runtime.ApiResponse<Array<HistoricoLecturas>>> {
        if (requestParameters.referencia === null || requestParameters.referencia === undefined) {
            throw new runtime.RequiredError('referencia','Required parameter requestParameters.referencia was null or undefined when calling getHistoricoLecturasByReference.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/historicoLecturas/referencia/{referencia}`.replace(`{${"referencia"}}`, encodeURIComponent(String(requestParameters.referencia))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HistoricoLecturasFromJSON));
    }

    /**
     * getHistoricoLecturasByReference
     * getHistoricoLecturasByReference
     */
    async getHistoricoLecturasByReference(referencia: string): Promise<Array<HistoricoLecturas>> {
        const response = await this.getHistoricoLecturasByReferenceRaw({ referencia: referencia });
        return await response.value();
    }

    /**
     * getPendingCounters
     * getPendingCounters
     */
    async getPendingCountersRaw(requestParameters: GetPendingCountersRequest): Promise<runtime.ApiResponse<HistoricoLecturasPaged>> {
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getPendingCounters.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getPendingCounters.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.field !== undefined) {
            queryParameters['field'] = requestParameters.field;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pending-counters`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CounterHistoryFilterToJSON(requestParameters.counterHistoryFilter),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoricoLecturasPagedFromJSON(jsonValue));
    }

    /**
     * getPendingCounters
     * getPendingCounters
     */
    async getPendingCounters(offset: number, limit: number, field?: string, sort?: GetPendingCountersSortEnum, counterHistoryFilter?: CounterHistoryFilter): Promise<HistoricoLecturasPaged> {
        const response = await this.getPendingCountersRaw({ offset: offset, limit: limit, field: field, sort: sort, counterHistoryFilter: counterHistoryFilter });
        return await response.value();
    }

    /**
     * updateHistoricoLecturasById
     * updateHistoricoLecturasById
     */
    async updateHistoricoLecturasByIdRaw(requestParameters: UpdateHistoricoLecturasByIdRequest): Promise<runtime.ApiResponse<HistoricoLecturas>> {
        if (requestParameters.idHistoricoLecturas === null || requestParameters.idHistoricoLecturas === undefined) {
            throw new runtime.RequiredError('idHistoricoLecturas','Required parameter requestParameters.idHistoricoLecturas was null or undefined when calling updateHistoricoLecturasById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/historicoLecturas/{idHistoricoLecturas}`.replace(`{${"idHistoricoLecturas"}}`, encodeURIComponent(String(requestParameters.idHistoricoLecturas))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HistoricoLecturasToJSON(requestParameters.historicoLecturas),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoricoLecturasFromJSON(jsonValue));
    }

    /**
     * updateHistoricoLecturasById
     * updateHistoricoLecturasById
     */
    async updateHistoricoLecturasById(idHistoricoLecturas: number, historicoLecturas?: HistoricoLecturas): Promise<HistoricoLecturas> {
        const response = await this.updateHistoricoLecturasByIdRaw({ idHistoricoLecturas: idHistoricoLecturas, historicoLecturas: historicoLecturas });
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetAllCounterReadingHistoryByFilterSortEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllHistoricoLecturasSortEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}
/**
    * @export
    * @enum {string}
    */
export enum GetPendingCountersSortEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}
