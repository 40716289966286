import { styled } from "@mui/material/styles";
import OwnColors from "../../constants/OwnColors";
import { Grid } from "@mui/material";

const StyledDiv = styled('div')(({ theme }) => ({
    width: "auto",
    margin: theme.spacing(0, 10),
    '& .theme--header': {
        backgroundColor: OwnColors.LECOlightRed,
        color: "black",
    },
}));

const StyledDivRow = styled('div')(({ theme }) => ({
    justifyContent: "flex-end",
    display: "table",
    margin: theme.spacing(0, 10),
    "&:hover .MuiInputLabel-root": {
        color: OwnColors.LECOCorporate,
    },
    '& .theme--header': {
        backgroundColor: OwnColors.LECOlightRed,
        color: "black",
    },
}));

const StyledRowItem = styled(Grid)(({ theme }) => ({
    "&:hover .MuiInputLabel-root": {
        color: OwnColors.LECOCorporate,
    },
    "& .MuiInputLabel-root.Mui-focused": {
        color: OwnColors.LECOCorporate,
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: OwnColors.LECOCorporate,
    },
    "& .MuiInputLabel-root": {
        color: OwnColors.LECOMediumGrey,
    },
}));


export { StyledDiv, StyledDivRow, StyledRowItem };
