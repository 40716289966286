import { ContadorPaged, CounterFilter, Contador, DeleteResponse, FindByFieldRequest } from "../../client";
import { ContadorApi } from "../../client/apis/ContadorApi";
import { GetAllCounterReadingHistoryByFilterSortEnum } from "../../client/apis/HistoricoLecturasApi";
import { UpdateRouteMetersReq } from "../../client/models/UpdateRouteMetersReq";
import { Api, ApiClient } from "./ApiClient";



export default class ContadorRepository {

  private static getContadorApiClient() {
    return ApiClient.getClient(Api.ContadorApi) as ContadorApi;
  }

  static async getContadores(): Promise<ContadorPaged> {
    const contadorApi = this.getContadorApiClient();
    const contadores: ContadorPaged = await contadorApi.getAllContadores(0, 20);

    return contadores;
  }

  static async getAllContadoresByFilter(offset: number, limit: number, filter: CounterFilter, sortingField?: string, sortingType?: string): Promise<ContadorPaged> {
    const contadorApi = this.getContadorApiClient();
    const contadores: ContadorPaged = await contadorApi.getAllCounterByFilter(offset, limit, sortingField, sortingType && sortingType.length > 0 && GetAllCounterReadingHistoryByFilterSortEnum[sortingType], filter)

    return contadores;
  }


  static async getContadoresByRoute(route: string): Promise<Contador[]> {
    const contadorApi = this.getContadorApiClient();
    const contadores: Contador[] = await contadorApi.getAllContadoresByRoute(route);

    return contadores;
  }

  static async getContadorByReference(referencia: string): Promise<Contador> {
    const contadorApi = this.getContadorApiClient();
    const contador: Contador = await contadorApi.getContadorByReference(referencia);

    return contador;
  }

  static async getContadorByNumerocont(numeroCont: string): Promise<Contador> {
    const contadorApi = this.getContadorApiClient();
    const contador: Contador = await contadorApi.getContadorByNumerocont(numeroCont);

    return contador;
  }

  static async createContador(newContador: Contador): Promise<Contador> {
    const contadorApi = this.getContadorApiClient();
    const contador: Contador = await contadorApi.createContador(newContador);

    return contador;
  }

  static async updateContadorByReference(referencia: string, updateContador: Contador): Promise<Contador> {
    const contadorApi = this.getContadorApiClient();
    const contador: Contador = await contadorApi.updateContadorByReference(referencia, updateContador);

    return contador;
  }

  static async deleteContadorByReference(referencia: string): Promise<DeleteResponse> {
    const contadorApi = this.getContadorApiClient();
    let response: DeleteResponse = await contadorApi.deleteContadorByReference(referencia);

    return response;
  }

  static async findContadoresByField(findByFieldRequest: FindByFieldRequest) {
    const contadorApi = this.getContadorApiClient();
    const response = await contadorApi.findAllContadoresByField(findByFieldRequest);

    return response;
  }

  static async updateRouteMeters(updateRouteMetersReq: UpdateRouteMetersReq) {
    const response = await this.getContadorApiClient().updateRouteMeters(updateRouteMetersReq);
    return response;
  }
}