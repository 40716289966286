import { FC, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { ContadorViewModel } from "../../viewmodels/ContadorViewModel";
import MeterTable from "./components/MeterTable";
import { checkRoles } from "../../infrastructure/utils/CheckRoles";
import { Contador, CounterFilter, ContadorPaged } from "../../client";
import { GridCallbackDetails } from "@mui/x-data-grid";
import { StyledBreadcrumbs, StyledSpeedIcon, StyledTypography } from "../../components/CustomBreadCrumbs";

const MeterControlView: FC = () => {
  const [filter, setFilter] = useState<CounterFilter>({});
  const [tableContent, setTableContent] = useState<Contador[]>([]);
  const [activePage, setActivePage] = useState(0);
  const [sortingField, setSortingField] = useState("");
  const [sortingType, setSortingType] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      setTableContent([]);
      ContadorViewModel.getInstance()
        .getAllContadoresByFilter(0, 20, filter, sortingField, sortingType)
        .then((response: ContadorPaged) => {
          setActivePage(response.number ?? 0);
          setTableContent([...(response.content ?? [])]);
          setTotalItems(response.totalElements ?? 0);
          setIsLoading(false);
        });
    })();
  }, [filter, sortingField, sortingType]);

  // const contadores = ContadorViewModel.getInstance().contadores ?? []

  const itemsByRole = (contadores: Contador[]) =>
    checkRoles(["ADMIN"]) ? contadores : contadores.filter((item) => item.estado !== "ELIMINADO");


  const handlePageChange = (value: number, _details?: GridCallbackDetails) => {
    setIsLoading(true);
    setTableContent([]);
    ContadorViewModel.getInstance()
      .getAllContadoresByFilter(value, 20, filter, sortingField, sortingType)
      .then((response: ContadorPaged) => {
        setActivePage(response.number ?? 0);
        setTableContent([...(response.content ?? [])]);
        setTotalItems(response.totalElements ?? 0);
        setIsLoading(false);
      });
  };

  return (
    <div>
      <StyledBreadcrumbs aria-label="breadcrumb">
        <StyledTypography color="textPrimary">
          <StyledSpeedIcon />
          Gestión de contadores
        </StyledTypography>
      </StyledBreadcrumbs>
      <MeterTable
        contadores={itemsByRole(tableContent)}
        setContadores={setTableContent}
        activePage={activePage}
        totalItems={totalItems}
        filter={filter}
        isLoading={isLoading}
        handlePageChange={handlePageChange}
        setFilter={setFilter}
        setSortingField={setSortingField}
        setSort={setSortingType}
        sortingField={sortingField}
        sort={sortingType}
      />
    </div>
  );
};

export default observer(MeterControlView);
